@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.recurrent-user-payments-info {
  align-items: center;

  &__loader {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);

    &_mobile {
      left: 50%;
    }

    &_desktop {
      left: 60%;
    }
  }

  &__notification {
    left: 50%;
  }
}

@media (max-width: common.$mobile-w) {
  .recurrent-user-payments-info {
    &__notification {
      @include fonts_mobile.mobile-font-regular-size12-line20;

      max-height: 2.623vh;
      height: unset;
      margin: 0 auto 5%;
      padding: 16px 14px;
      gap: 4.2%;
      width: calc(100% - 44px);
      left: 50%;

      & > svg {
        width: 2em;
        height: 2em;
      }
    }
  }
}

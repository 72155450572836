@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.map {
  display: flex;
  height: 90.8vh;
}

.map-container {
  flex: 1;
  height: 90.8vh;
}

.map-create-project-button {
  position: absolute;
  bottom: 0;
}

.base-layout > .map-container {
  height: 100%;
}

.base-layout--drawing > .map-container,
.only-map-layout--drawing > .map-container {
  cursor: url("./img/cursor.svg"), default !important;
}

.only-map-layout {
  & .topleft-control__container {
    margin-top: 0;
  }

  .map-container div.leaflet-control-container {
    & > div.leaflet-top.leaflet-right {
      top: calc(57px + 1.7vh);

      .leaflet-control {
        margin-top: 0;

        // full height - footer - absolute top - creation button
        height: calc(common.get-mobile-vh(100) - 184px);
      }

      .leaflet-control-attribution.leaflet-control {
        height: unset;
      }
    }
  }
}

.leaflet-container {
  display: flex;
}

/* Disable control's border */
.map-container .leaflet-control-container > div.leaflet-top.leaflet-left > div > div,
.map-container .leaflet-control-container > div.leaflet-top.leaflet-right > div > div,
.map-container .leaflet-control-container > div.leaflet-bottom.leaflet-left > div > div,
.map-container .leaflet-control-container > div.leaflet-bottom.leaflet-right > div > div {
  border: 0;
}

.leaflet-control.leaflet-control-attribution {
  & > a:nth-child(1),
  & > span {
    display: none;
  }
}

.map-container div.leaflet-control-container {
  & > div.leaflet-top.leaflet-right > .leaflet-control.leaflet-control-attribution,
  & > div.leaflet-bottom.leaflet-right > .leaflet-control.leaflet-control-attribution {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 1.4em;
    width: max-content;
    font-size: 0.75rem;
    margin: 0;
  }

  & > div.leaflet-top.leaflet-right {
    z-index: 1000;
  }

  & > div > .leaflet-control {
    @include fonts.font-size(16px);
  }

  & > div.leaflet-top.leaflet-left > .leaflet-control {
    margin: 1.75em 0 0 1em;
  }

  & > div.leaflet-bottom.leaflet-left > .leaflet-control {
    margin: 0 0 1.75em 1em;
  }

  & > div.leaflet-bottom.leaflet-right > .leaflet-control {
    margin: 0 1em 1.75em 0;
  }
}

.map__spinner {
  @include fonts.font-size(10px);

  position: absolute;
  width: 8em;
  height: 8em;
  top: calc(50% - 4em);
  left: calc(50vw - 4em);

  svg {
    width: 8em;
    height: 8em;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .map,
  .map .map-container {
    height: calc(common.get-mobile-vh(100));
    width: 100vw;
  }

  .map-container div.leaflet-control-container {
    & > div > .leaflet-control {
      font-size: 10px;
    }

    & > div.leaflet-top.leaflet-left {
      width: 100%;
      z-index: 1000;
    }

    & > div.leaflet-bottom.leaflet-left {
      bottom: 17vh;
    }

    & > div.leaflet-top.leaflet-right {
      top: 0;

      .leaflet-control {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        gap: 30%;

        // full height - footer - absolute top - creation button
        height: calc(common.get-mobile-vh(100) - 184px);
      }

      .leaflet-control-attribution.leaflet-control {
        height: unset;
      }
    }

    & > div.leaflet-top.leaflet-left > .leaflet-control {
      margin: 1em 0 0 0.8em;
      width: calc(100% - 16px);
    }

    & > div.leaflet-top.leaflet-right > .leaflet-control {
      margin: 0 0.8em 0 0;
    }

    & > div.leaflet-top.leaflet-right > .leaflet-control.leaflet-control-attribution {
      position: fixed;
      bottom: 0;
      right: 0;
      font-size: 10px;
      width: max-content;
    }
  }

  .topleft-control__container {
    margin-top: 54px;
    display: flex;
    gap: 8px;
    justify-content: space-between;
  }

  .map-do-not-response-card {
    position: absolute;
    bottom: 11em;
    margin: 10px 2%;
    height: 3em;
    width: stretch;
    z-index: 10000;
  }
}

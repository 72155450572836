@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.total-budget__empty-budget {
  color: var(--grey-main);

  @include fonts.font-regular-small;
}

.total-budget__collected {
  @include fonts.font-medium-small;

  & .total-budget__amount-collected {
    @include fonts.font-medium-size20-line24;
  }

  color: var(--grey-dark);
}

.total-budget__budget-info {
  display: flex;
  align-items: center;
}

.total-budget__budget-info-progress {
  @include fonts.font-medium-small;

  height: 6.9em;
  aspect-ratio: 1/1;
}

.total-budget__budget-info-data {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  margin-left: 6.36%;

  & > a,
  button {
    @include fonts.font-medium-small;

    color: var(--blue-main);
    text-decoration: underline;

    &:visited {
      color: var(--blue-main);
    }

    &:hover {
      color: var(--blue-light);
    }

    &:active {
      color: var(--blue-dark);
    }
  }
}

.total-budget__amount {
  @include fonts.font-medium-size20-line24;

  color: var(--grey-dark);
  margin: 0;
}

.total-budget__amount-more {
  @include fonts.font-medium-small;
}

.total-budget__amount-total {
  color: var(--blue-dark);
}

.total-budget__amount-collected {
  color: var(--green-light);
}

.total-budget__amount--archived.total-budget__amount-collected {
  color: var(--grey-main);
}

@media screen and (max-width: common.$mobile-w) {
  .total-budget__empty-budget {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .total-budget__collected {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    & .total-budget__amount-collected {
      @include fonts_mobile.mobile-font-medium-size20-line28;

      margin-left: 5px;
    }
  }

  .total-budget__budget-info-progress {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .total-budget__budget-info-data {
    & > a {
      @include fonts_mobile.mobile-font-medium-size16-line22;
    }
  }

  .total-budget__amount {
    @include fonts_mobile.mobile-font-medium-size20-line28;
  }

  .total-budget__amount-more {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

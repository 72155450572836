@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.overview-card {
  width: 47.5vw;
  height: calc(90.8vh - 3.5em); // fullscreen - header (9.2%) - vertical margins
  background-color: var(--main-white);
  border-radius: 10px;
  box-sizing: border-box;
  display: none;
  position: relative;
  flex-direction: column;
  padding-bottom: 6.93%;
  font-family: Rubik, sans-serif;
}

.overview-card--active {
  display: flex;
}

.overview-card__join-button {
  margin: 0;
}

.overview-card__change-view {
  /* Если не absolute, то всё пустое пространство под кнопкой
  некликабельное и не даёт таскать карту :( */
  @include fonts.font-regular-small;

  display: flex;
  align-items: center;
  padding: 1.5%;
  gap: 5px;
  position: absolute;
  top: 0;
  left: -13vw;
  color: var(--grey-dark);
  background-color: var(--main-white);

  &:hover {
    @media (hover: hover) {
      background-color: var(--secondary-light);
    }
  }

  &:active {
    background-color: var(--secondary-dark);
  }

  &span {
    vertical-align: 4px;
    margin-left: 5px;
  }

  & > svg {
    width: 1.5em;
    height: 1.5em;

    & > path {
      stroke: var(--grey-dark);
    }
  }

  @media (max-width: common.$hd-w), (max-height: common.$hd-h) {
    left: -16vw;
  }
}

.overview-card__close-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: 22px 22px 0 0;
}

.overview-card__close {
  cursor: pointer;
  margin: 0;

  & > svg path {
    fill: var(--grey-light);
  }
}

.overview-card__content-block {
  display: flex;
  flex-direction: column;
  padding-left: 40px;
  height: calc(100% - 49px);
  position: relative;
}

.overview-card__header {
  margin: 0 40px 0 0;
}

.overview-card__name-container {
  display: flex;
  flex-direction: row;
  gap: 1.5em;
}

.overview-card__name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  word-wrap: break-word;
  overflow: hidden;
  width: 100%;
  margin: 0;

  @include fonts.font-medium-big;

  color: var(--grey-dark);
}

.overview-card__status-with-timeline {
  margin: 1.25em 0 0.5em;
}

.overview-card__status {
  margin-top: 24px;
}

.overview-card__address-row {
  display: flex;
  justify-content: space-between;
  margin: 14px 0 16px;

  &:has(.overview-card__meeting-point) {
    margin-bottom: 0.5em;
  }
}

.overview-card__meeting-point {
  @include fonts.font-regular-small;

  max-width: 80%;
}

.overview-card__address {
  @include fonts.font-medium-size20-line24;

  line-height: 24px;
  color: var(--grey-main);
  max-width: 64%;
}

.overview-card__edit-button {
  @include fonts.font-medium-small;

  text-decoration: none;
  align-self: flex-start;
  padding: 0;

  & > svg {
    margin-right: 9px;
    width: 18px;
    stroke-width: 2px;
  }
}

.overview-card__initiator,
.overview-card__initiator:disabled {
  padding: 0;
  border: none;
  color: var(--grey-dark);

  @include fonts.font-medium-small;
}

.overview-card__content {
  margin-right: 19px;
  padding-right: 15px;
  overflow-y: auto;

  .detailed-info__categories {
    margin: 1.5em 0 1.2em;
  }

  .detailed-info__photo-mock {
    width: 80%;
    height: 40%;
    max-height: 312px;
    max-width: 514px;
    margin: 0 auto;
    padding: 2.5em;
  }

  @include scroll.scroll;
}

.overview-card__photos--single {
  display: block;
}

.overview-card__photos-news {
  margin: 0 auto;
}

.overview-card__description {
  @include fonts.font-regular-small;

  margin-top: 1.6em;
  color: var(--grey-dark);
  white-space: pre-line;

  & .text-editor-preview {
    word-break: break-all;
  }
}

.overview-card__comments {
  margin: 24px 0 5px;
  color: var(--blue-main);
  cursor: pointer;
  transition: 1s;

  @include fonts.font-medium-small;

  &:hover {
    @media (hover: hover) {
      color: var(--blue-light);
    }
  }

  &:active {
    color: var(--blue-dark);
  }

  &:disabled {
    cursor: default;
    color: var(--grey-main);
  }
}

.overview-card__footer {
  padding-top: 1.2%;
  margin-right: 4.6%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
}

.overview-card__footer-block {
  display: flex;
  gap: 1.5%;
  flex-wrap: wrap;
}

.overview-card__support-button,
.overview-card__watch-full-button {
  padding: 1.14% 2.4% 1.2%;
  min-width: 21.3%;

  @include fonts.font-medium-small;
}

.overview-card__share-button {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0;

  & > svg {
    height: 2em;
    width: 2em;
  }

  & > svg > path {
    fill: var(--blue-dark);
  }
}

.overview-card__watch-full-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-left: auto;

  & > svg {
    width: 1.5em;
    height: 1.5em;
    stroke: var(--green-main);
    margin-left: 11px;
    stroke-width: 2px;
  }
}

.overview-card__share-plate {
  position: absolute;
  bottom: -3vh;
  right: 2vw;
  z-index: 1000000;
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.arrow-button {
  display: flex;
  align-items: center;
  gap: 32px;
  text-decoration: none;
  cursor: pointer;
  width: fit-content;
}

.arrow-button__text {
  height: fit-content;
  color: var(--grey-dark);
}

.arrow-button__icon {
  position: relative;
  width: 6vw;
  aspect-ratio: 1/1;
  background-image: url("./images/arrow_button.svg");
  background-size: cover;
}

.arrow-button__icon::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 10px;
  width: 6vw;
  aspect-ratio: 1/1;
  background-image: url("../../images/noisy-circle.png");
  background-position: center;
  border-radius: 50%;
  opacity: 0;
  transition: 0.5s;
  z-index: -1;
}

.arrow-button:hover .arrow-button__icon::before {
  @media (hover: hover) {
    opacity: 1;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .arrow-button {
    gap: 1em;
  }

  .arrow-button__icon {
    width: 12.5vw;

    &::before {
      top: 5px;
      left: 5px;
      width: 12.5vw;
    }
  }
}

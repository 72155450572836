@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.profile-header {
  display: flex;
  align-items: center;
  text-decoration: none;
}

.profile-header__icon {
  stroke: var(--grey-dark);
  flex-shrink: 0;
  margin-right: 8px;
  margin-bottom: auto;
}

.profile-header__user-info {
  @include fonts.font-size(26px);

  color: var(--grey-dark);
  margin: 0;
  word-break: break-word;
}

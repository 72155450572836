.donation__block-data {
  margin: 0 8px;
}

.donation__mobile {
  margin: 20px 0;

  & .detailed-info__donation-form {
    margin: 24px 0;
  }
}

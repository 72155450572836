@use "@platform-for-public-places/components-library/styles/_common.scss";

.locator-icon {
  margin-top: -64px !important;

  & > svg:hover {
    animation: bounce 0.6s infinite;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .locator-icon {
    margin-top: -48px !important;
  }
}

.marker {
  & path {
    fill: var(--main-white);
  }
}

@keyframes bounce {
  25% {
    transform: translateY(0.25rem);
  }

  75% {
    transform: translateY(-0.25rem);
  }
}

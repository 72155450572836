@use "@platform-for-public-places/components-library/styles/_common.scss";

.comments-screen {
  display: flex;
  flex-direction: column;
  height: common.get-mobile-vh(100);

  &__list {
    flex-grow: 1;
    overflow: hidden scroll;
  }

  &__form {
    margin: 14px 8px;
  }
}

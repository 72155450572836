.search-panel-bottom-sheet {
  &[data-rsbs-is-blocking="false"] {
    z-index: 100001;
  }

  & [data-rsbs-backdrop] {
    display: none;
    cursor: none;
  }

  & [data-rsbs-content="true"] {
    display: flex;
    height: 87vh;
  }

  & [data-rsbs-scroll="true"] {
    overflow: hidden;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.donation-page-mobile {
  width: 95%;
  margin: 24px auto;
}

.donation-spinner {
  @include fonts.font-size(10px);

  position: absolute;
  width: 8em;
  height: 8em;
  top: calc(50% - 4em);
  left: calc(50% - 4em);

  svg {
    width: 8em;
    height: 8em;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.comment-creation {
  transition: 0.5s;
}

.comment-creation__textarea {
  @include fonts.font-regular-small;
  @include fonts_mobile.mobile-font-regular-14px-line20;

  margin: 0;
  padding: 1.5% 2.3%;

  &::placeholder {
    @include fonts.font-regular-small;
    @include fonts_mobile.mobile-font-regular-14px-line20;

    color: var(--grey-main);
  }
}

.comment-creation__buttons {
  justify-content: flex-end;
  margin-left: auto;
  margin-top: 1.9%;
  display: flex;
  gap: 16px;
}

.comment-creation__cancel-button {
  padding: 12px calc(4.5% - 2px);
}

.comment-creation__create-button {
  padding: 13px 4.5% 15px;
}

@media screen and (max-width: common.$mobile-w) {
  .comment-creation {
    display: flex;
    border: 2px solid var(--secondary-dark);
    border-radius: 5px;
    align-items: flex-end;
  }

  .comment-creation__textarea {
    margin: 0;
    padding: 13px 0 13px 16px;
    border: none;
    overflow: hidden;

    &--extended {
      height: 86px;
    }

    &:focus {
      outline: none;
    }
  }

  .comment-creation__textarea-send {
    display: flex;
    flex-shrink: 0;
    padding: 11px 14px 11px 5px;
    height: 44px;
    opacity: 1;
    transition: 0.5s;

    & svg path {
      stroke: var(--blue-main);
    }

    &:disabled {
      opacity: 0;
    }
  }
}

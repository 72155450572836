@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.catalog-user__container {
  @include fonts.font-size(16px);

  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 3.37em 4.8em 0 1.75em;
  height: calc(100% - 3.37em);
}

.catalog-user__left-container {
  position: relative;
  display: flex;
  width: 41vw;
  flex-direction: column;
  box-sizing: border-box;

  @media (max-width: common.$hd-w) {
    width: 46vw;
  }
}

.catalog-user__spinner {
  @include fonts.font-size(10px);

  position: absolute;
  width: 8em;
  height: 8em;
  top: calc(50% - 4em);
  left: calc(50% - 4em);

  svg {
    width: 8em;
    height: 8em;
  }
}

.catalog-user__project-category-filter {
  margin: 24px 0;
  padding-left: 0;
  width: 25em;
  display: flex;
  gap: 32px;
}

.catalog-user__user-container {
  @include scroll.scroll;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  overflow-y: auto;
  direction: rtl;
  gap: 12px;
  box-sizing: border-box;
  padding-left: 1.1vw;
}

.catalog-user__search,
.catalog-user__project-category-filter {
  margin-left: 1.1vw;
}

.catalog-user__right-container {
  @include scroll.scroll;

  overflow: hidden auto;
  box-sizing: border-box;
  margin: 0 0 2%;
  padding: 3.4% 3% 2.3%;
  border: 2px solid var(--secondary-main);
  border-radius: 10px;
  width: 47vw;
}

.user-catalog-card {
  display: flex;
  border-radius: 10px;
  align-items: center;
  transition: 0.3s;
  border: 2px solid var(--main-white);
  height: auto;
  width: 100%;
  cursor: pointer;
  direction: ltr;
  box-sizing: border-box;
  justify-content: space-between;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    @media (hover: hover) {
      border-color: var(--secondary-main);
    }
  }

  &:last-child {
    margin-bottom: 2vh;
  }
}

.user-catalog-card--selected {
  border-color: var(--secondary-dark);

  &:hover {
    @media (hover: hover) {
      border-color: var(--secondary-dark);
    }
  }

  &:active {
    border-color: var(--secondary-dark);
  }
}

.user-catalog-card__main-info {
  @include fonts.font-medium-small;

  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 0.8vw;
}

.user-card__avatar-small {
  height: 7.2em;
  width: 7.2em;
  margin: 0.5em 0;
  border-radius: 50%;
}

.user-catalog-card__arrow {
  visibility: hidden;
  width: 7%;
  height: 32px;
  fill: var(--secondary-main);
  transform: rotate(270deg);
  margin-right: 2.5%;
}

.user-catalog-card--selected > .user-catalog-card__arrow {
  fill: var(--secondary-dark);
  visibility: visible;
}

.user-catalog-card:active > .user-catalog-card__arrow {
  fill: var(--secondary-dark);
}

.user-catalog-card__name {
  @include fonts.font-medium-small;

  color: var(--grey-dark);
  max-width: 15vw;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.user-catalog-card__image {
  max-width: 87px;
  max-height: 95px;
  border-radius: 100px;
  margin: 16px 12px 16px 16px;
}

.user-catalog-card__about {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.user-catalog-card__tag {
  @include fonts.font-medium-small14;

  border-radius: 20px;
  padding: 0.5em 1.5em;
  border: 2px solid var(--tag-blue);
  color: var(--tag-blue);
}

.user-catalog-card__tags {
  display: flex;
  gap: 3%;
}

.user-catalog-card__links-portfolio-icon {
  stroke: var(--blue-main);
  stroke-width: 2px;
}

.user-catalog-card__links-portfolio-block {
  @include fonts.font-medium-small;

  display: flex;
  align-items: center;
  color: var(--blue-main);
  outline: none;

  & > p {
    margin: 8px 8px 8px 4px;
  }

  &:visited {
    color: var(--grey-main);

    & > .user-catalog-card__links-portfolio-icon {
      stroke: var(--grey-main);
    }
  }

  &:hover {
    @media (hover: hover) {
      color: var(--blue-light);

      & > .user-catalog-card__links-portfolio-icon {
        stroke: var(--blue-light);
      }
    }
  }

  &:active {
    color: var(--blue-dark);

    & > .user-catalog-card__links-portfolio-icon {
      stroke: var(--blue-dark);
    }
  }
}

.user-catalog-card__links__main-contact {
  @include fonts.font-size(14px);

  font-weight: 500;
  line-height: 140%;
  max-width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--grey-dark);

  &:visited {
    color: var(--grey-main);
  }

  &:hover {
    @media (hover: hover) {
      color: var(--blue-dark);
    }
  }

  &:active {
    color: var(--blue-main);
  }
}

.user-catalog-card__links {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  width: 34%;
  flex: 1;

  & p {
    margin: 8px 8px 8px 4px;
    padding: 0;
  }

  & .user-card__contacts-button {
    position: relative;

    &::after {
      top: auto;
      right: 10px;
    }
  }
}

.user-catalog-card:hover > .user-catalog-card__arrow {
  @media (hover: hover) {
    visibility: visible;
  }
}

.full-info-card__image {
  max-width: 136px;
  max-height: 136px;
  border-radius: 100px;
  margin: 16px 31px 16px 16px;
}

.project-mini__title {
  color: var(--grey-dark);
  font-size: 16px;
  font-weight: 500;
}

.project-mini__reaction {
  display: flex;
}

.project-category-filter__element {
  list-style-type: none;
  color: var(--grey-main);
}

.project-category-filter__element button {
  @include fonts.font-regular-small;

  &:hover {
    @media (hover: hover) {
      cursor: pointer;
    }
  }
}

.project-category-filter__element_selected {
  position: relative;
  color: var(--grey-dark);

  &::after {
    content: "";
    width: 42px;
    height: 5px;
    position: absolute;
    bottom: -12px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--secondary-dark);
    border-radius: 10px;
  }
}

.project-category-filter__element_counter {
  display: inline;
  margin-left: 5px;

  @include fonts.font-medium-small;
}

@media screen and (max-width: common.$mobile-w) {
  .catalog-user--mobile {
    display: flex;
    height: 100%;
    flex-direction: column;
  }

  .catalog-user__project-category-filter {
    margin: 12px 0 16px;
    width: auto;
    justify-content: flex-start;
    gap: 20px;
  }

  .catalog-user__user-container {
    direction: ltr;
    padding-left: 0;
  }

  .catalog-user__search,
  .catalog-user__project-category-filter {
    margin-left: 0;
  }

  .catalog-user__container {
    flex-direction: column;
    justify-content: normal;
    overflow: auto;
    margin: 0 8px;
    padding: 0;
    height: unset;

    & .search-input {
      width: auto;
    }

    @include scroll.scroll;
  }

  .user-catalog-card {
    margin: 0;
    width: 100%;
    padding: 8px 8px 8px 4px;

    &--selected {
      border-color: var(--main-white);
      background-color: var(--secondary-light);

      &:hover {
        @media (hover: hover) {
          border-color: var(--main-white);
        }
      }

      &:active {
        border-color: var(--main-white);
      }
    }
  }

  .user-catalog-card__main-info {
    width: 100%;
    gap: 10px;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .user-card__avatar-small {
    height: 4em;
    width: 4em;
  }

  .user-catalog-card__name {
    max-width: 56.3vw;
    margin: 8px 0 6px;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .user-catalog-card__tag {
    padding: 6px 14px;
    width: max-content;
    border-width: 1px;

    @include fonts_mobile.mobile-font-bold-10px;
  }

  .user-catalog-card__links-portfolio-block {
    margin: 0;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .full-info-card__text {
    margin-left: 8px;
  }

  .project-mini {
    border: none;
    padding: 0;

    &:first-child {
      margin-left: 8px;
    }
  }

  .project-mini__title {
    margin: 12px 0 0;
  }

  .project-category-filter__element button,
  .project-category-filter__element .project-category-filter__element_counter {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .project-category-filter__element_selected::after {
    width: 20px;
    height: 4px;
    bottom: -6px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--secondary-dark);
    border-radius: 10px;
  }
}

.user-container__loading-trigger {
  width: 270px;
  min-height: 30px;
  background: transparent;
}

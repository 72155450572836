// library styles
.bm-burger-button {
  position: fixed;
  width: 24px;
  height: 24px;
}

.bm-burger-bars {
  background: var(--grey-dark);
}

.bm-menu {
  position: fixed;
  right: 0;
  max-width: 267px;
  width: 100%;
  background: var(--main-white);
  padding: 1.75rem 0.8rem 0 1.25rem;
  font-size: 1.15em;
}

.bm-item-list {
  display: flex;
  flex-direction: column;
}

.bm-overlay {
  top: 0;
  left: 0;
  background: rgb(60 62 75 / 40%);
}

// own styles
.menu {
  position: absolute;
  max-width: 267px;
  top: 0;
  right: 0;
}

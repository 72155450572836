@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.password-input {
  width: 100%;
  box-sizing: border-box;
  background: var(--main-white);
  border: 1px solid var(--grey-light);
  border-radius: 10px;
  color: var(--grey);
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.5s;

  &:focus-within {
    outline: none;
    border-color: var(--blue-main);
  }
}

.password-input__input {
  @include fonts.font-regular-small;

  color: var(--grey-dark);
  border: none;
  background: none;
  box-sizing: border-box;
  padding: 3.6% 2.8% 3.6% 5.5%;
  width: calc(100% - 2.75em);

  &-internal-autofill-selected .password-input{
    background: var(--secondary-dark);
  }

  &::placeholder {
    color: var(--grey-light);
  }

  &:focus {
    outline: none;
  }
}

.password-input__button {
  @include fonts.font-regular-small;

  display: flex;
  cursor: pointer;

  & > svg {
    width: 1.5em;
    height: 1.5em;

    & > path {
      stroke: var(--grey-main);
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .password-input__input {
    font-size: 16px;
  }
}

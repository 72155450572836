@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.members-outgoing-row {
  @include fonts.font-size(16px);

  line-height: 140%;
  color: var(--grey-dark);
}

#link.members-outgoing-row__item {
  padding: 0;
  border: 0;
  text-decoration: underline;
  text-align: left;
}

#link.members-outgoing-row__item,
.members-outgoing-row span {
  white-space: pre-wrap;
  word-wrap: break-word;
  width: fit-content;
  height: fit-content;
}

#list.members-outgoing-row__item {
  display: flex;
  flex-direction: column;
}

#status.members-outgoing-row__item {
  display: flex;
  height: fit-content;
  justify-content: space-between;

  & .button {
    align-self: center;
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    padding: 0;
    border: 0;
    transition: 0.5s;
    opacity: 0;

    & svg path {
      fill: var(--blue-main);
    }
  }

  &:hover .button {
    opacity: 1;
  }
}

.members-outgoing-row__status--awaiting {
  color: var(--blue-dark);
}

.members-outgoing-row__status--rejected {
  color: var(--error-main);
}

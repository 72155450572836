@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.pagination__trigger {
  width: 1px;
  height: 1px;
  flex-shrink: 0;
}

.pagination__empty-placeholder {
  @include fonts.font-size(16px);

  line-height: 140%;
  color: var(--grey-main);
}

@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.mobile-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 9px 11px 9px 20px;
  z-index: 10001;
  background-color: rgb(255 255 255 / 95%);
  border-radius: 0 0 10px 10px;
  margin: 0;

  & .bm-burger-button {
    top: 13px;
    right: 16px;
  }

  &--with-title {
    margin-bottom: 12px;

    & .bm-burger-button {
      top: 9px;
    }
  }
}

.mobile-header__logo {
  width: 1.75em;
  height: 1.75em;
}

.mobile-header__return-button {
  position: absolute;
  top: 7px;
  left: 0;
  width: 1.7em;
  height: 1.7em;
  transform: rotate(90deg);
  padding: 0;
  border: 0;

  @include fonts_mobile.mobile-font-regular-18px;

  & > svg {
    fill: var(--grey-dark);

    & > path {
      stroke: none;
    }
  }

  &:active {
    -webkit-tap-highlight-color: transparent;
  }
}

.mobile-header__header {
  margin: 0 auto;
  color: var(--grey-dark);

  @include fonts_mobile.mobile-font-regular-18px;
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.dates {
  display: flex;
  flex-direction: column;
  width: fit-content;

  &__title {
    margin-bottom: 2.5em;
  }

  &__footer {
    margin: auto 0 6.5em;
  }

  &__error {
    @include fonts.font-regular-small;

    margin: 0.5em 0 2.25em;
    color: var(--error-main);
    max-height: 0;
    opacity: 0;
    transition: 0.5s;

    &--active {
      max-height: 34px;
      opacity: 1;
    }
  }

  @media screen and (max-width: common.$mobile-w) {
    &__title {
      margin-bottom: 0.325em;
    }

    &__date-widget:nth-child(2) {
      margin-bottom: 1.25em;
    }

    &__error {
      @include fonts_mobile.mobile-font-regular-size16-line22;
    }

    &__footer {
      margin-bottom: 1.25em;
    }
  }
}

.date-widget {
  color: var(--grey-dark);

  &__content {
    display: flex;
    gap: 1em;
  }

  &__title {
    margin: 0 0 1em;

    @include fonts.font-medium-size20-line24;
  }

  &__subtitle {
    margin: 0 0 1.25em;

    @include fonts.font-regular-size18-line24;
  }

  &__date--error .date-picker-button__date {
    border-color: var(--error-main);
  }

  &__time--error .time-picker__control {
    border-color: var(--error-main) !important;
  }

  @media screen and (max-width: common.$mobile-w) {
    &__title {
      margin-bottom: 0.5em;

      @include fonts_mobile.mobile-font-medium-size14-line17;
    }

    &__subtitle {
      margin-bottom: 0.5em;

      @include fonts_mobile.mobile-font-regular-14px;
    }

    &__date {
      width: calc(100% - 7em);

      & .button {
        width: 100%;
        justify-content: center;

        @include fonts_mobile.mobile-font-medium-size14-line17;
      }
    }

    &__time {
      @include fonts_mobile.mobile-font-regular-size16-line22;
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .edit-layout__gate {
    .dates__title {
      display: none;
    }
  }
}

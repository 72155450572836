@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.creation-algorithm {
  margin: 0;
  padding-bottom: 8%;
}

.creation-algorithm__title {
  margin: 0 0 2.4%;
}

.creation-algorithm__description {
  max-width: 80%;
  margin: 0;
  color: var(--grey);

  @include fonts.font-regular-size18-line24;
}

.creation-algorithm__scheme {
  margin-top: 3.5%;
  margin-right: 10%;
  display: grid;
  column-gap: 4.25%;
  row-gap: 8%;
  grid-template:
    "idea moderation designing approving"
    "next transfer commissioning implementation";
  grid-template-columns: 22% 22% 22% 22%;
}

#idea.creation-algorithm__stage {
  grid-area: idea;
}

#moderation.creation-algorithm__stage {
  grid-area: moderation;
}

#designing.creation-algorithm__stage {
  grid-area: designing;
}

#approving.creation-algorithm__stage {
  grid-area: approving;
}

#implementation.creation-algorithm__stage {
  grid-area: implementation;
}

#commissioning.creation-algorithm__stage {
  grid-area: commissioning;
}

#transfer.creation-algorithm__stage {
  grid-area: transfer;
}

#next.creation-algorithm__stage {
  grid-area: next;
}

#next .creation-algorithm__stage-title {
  margin-top: 3.75em;
}

.creation-algorithm__stage-icon {
  background-size: contain;
  background-repeat: no-repeat;
}

.creation-algorithm__stage-icon,
.creation-algorithm__stage-icon--empty {
  width: 36%;
  aspect-ratio: 1/1;
  margin: 0 auto;
}

#idea.creation-algorithm__stage > .creation-algorithm__stage-icon {
  background-image: url("./img/idea.png");
}

#moderation.creation-algorithm__stage > .creation-algorithm__stage-icon {
  background-image: url("./img/moderation.png");
}

#designing.creation-algorithm__stage > .creation-algorithm__stage-icon {
  background-image: url("./img/designing.png");
}

#approving.creation-algorithm__stage > .creation-algorithm__stage-icon {
  background-image: url("./img/approving.png");
}

#implementation.creation-algorithm__stage > .creation-algorithm__stage-icon {
  background-image: url("./img/implementation.png");
}

#commissioning.creation-algorithm__stage > .creation-algorithm__stage-icon {
  background-image: url("./img/commissioning.png");
}

#transfer.creation-algorithm__stage > .creation-algorithm__stage-icon {
  background-image: url("./img/transfer.png");
}

#idea.creation-algorithm__stage::before,
#moderation.creation-algorithm__stage::before,
#designing.creation-algorithm__stage::before,
#commissioning.creation-algorithm__stage::before,
#transfer.creation-algorithm__stage::before,
#next.creation-algorithm__stage::before {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 10%;
  top: 15%;
  left: 87%;
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
}

#idea.creation-algorithm__stage::before,
#moderation.creation-algorithm__stage::before,
#designing.creation-algorithm__stage::before {
  background-image: url("./img/link-right.svg");
}

#commissioning.creation-algorithm__stage::before,
#transfer.creation-algorithm__stage::before,
#next.creation-algorithm__stage::before {
  background-image: url("./img/link-left.svg");
}

#approving.creation-algorithm__stage::before {
  content: "";
  display: block;
  position: absolute;
  width: 50%;
  height: 115%;
  top: 23%;
  left: 79%;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("./img/link-down.svg");
  pointer-events: none;
}

.creation-algorithm__stage {
  position: relative;
  text-align: center;
  text-decoration: none;
}

.creation-algorithm__stage-title {
  @include fonts.font-medium-small;

  margin: 15px 0 0;
  color: var(--grey-dark);
}

.creation-algorithm__stage-duration {
  @include fonts.font-medium-small;

  margin: 8px 0 0;
  color: var(--green-main);
}

.creation-algorithm__stage-description {
  @include fonts.font-regular-small;

  margin: 8px 0 0;
  color: var(--grey-dark);
}

.creation-algorithm__stage-button {
  @include fonts.font-medium-small;

  display: inline-block;
  margin-top: 6.6%;
  background-color: var(--green-main);
  border-radius: 10px;
  padding: 5.8% 15% 7%;
  color: var(--main-white);
  text-decoration: none;
  transition: 0.5s;

  &:hover {
    @media (hover: hover) {
      background-color: var(--green-light);
    }
  }

  &:active {
    background-color: var(--green-dark);
  }
}

.creation-algorithm__button-navigation {
  display: flex;
  margin: 8px auto 0;
  flex-direction: column-reverse;
  gap: 4em;
  width: 64%;

  & > button {
    padding: 5.5% 0;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .creation-algorithm {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  #idea.creation-algorithm__stage::before,
  #moderation.creation-algorithm__stage::before,
  #designing.creation-algorithm__stage::before,
  #commissioning.creation-algorithm__stage::before,
  #approving.creation-algorithm__stage::before,
  #implementation.creation-algorithm__stage::before,
  #transfer.creation-algorithm__stage::before,
  #next.creation-algorithm__stage::before {
    background-image: url("./img/arrow-down.svg");
    height: 5.8vh;
    width: 2.58vh;
    top: 110%;
    left: 50%;
  }

  #transfer.creation-algorithm__stage::before {
    top: 80%;
    padding-bottom: 7%;
  }

  #implementation.creation-algorithm__stage::before {
    content: "";
    display: block;
    position: absolute;
    background-size: contain;
    background-repeat: no-repeat;
    pointer-events: none;
  }

  .creation-algorithm__scheme {
    margin-top: 4.7%;
    margin-right: 0;
    row-gap: 8%;
    padding-bottom: 5%;
    grid-template:
      "idea"
      "moderation"
      "designing"
      "approving"
      "implementation"
      "commissioning"
      "transfer"
      "next";
    margin-bottom: 30em;
  }

  .creation-algorithm__stage-block-description {
    display: flex;
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
    width: 185%;
  }

  .creation-algorithm__stage {
    display: flex;
    align-items: center;
    gap: 4%;

    &:last-child {
      row-gap: 40px;
    }
  }

  .creation-algorithm__stage-icon {
    width: 52%;
  }

  .creation-algorithm__stage-title {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    margin: 0;
  }

  .creation-algorithm__stage-duration {
    @include fonts_mobile.mobile-font-bold-14px-line20;

    margin: 2px 0 0;
  }

  .creation-algorithm__stage-description {
    @include fonts_mobile.mobile-font-regular-size16-line22;

    margin: 4px 0 0;
  }

  .creation-algorithm__scroll {
    @include scroll.scroll-none;

    overflow: auto;
  }

  .creation-algorithm__description {
    max-width: 100%;

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }
}

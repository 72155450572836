.search-pane__input {
  flex: 1 1 0;
  border: 1px solid var(--grey-light);
}

.search-pane__input-map {
  flex: 10 1 0;
  width: 22vw;
}

.filter-button__map {
  width: 11.5%;
}

.search-pane__input_catalog {
  flex: 0.45;
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.detailed-diary {
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.detailed-diary__back {
  padding: 2.5% 2%;

  @include fonts.font-medium-size20-line24;

  & > svg {
    height: 1.2em;
    width: 1.2em;

    & > path {
      stroke: var(--grey-dark);
      transform-origin: center;
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }
}

.detailed-diary__diary {
  display: flex;
  justify-content: space-around;
  height: 87.75%;
}

.detailed-diary__diary-content {
  box-sizing: border-box;
  max-width: 55.5%;
  display: flex;
  flex-direction: column;
}

.detailed-diary__scrollable-content {
  overflow-y: scroll;
  box-sizing: border-box;
  padding: 0 0 0 6em;
  margin-top: 37px;

  @include scroll.scroll-none;
  @include fonts.font-size(22px);

  &--not-display {
    display: none;
  }

  .diary-entry {
    padding-left: 3.16%;
  }

  .diary-entry__article {
    margin: 0;
  }

  .diary-entry__header {
    margin: 0;

    & .diary-entry__date {
      position: absolute;
      right: 103.5%;
      top: 0;
    }

    & .diary-entry__title:disabled {
      margin: 0;
    }
  }
}

.detailed-diary__diary-side {
  position: static;
  margin-top: 3.91%;
  width: 17.7%;
  flex-shrink: 0;
  box-sizing: border-box;
}

.detailed-diary__side-scrollable {
  @include scroll.scroll-none;

  overflow: auto;
  height: 92.6%;
}

.detailed-diary__title {
  padding: 0 0 0 12.5%;
  box-sizing: content-box;
}

.detailed-diary__side-title {
  @include fonts.font-medium-size20-line24;

  color: var(--grey-dark);
  margin: 0 0 10.6%;
}

.detailed-diary__header {
  display: flex;
  gap: 4.7%;
  margin-bottom: 3%;
}

.detailed-diary__header-text {
  @include fonts.font-medium-big;

  color: var(--grey-dark);
  margin: 0;
}

.detailed-diary__subheader-text {
  margin: 0;

  @include fonts.font-regular-size18-line24;
}

.detailed-diary__digest-dropdown {
  display: none;
}

@media screen and (max-width: common.$mobile-w) {
  .detailed-diary__subheader-text {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    color: var(--grey-dark);
  }

  .detailed-diary__header,
  .detailed-diary__diary-side {
    display: none;
  }

  .detailed-diary__diary-content {
    max-width: 100%;
  }

  .detailed-diary__title {
    padding: 0 3%;
  }

  .detailed-diary__digest-dropdown {
    display: block;
    margin-top: 2.1vh;

    .detailed-diary__digest-controls {
      width: 100%;
      padding: 0 3%;
      display: flex;
      justify-content: space-between;
      cursor: pointer;

      h4 {
        @include fonts_mobile.mobile-font-bold-14px-line20;

        margin: 0;
        color: var(--blue-main);
      }

      svg {
        transition: 0.3s;
      }

      svg path {
        fill: var(--blue-main);
      }
    }

    .detailed-diary__digest-list {
      display: flex;
      flex-direction: column;
      row-gap: 1.5vh;
      height: 0;
      max-height: 65vh;
      padding-right: 2vw;
      overflow: hidden scroll;
    }
  }

  .detailed-diary__digest-dropdown--visible {
    .detailed-diary__digest-controls {
      svg {
        transform: rotate(180deg);
      }
    }

    .detailed-diary__digest-list {
      @include scroll.scroll;

      height: unset;
      margin-top: 2.5vh;
    }
  }

  .detailed-diary__scrollable-content {
    padding: 0 4.3vw;
    margin-top: 2.5vh;

    .diary-entry {
      padding-left: 5.3%;
      padding-bottom: 30px;

      & .diary-entry__header {
        & .diary-entry__date {
          position: inherit;
        }
      }
    }

    .diary-entry__article {
      margin-top: 0.9vh;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-status-vertical {
  display: flex;
}

.project-status-vertical__text-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin-left: 1vw;
}

.project-status-vertical__text,
.project-status-vertical__text--passed {
  color: var(--grey-dark);
  padding: 1px 0 1px 20%;

  @include fonts.font-medium-small;
}

.project-status-vertical__text--passed {
  color: var(--green-main);
}

.project-status-vertical__bar-row {
  display: flex;
  flex-direction: column;
  margin: 1px 0;
}

.project-status-vertical__bar-part,
.project-status-vertical__bar-part--passed {
  height: common.get-mobile-vh(7.8);
  border-left: 2px dashed var(--grey-light);
}

.project-status-vertical__bar-part--passed {
  border-left-color: var(--green-main);
}

.project-status-vertical__point-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: -0.85em;
}

.project-status-vertical__point-row,
.project-status-vertical__text-row {
  @include fonts.font-medium-small;
}

.project-status-vertical__point,
.project-status-vertical__point--passed {
  width: 1.6em;
  height: 1.6em;
  fill: var(--main-white);
  stroke: var(--grey-light);
}

.project-status-vertical__point--passed {
  fill: var(--green-main);
  stroke: var(--green-main);
}

.project-status-vertical__dates {
  @include fonts.font-size(14px);

  font-weight: 400;
  line-height: 120%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  color: var(--grey-main);
  padding: 2px 13% 2px 0;

  & > span {
    height: 1.2em;
  }
}

.project-status-vertical__hint-wrapper {
  position: relative;

  & .project-status-vertical__status,
  & .project-status-vertical__status--passed {
    display: block;
  }

  & .project-status-vertical__status {
    color: var(--grey-light);
  }

  & .project-status-vertical__status--passed {
    color: var(--green-main);
  }
}

.project-status-vertical__icon-wrapper {
  display: flex;
}

.project-status-vertical__hint {
  position: absolute;
  width: max-content;
  max-width: 12vw;
  border: 1px solid var(--grey-light);
  border-radius: 10px;
  background-color: var(--main-white);
  padding: 1vh 1vw;
  z-index: 10;
  top: calc(100% + 4px);

  @include fonts.font-regular-very-small;
}

.project-status-vertical__hint--hidden {
  display: none;
}

.project-status-vertical--grayscale {
  .project-status-vertical__bar-row .project-status-vertical__bar-part--passed {
    border-left-color: var(--grey-main);
  }

  .project-status-vertical__point-row .project-status-vertical__point--passed circle {
    fill: var(--grey-main);
    stroke: var(--grey-main);
  }

  .project-status-vertical__text-row .project-status-vertical__status--passed {
    color: var(--grey-main);
  }
}

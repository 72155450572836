@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.meeting-point {
  @include fonts.font-regular-size18-line27;

  margin: 0;
  color: var(--grey-dark);
  width: 100%;

  &__name {
    word-wrap: break-word;
  }

  &__label {
    font-weight: 500;
  }

  &--reverted {
    display: flex;
    flex-direction: column;
    gap: 0.5em;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .meeting-point {
    &__name {
      @include fonts_mobile.mobile-font-medium-size16-line22;
    }

    &__label {
      @include fonts_mobile.mobile-font-regular-size12-line14;

      vertical-align: middle;
      color: var(--grey-main);
    }
  }
}

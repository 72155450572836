@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.profile-layout {
  display: flex;
}

.profile-layout__gate {
  @include scroll.scroll-none;

  box-sizing: border-box;
  flex: 9 1;
  padding: 3.6% 4.2% 0;
  height: 100%;
  overflow: auto;
}

.profile-layout__title {
  color: var(--grey-dark);

  &__title {
    @include fonts.font-medium-big;

    margin: 0;
  }
}

@media (max-width: common.$mobile-w) {
  .profile-layout {
    height: unset;
    flex-direction: column;
  }

  .profile-layout__gate {
    margin: 0;
    padding: 0;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.pkk-does-not-response-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 20.834vw;
  height: 4.08vh;
  padding: 12px;
  background: var(--main-white);
  border-radius: 10px;

  & > .pkk-does-not-response-info__text {
    @include fonts.font-size(16px);

    font-weight: 400;
    line-height: 130%;
    margin: 0 0 0 8px;
    color: var(--grey-dark);

    & > .pkk-does-not-response-info__text--link {
      @include fonts.font-size(16px);

      font-weight: 400;
      line-height: 130%;
      display: inline;
      text-decoration: underline;
      color: var(--green-main);
    }
  }

  & > .pkk-does-not-response-info__icon {
    min-width: 20px;
    min-height: 20px;
    margin: 8px;

    & path {
      stroke: var(--warning-main);
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .pkk-does-not-response-info {
    & > .pkk-does-not-response-info__text {
      font-size: 12px;

      & > .pkk-does-not-response-info__text--link {
        font-size: 12px;
      }
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.successful-creation {
  width: 32%;
  height: 100%;
  margin: 0 auto;
}

.successful-creation__title,
.successful-creation__thanks {
  @include fonts.font-medium-big;

  margin: 0;
  text-align: center;
  color: var(--grey-dark);
}

.successful-creation__congratulation,
.successful-creation__description {
  margin: 5.5% 0 0;
  text-align: center;
  color: var(--grey);

  @include fonts.font-regular-size18-line24;
}

.successful-creation__links {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 2em;
  margin: 2% 0 0;
  padding: 0;
  grid-area: menu;

  & > li {
    list-style-type: none;
  }
}

.successful-creation__link {
  display: flex;
  align-items: center;
  color: var(--grey-dark);
  text-decoration: none;
  gap: 8px;

  @include fonts.font-regular-size18-line24;

  line-height: 18.2px;
  transition: 0.5s;
  -webkit-tap-highlight-color: transparent;

  & > svg {
    width: 24px;
    height: 24px;
    margin-bottom: auto;

    path {
      stroke: var(--blue-main);
    }
  }
}

.successful-creation__button {
  display: block;
  margin: 5.2% auto 0;
  padding: 2.1% 6.4%;
}

.successful-creation__image {
  background-position: center;
  background-size: contain;
  margin: 5% auto 0;
  height: 38%;
  display: flex;
  aspect-ratio: 211/187;
}

.successful-creation__image-event {
  aspect-ratio: 467/359;
}

.successful-creation__title {
  padding: 9% 0 0;
}

.successful-creation__thanks {
  @include fonts.font-medium-big;

  margin-top: 7%;
}

@media screen and (max-width: common.$mobile-w) {
  .successful-creation {
    width: auto;
    display: flex;
    flex-direction: column;
    margin: 0 2%;
  }

  .successful-creation__description {
    margin-top: 2%;
  }

  .successful-creation__links {
    gap: 12px;
  }

  .successful-creation__link {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .successful-creation__title {
    @include fonts_mobile.mobile-font-bold-20px;
  }

  .successful-creation__thanks {
    @include fonts_mobile.mobile-font-bold-20px;
  }

  .successful-creation__button {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    padding: 3.1% 6.4%;
    position: absolute;
    order: 1;
    width: 96%;
    bottom: 2%;
  }

  .successful-creation__image {
    margin-top: 5.4%;
    height: 28%;
  }

  .successful-creation__image-event {
    margin-top: 0 auto;
    height: 30%;
    width: 100%;
  }

  .successful-creation__congratulation,
  .successful-creation__description {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }
}

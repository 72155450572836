.card {
  background: var(--main-white);
  border-radius: 10px;
  color: var(--grey-dark);

  &_large {
    padding: 2.5em 2.5em 3.125em;
    border: 2px solid var(--secondary-main);
  }

  &_small {
    padding: 0.75em 0.625em;
    border: 1px solid var(--grey-light);
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.confirm-email {
  background: var(--main-white);
  padding: 7.87vh 2.1vw 8.62vh;
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  z-index: 1;
}

.confirm-email__header {
  text-align: center;
  margin: 0;
  color: var(--grey-dark);

  @include fonts.font-medium-big;
}

.confirm-email__guidance {
  text-align: center;
  color: var(--grey-dark);
  width: 23.4375vw;
  margin-top: 2.22em;

  @include fonts.font-regular-size18-line27;
}

.confirm-email__guidance-email {
  color: var(--green-main);
  text-overflow: ellipsis;
  max-width: 16vw;
  overflow: hidden;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.confirm-email__success-image {
  display: block;
  width: 24.47vw;
  height: 28.8vh;
  margin: 8.3% 0 0 2.5%;
  aspect-ratio: 26/17;
}

@media screen and (max-width: common.$mobile-w) {
  .confirm-email {
    border: none;
    padding: 0 8px;
    margin: 45px 0 0;
  }

  .confirm-email__guidance {
    margin: 0;
    width: 100%;

    @include fonts_mobile.mobile-font-regular-16px-line24;
  }

  .confirm-email__guidance-email {
    max-width: 70vw;
  }

  .confirm-email__success-image {
    margin: 44px 0 0 8vw;
    width: 85vw;
  }
}

.donation-failure-page {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .donation-failure {
    margin-top: 41px;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.reporting-chart-label {
  @include fonts.font-regular-size12-line20;

  text-anchor: middle;
  fill: var(--grey-dark);

  &_empty {
    fill: var(--grey-main);
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.front-page {
  overflow: hidden;

  @media screen and (max-width: common.$mobile-w) {
    margin-bottom: common.get-mobile-vh(8.2);
  }
}

.front-page__section--main {
  display: flex;
  flex-direction: column;
  margin: calc(4.98% + 9.2vh) 4.04% 0 8.91%;
  background-image:
    url("./images/region-markers.png"),
    url("./images/noisy-circle.png");
  background-position: right top 5%, right 0.9% top 5%;
  background-repeat: no-repeat;
  background-size: 61.02%, 48.55%;
  box-sizing: border-box;
  border: 1px solid transparent;
  min-height: 93vh;
}

.front-page__main-slogan {
  margin: 2.17em 0 0;
  color: var(--green-main);
  font-family: Raleway, sans-serif;

  @include fonts.font-semi-bold-size24-line28;
}

.front-page__main-title {
  margin: 0.44em 0 0;
  max-width: 11.875em;
  color: var(--grey-dark);
  font-family: Raleway, sans-serif;

  @include fonts.font-extra-bold-size64-line77;
}

.front-page__main-description {
  margin: 1.58em 0 0;
  max-width: 25.83em;
  color: var(--grey-dark);
  font-family: Raleway, sans-serif;

  @include fonts.font-medium-size24-line28;
}

.front-page__main-link {
  margin: 9vh 0 0;
  padding: 0;
  width: fit-content;
  height: auto;

  @include fonts.font-regular-size28-line38;
}

.front-page__main-typewriter {
  width: 15em;
  align-self: flex-end;
  color: var(--grey-dark);
  font-family: Raleway, sans-serif;

  @include fonts.font-semi-bold-size32-line38;

  @media screen and (max-width: common.$ultra-hd-w) {
    margin: 18% 9% 0 0;
  }

  @media screen and (max-width: common.$two-k-w) {
    margin: 11% 6% 0 0;
  }

  @media screen and (max-width: common.$full-hd-w) {
    margin: 11% 4% 0 0;
  }

  @media screen and (max-width: common.$wsxga-w) {
    margin: 8% 0 0;
  }

  @media screen and (max-width: common.$wxga-p-w) {
    margin: 4% 4% 0 0;

    @include fonts.font-medium-size24-line28;
  }

  @media screen and (max-width: common.$hd-w) {
    margin: 9% 9% 0 0;
  }
}

.front-page__section-title {
  margin: 0;
  color: var(--grey-dark);
  text-align: center;
  font-family: Raleway, sans-serif;

  @include fonts.font-bold-size64-line77;
}

.front-page__section--purpose {
  padding: 18vh 0 25vh;
  background-image: url("./images/concrete.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.front-page__purpose-description {
  margin: 2.8% auto 0;
  line-height: 145%;
  text-align: center;
  color: var(--grey-dark);
  max-width: 37.5em;

  @include fonts.font-size(24px);
}

.front-page__purpose-grid {
  display: grid;
  margin: 70px 10% 0;
  gap: 40% 20%;
  grid-template-columns: repeat(2, 42%);
  width: fit-content;
}

.front-page__purpose-cell {
  @include fonts.font-size(24px);

  position: relative;
  padding-left: 3.2vw;
  margin: 0;
  color: var(--grey-dark);

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 2.1vw;
    aspect-ratio: 1 / 1;
    background-image: url("./images/checkmark.svg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  & span {
    font-weight: 500;
    color: var(--green-main);
  }
}

.front-page__section--team {
  margin: 7% auto 4%;
}

.front-page__section--projects {
  padding: 7.3% 0 10%;

  @media (hover: hover) {
    .project-preview:hover .project-preview__image-icon_hover {
      display: none;
    }
  }
}

.front-page__projects {
  margin: 4.5% 8.9% 0;
  display: flex;
  flex-direction: column;
}

.front-page-project {
  text-decoration: none;

  & .project-preview__image--large,
  & .project-preview__cover::after {
    height: calc(100% - 4px);
  }

  & > .project-preview__title--large {
    @include fonts.font-medium-size28-line38;
  }

  & .budget-info__collected,
  .budget-info__amount {
    @include fonts.font-regular-size18-line24;
  }

  & > .project-preview__reactions--large > .project-preview__reaction-container--large {
    @include fonts.font-medium-size24-line28;
  }
}

.front-page__projects-row {
  display: grid;
  flex: 1;
  grid-template-columns: repeat(3, 33%);
  gap: 1%;
}

.front-page__projects-catalog-link {
  margin-top: 40px;

  @include fonts.font-regular-size28-line38;
}

.front-page__section--features {
  padding: 7.3% 0 9.4%;
  background-image: url("./images/concrete.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.front-page__features-row {
  display: grid;
  margin: 4.7% auto 0;
  grid-template-columns: repeat(4, 25%);
  width: fit-content;
  max-width: 83.6vw;
}

.front-page__feature-illustration {
  width: 65.7%;
  aspect-ratio: 1 / 1;
  margin: 0 auto;
  background-size: 8vw, cover;
  background-position: center;
  background-repeat: no-repeat;
}

#coordination .front-page__feature-illustration {
  background-image:
    url("./images/crane-machine.svg"),
    url("./images/noisy-circle.png");
}

#tracking .front-page__feature-illustration {
  background-image:
    url("./images/measure-tool.svg"),
    url("./images/noisy-circle.png");
}

#supporting .front-page__feature-illustration {
  background-image:
    url("./images/payment-calculation.svg"),
    url("./images/noisy-circle.png");
}

#registration .front-page__feature-illustration {
  background-image:
    url("./images/plan-architect.svg"),
    url("./images/noisy-circle.png");
}

.front-page__feature-description {
  margin: 9.1% 0 0;
  text-align: center;
  color: var(--grey-dark);

  @include fonts.font-medium-size24-line28;
}

.front-page__section--news {
  padding-top: 7.3%;
}

.front-page__news {
  display: grid;
  grid-template-columns: repeat(2, 38vw);
  gap: 3% 5.8vw;
  margin: 4.7% auto 3.6%;
  width: fit-content;
}

.front-page__news-post {
  max-width: 37vw;
}

.front-page__news-post-cover {
  width: 100%;
  aspect-ratio: 16/9;
  border-radius: 10px;
  object-fit: cover;
}

.front-page__news-post-title {
  overflow: hidden;
  margin: 5% 0 0;
  color: var(--grey-dark);
  overflow-wrap: break-word;

  @include fonts.font-semi-bold-size40-line47;
}

.front-page__news-post-text {
  margin: 2.7% 0 0;
  color: var(--grey-dark);
  overflow-wrap: break-word;
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;

  & * a {
    margin: 0;
    color: var(--green-main);

    &:hover {
      color: var(--green-light);
    }

    &:active {
      color: var(--green-dark);
    }

    &:visited {
      color: var(--blue-main);
    }
  }

  @include fonts.font-regular-size24-line28;
}

.front-page__news-post-button,
.front-page__news-post-button--mobile {
  display: flex;
  gap: 6px;
  align-items: center;
  margin-top: 20px;
  padding: 0;
  cursor: pointer;
  transition: 0.5s;

  @media (hover: hover) {
    opacity: 0;
    transform: translateX(-170px);
  }

  & > span {
    color: var(--green-main);

    @include fonts.font-regular-size24-line28;

    & > svg {
      width: 1.8em;
      height: 1.8em;
    }
  }

  & > svg > path {
    stroke: var(--green-main);
  }
}

.front-page__news-post-button--mobile {
  opacity: 1;
  transform: translateX(0);
}

.front-page__news-post:hover .front-page__news-post-button {
  @media (hover: hover) {
    opacity: 1;
    transform: translateX(0);
  }
}

.front-page__section--algorithm {
  position: relative;
  padding: 7.3% 0 13.6%;
}

.front-page__algorithm-decoration--top,
.front-page__algorithm-decoration--bottom {
  position: absolute;
  aspect-ratio: 1 / 1;
  background-repeat: no-repeat;
  z-index: -1;
}

.front-page__algorithm-decoration--top {
  position: absolute;
  right: -13.2vw;
  top: -21.3vh;
  width: 38.2vw;
  background-image:
    url("./images/gardener.svg"),
    url("./images/noisy-circle.png");
  background-size: 27vw, cover;
  background-position: center;
}

.front-page__algorithm-decoration--bottom {
  position: absolute;
  bottom: -27.2vh;
  left: -3vw;
  width: 46.1vw;
  background-size: 37vw, cover;
  background-position: 6vw 0.5vw, center;
  background-image:
    url("./images/bench-tree.svg"),
    url("./images/noisy-circle.png");
}

.front-page__algorithm {
  margin: 11% 9% 0;
}

.front-page__algorithm-arrow {
  width: 18vw;
  height: 27.8vh;
  align-self: flex-end;
  background-image: url("./images/step-arrow.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right bottom;
}

.front-page__algorithm-row {
  display: flex;
  gap: 10% 4%;
  min-height: 28.4vh;
  margin-top: 2%;
  padding-right: 28%;

  &:first-of-type {
    margin-top: 0;
  }

  &:nth-of-type(even) {
    flex-direction: row-reverse;
    padding-right: 0;
    padding-left: 28%;

    & > .front-page__algorithm-arrow {
      transform: scaleX(-1);
    }
  }

  &:last-of-type > .front-page__algorithm-arrow {
    display: none;
  }
}

.front-page__algorithm-step {
  display: flex;
  align-items: center;
  width: 45vw;
  height: fit-content;
  gap: 2vw;
}

.front-page__algorithm-step-icon {
  width: 12.2vw;
  aspect-ratio: 1 / 1;
  flex-shrink: 0;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;

  &#front-page-algorithm-idea {
    background-image: url("./images/steps/idea.png");
  }

  &#front-page-algorithm-moderation {
    background-image: url("./images/steps/moderation.png");
  }

  &#front-page-algorithm-designing {
    background-image: url("./images/steps/designing.png");
  }

  &#front-page-algorithm-approving {
    background-image: url("./images/steps/approving.png");
  }

  &#front-page-algorithm-implementation {
    background-image: url("./images/steps/implementation.png");
  }

  &#front-page-algorithm-exploitation {
    background-image: url("./images/steps/exploitation.png");
  }
}

.front-page__algorithm-step-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: var(--grey-dark);
}

.front-page__algorithm-step-name {
  margin: 0;

  @include fonts.font-semi-bold-size40-line47;
}

.front-page__algorithm-step-decription {
  margin: 0;

  @include fonts.font-regular-size32-line36;
}

.front-page__algorithm-button {
  display: block;
  margin: 4.2% auto 0;
  padding: 0;
  color: var(--main-white);

  @include fonts.font-medium-size32-line36;
}

.front-page__donation-block {
  display: grid;
  background: var(--front-page-grey);
  grid-template: "form image" "info contact" / 1fr 1fr;
  padding: 14vh 16vh 10vh;
  gap: 10vh 0;
}

.front-page__donation-form {
  grid-area: form;
  display: flex;
  flex-direction: column;
  color: var(--grey-dark);
}

.front-page__donation-form-header {
  font-weight: 700;
  line-height: 57px;
  margin: 0 0 3.6vh;

  @include fonts.font-size(48px);
}

.front-page__donation-recurring-payments {
  margin: 0;

  @include fonts.font-regular-size24-line28;

  margin-bottom: 10px;
}

.front-page__donation-bottom-info {
  margin: 0;

  @include fonts.font-regular-size24-line28;

  margin-top: 1.04em;
}

.front-page__donation-btn {
  text-decoration: underline;
  cursor: pointer;

  @include fonts.font-regular-size24-line28;
}

.front-page__donation-form-description {
  margin: 0;

  @include fonts.font-regular-size24-line28;
}

.front-page__donation-form-element {
  margin-top: 6.2vh;
}

.front-page__donation-img {
  grid-area: image;
  display: flex;
  justify-content: center;
}

.front-page__donation-img-element {
  width: 29em;
  height: auto;

  @include fonts.font-regular-size24-line28;
}

.front-page__donation-info {
  grid-area: info;
  display: flex;
  flex-direction: column;
  color: var(--grey-dark);
}

.front-page__donation-info-title {
  margin: 0;

  @include fonts.font-medium-size32-line36;
}

.front-page__donation-info-description {
  margin: 4vh 0 2.3vh;
  white-space: pre-line;

  @include fonts.font-regular-size24-line28;

  max-width: 670px;
  width: 100%;
}

.front-page__donation-info-link {
  margin: 1.4vh 0 0;

  @include fonts.font-regular-size24-line28;
}

.front-page__donation-contact {
  grid-area: contact;
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
  color: var(--grey-dark);
}

.front-page__donation-contact-title {
  margin: 0 0 1vh;

  @include fonts.font-medium-size32-line36;
}

.front-page__donation-contact-description {
  margin: 3.2vh 0 0;
  white-space: pre-line;

  @include fonts.font-regular-size24-line28;
}

.front-page__donation-text-with-icon {
  display: flex;
  flex-direction: row;
  margin-top: 1.8vh;

  @include fonts.font-regular-size24-line28;

  & > svg {
    width: 1.33em;
    height: 1.333em;
  }
}

.front-page__donation-contact-email {
  margin: 0 0 0 0.8vw;
  max-width: calc(100% - 2em);

  @include fonts.font-medium-size24-line28;
}

.front-page__donation-contact-address {
  margin: 0 0 0 0.8vw;
  max-width: calc(100% - 2em);
  text-transform: uppercase;

  @include fonts.font-medium-size24-line28;
}

@media screen and (max-width: common.$mobile-w) {
  .front-page__section--main {
    position: relative;
    padding: 1.87% 3.2% 3.2%;
    height: calc(common.get-mobile-vh(100) - 49px);
    background-size: 100%, 80%;
    background-position: right 40% bottom 6%, right 12% bottom 9%;
    margin: 0;
  }

  .front-page__main-slogan {
    margin: 0;

    @include fonts_mobile.mobile-font-semi-bold-size16-line19;
  }

  .front-page__main-title {
    margin-top: 18px;

    @include fonts_mobile.mobile-font-extra-bold-size32-line39;
  }

  .front-page__main-description {
    width: 71.7%;
    margin-top: 18px;

    @include fonts_mobile.mobile-font-medium-size16-line19;
  }

  .front-page__main-link {
    margin-top: 17px;
    width: auto;

    @include fonts_mobile.mobile-font-medium-size16-line19;
  }

  .front-page__main-typewriter {
    position: absolute;
    bottom: 2%;

    @include fonts_mobile.mobile-font-bold-12px;
  }

  .front-page__section--purpose {
    padding: 7.8vh 0 12vh;
  }

  .front-page__section-title {
    width: 65%;
    margin: 0 auto;

    @include fonts_mobile.mobile-font-bold-size36-line42;
  }

  .front-page__purpose-description {
    width: 90%;
    margin: 34px auto 0;

    @include fonts_mobile.mobile_font-regular-size16-line22;
  }

  .front-page__purpose-grid {
    margin: 6px 10% 0;
    gap: normal;
    grid-template-columns: none;
  }

  .front-page__purpose-cell {
    margin: 78px 0 0;
    padding: 0;
    text-align: center;

    @include fonts_mobile.mobile-font-medium-size18-line26;

    &::before {
      top: -50px;
      left: 38vw;
      width: 40px;

      @media screen and (max-width: common.$mobile-wide-w) {
        left: 36vw;
      }

      @media screen and (max-width: common.$mobile-narrow-w) {
        left: 34vw;
      }
    }
  }

  .front-page__section--projects {
    padding: 54px 0 80px;
  }

  .front-page__projects {
    width: auto;
    margin: 34px 0;
  }

  .front-page__projects-row {
    grid-template-columns: repeat(3, 77.5%);
    overflow: auto;
    padding: 0 0 10px;

    @include scroll.scroll-none;
  }

  .front-page-project {
    margin: 0 10px 0 0;
  }

  .front-page__projects-catalog-link {
    position: inherit;
    margin: 32px auto 0;

    @include fonts_mobile.mobile-font-medium-size16-line19;
  }

  .front-page__section--features {
    padding: 54px 0 80px;
  }

  .front-page__features-row {
    margin: 32px 22px 0;
    grid-template-columns: none;
    gap: normal;
  }

  .front-page__features-row > div {
    display: flex;
    justify-content: flex-start;
    margin: 18px 0 0;
  }

  .front-page__feature-illustration {
    width: 76px;
    height: 76px;
    margin: 0;
    background-size: 75%, cover;
  }

  .front-page__feature-description {
    margin: auto 5px;
    text-align: left;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .front-page__section--news {
    padding-top: 54px;
  }

  .front-page__news {
    display: block;
    gap: normal;
    margin: 40px 12px 0;
  }

  .front-page__news-post {
    margin: 30px 0 0;
    max-width: calc(100vw - 24px); // screen - margin
  }

  .front-page__news-post-title {
    @include fonts_mobile.mobile-font-medium-size24-line30;

    overflow-wrap: anywhere;
  }

  .front-page__news-post-text {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .front-page__news-post-button {
    opacity: 1;
    transform: translateX(0);
    margin-top: 14px;

    span {
      @include fonts_mobile.mobile-font-medium-size20-line28;
    }
  }

  .front-page__section--algorithm {
    padding: 80px 0 66.67%;
  }

  .front-page__algorithm-decoration--top {
    top: 15.6vh;
  }

  .front-page__algorithm {
    margin: 53px 12px 0;
  }

  .front-page__algorithm-row {
    gap: normal;
    min-height: auto;
    margin-top: 0;
    padding-right: 0;
    flex-direction: column;
    justify-content: flex-start;

    &:first-of-type {
      margin-top: 0;
    }

    &:nth-of-type(even) {
      flex-direction: column;
      padding-right: 0;
      padding-left: 0;

      & > .front-page__algorithm-arrow {
        transform: none;
      }
    }

    &:last-of-type > .front-page__algorithm-arrow {
      display: inherit;
    }
  }

  .front-page__algorithm-step-wrapper {
    gap: normal;
  }

  .front-page__algorithm-step {
    width: auto;
  }

  .front-page__algorithm-step-icon {
    width: 19.47vw;
    height: auto;
  }

  .front-page__algorithm-step-name {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .front-page__algorithm-step-decription {
    margin-top: 4px;

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .front-page__algorithm-arrow {
    width: 11vw;
    height: 5vh;
    align-self: center;
    background-size: 350%;
    background-position: right -15% bottom;
  }

  .front-page__algorithm-button {
    margin: 4px auto 0;
    padding: 0;
    width: 96%;
    height: 68px;
  }

  .front-page__algorithm-decoration--bottom {
    bottom: -10%;
    left: -11%;
    width: 115%;
    background-size: 83%, cover;
    background-position: 57% 0%, center;
  }

  .front-page__donation-block {
    grid-template: "image" "form" "info" "contact" / 1fr;
    padding: 40px 12px 60px;
    gap: 0;
  }

  .front-page__donation-img > svg {
    width: 17em;

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .front-page__donation-form {
    margin-top: 18px;
  }

  .front-page__donation-form-header {
    @include fonts_mobile.mobile-font-extra-bold-size32-line39;
  }

  .front-page__donation-recurring-payments {
    @include fonts_mobile.mobile-font-regular-14px;

    margin-bottom: 16px;
  }

  .front-page__donation-bottom-info {
    @include fonts_mobile.mobile-font-regular-14px;

    margin-top: 20px;
  }

  .front-page__donation-form-description {
    @include fonts_mobile.mobile-font-regular-14px;
  }

  .front-page__donation-form-element {
    margin-top: 32px;
  }

  .front-page__donation-info {
    margin-top: 48px;
  }

  .front-page__donation-info-title {
    @include fonts_mobile.mobile-font-medium-size24-line30;
  }

  .front-page__donation-info-description {
    margin: 18px 0 0;

    @include fonts_mobile.mobile-font-regular-14px;
  }

  .front-page__donation-info-link {
    margin-top: 10px;

    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .front-page__donation-contact {
    margin: 36px 0 0;
  }

  .front-page__donation-contact-description {
    margin-top: 16px;

    @include fonts_mobile.mobile-font-regular-14px;
  }

  .front-page__donation-text-with-icon {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    & > svg {
      width: 1.5em;
      height: 1.5em;
    }
  }

  .front-page__donation-contact-email {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .front-page__donation-contact-address {
    max-width: calc(100% - 3em);

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

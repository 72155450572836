@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.unsubscribe {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 auto;
  width: 57%;
  gap: 14%;
}

.unsubscribe__image {
  background-position: center;
  background-size: contain;
  width: 38%;
  display: flex;
  aspect-ratio: 368/386;
}

.unsubscribe__title {
  margin: 0 0 6%;

  @include fonts.font-semi-bold-size40-line47;

  line-height: 50px;
  font-weight: 500;
  color: var(--grey-dark);
}

.unsubscribe__description,
.unsubscribe__clarifying {
  @include fonts.font-regular-size18-line24;

  & > b {
    font-weight: 500;
  }
}

.unsubscribe__description {
  margin: 0 0 2%;
}

.unsubscribe__clarifying {
  margin: 0;
}

.unsubscribe__button {
  margin: 6% 0 0;
  padding: 1.8% 6.7%;
}

@media screen and (max-width: common.$mobile-w) {
  .unsubscribe {
    flex-flow: column-reverse;
    height: auto;
    margin: 16% 2% 0;
    width: auto;
  }

  .unsubscribe__info {
    text-align: center;
  }

  .unsubscribe__title {
    @include fonts_mobile.mobile-font-bold-20px;

    margin: 0 0 3.8%;
  }

  .unsubscribe__image {
    margin-bottom: 7.8%;
    width: 43.5%;
    aspect-ratio: 156/164;
  }

  .unsubscribe__description,
  .unsubscribe__clarifying {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .unsubscribe__button {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    margin: 7% 0 0;
    padding: 3.8% 4.9%;
    width: 100%;
  }
}

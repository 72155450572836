@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.more-info-status {
  @include fonts_mobile.mobile-font-medium-size14-line17;

  padding: 20px 8px 8px;
  margin-bottom: 1.4em;
  color: var(--grey-dark);
  display: flex;
  flex-direction: column;
  gap: 18px;

  & span {
    font-weight: 400;
  }

  .more-info-status__project:active .more-info-status__project-name {
    color: var(--blue-dark);
  }

  & .more-info-status__project-name {
    text-decoration: underline;
    color: var(--blue-main);
    font-weight: 500;
  }

  & > p {
    margin: 0;
  }

  & .more-info-status__comment {
    font-weight: 400;
  }

  & button {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    & > p {
      margin: 0;
      text-align: left;
    }

    border: 0;
    padding-left: 0;
    align-self: self-start;
    margin: 0;

    & .icon-button {
      align-items: flex-end;
    }
  }
}

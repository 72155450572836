@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.budget {
  max-width: 64%;
  margin-bottom: 2.5%;
}

.budget__subtitle {
  color: var(--grey);
  margin: 3.37% 0 2%;

  @include fonts.font-regular-size18-line24;
}

.budget__input {
  width: 18%;
}

.budget__input--error {
  border-color: var(--error-main);
}

.budget-input__error {
  display: block;
  margin: 2px 0 8px;
  color: var(--error-main);

  @include fonts.font-regular-small;
}

.budget-doc__preview {
  width: fit-content;
}

.budget__hint {
  @include fonts.font-size(16px);

  line-height: 140%;
  margin: 0;
  color: var(--grey-main);
}

.budget__hint-accent {
  font-weight: 500;
}

@media (max-width: common.$mobile-w) {
  .budget {
    max-width: 100%;
    padding: 0 8px;
  }

  .budget__subtitle {
    @include fonts_mobile.mobile-font-regular-14px;
  }

  .budget-doc__preview {
    width: 100%;
  }

  .budget__input {
    width: 100%;
    height: 45px;

    @include fonts_mobile.mobile-font-regular-14px-line20;

    &::placeholder {
      @include fonts_mobile.mobile-font-regular-14px-line20;
    }
  }

  .budget__hint {
    margin: 0;
    font-size: 12px;
    line-height: 135%;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.all-user-payments-table-row {
  @include fonts.font-size(16px);

  color: var(--grey-dark);

  & span,
  & a {
    white-space: pre-wrap;
    word-wrap: break-word;
    width: fit-content;
    height: fit-content;
  }

  &__link {
    @include fonts.font-size(16px);

    color: var(--grey-dark);
    font-weight: 500;
    transition: 0.5s;

    &:hover {
      @media (hover: hover) {
        color: var(--blue-light);
      }
    }

    &:active {
      color: var(--blue-main);
    }
  }
}

.all-user-payments-table-row__status {
  &--completed {
    color: var(--green-main);
  }

  &--notCompleted {
    color: var(--error-main);
  }
}

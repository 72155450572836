@use "@platform-for-public-places/components-library/styles/_common.scss";

.comments-list {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.comments-list__tail {
  height: 20px;

  svg {
    width: 3em;
    height: 2em;
    margin: 0 auto;
  }

  circle {
    fill: var(--green-dark);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .comments-list {
    padding: 3px 8px 0;
  }
}

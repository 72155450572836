@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.add-person-modal__container {
  background: var(--main-white);
  border-radius: 10px;
  padding: 1.04%;
  z-index: 1;
  color: var(--grey-dark);
}

.add-person-modal__title {
  @include fonts.font-size(32px);

  line-height: 120%;
  margin: 0 0 1em;
}

.add-person-modal__close-wrapper {
  display: flex;
  justify-content: flex-end;
}

.add-person-modal__block {
  padding: 1em 2.5em;

  & .dropdown-container {
    @include fonts.font-size(10px);

    margin-top: 2.4em;
  }
}

.add-person-modal__dropdown-title {
  @include fonts.font-regular-small14;

  margin: 0 0 1em;
}

.add-person-modal__button {
  @include fonts.font-medium-small;

  width: 16.5em;
  padding: 2.14% 0;
  margin-top: 2em;
}

.search-dropdown__paginate {
  [class*="-placeholder"] {
    color: var(--grey-light);
  }

  [id*="-input"] {
    color: var(--grey-dark) !important;
  }

  [class*="singleValue"] {
    color: var(--grey-dark);
  }
}

.selector__placeholder {
  color: var(--grey-light) !important;
}

@media screen and (max-width: common.$mobile-w) {
  .add-person-modal__container-mobile {
    color: var(--grey-dark);
  }

  .add-person-modal__block {
    padding: 8px;
  }

  .add-person-modal__button {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    padding: 2.85% 0;
    width: 100%;
  }

  .add-person-modal__dropdown-title {
    @include fonts_mobile.mobile-font-regular-14px;
  }

  .add-person-modal__title {
    @include fonts_mobile.mobile-font-medium-size20-line28;
  }
}

@media screen and (min-width: common.$two-k-w) {
  .add-person-modal__button {
    width: 10vw;
  }
}

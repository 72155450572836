@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.reporting-chart-bottom-label {
  @include fonts.font-regular-small;

  fill: var(--grey-dark);

  &_empty {
    fill: var(--grey-main);
  }
}

@media screen and (max-width: common.$hd-w) and (min-width: common.$mobile-w) {
  .reporting-chart-bottom-label {
    @include fonts.font-regular-small14;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .reporting-chart-bottom-label {
    @include fonts_mobile.mobile-font-regular-10px;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.edit-layout-side-menu {
  flex: 2.5;
  background-color: var(--secondary-dark);
}

.edit-layout-side-menu__back-wrapper {
  width: 50.4%;
  margin: 15.6% auto 0;
}

.edit-layout-side-menu__back {
  @include fonts.font-medium-size20-line24;

  border: 0;
  padding: 0;
  color: var(--grey-dark);

  & > svg {
    transform-origin: center;
    transform: rotate(180deg);
    transition: 0.5s;
    height: 1.2em;
    width: 1.2em;
  }
}

.edit-layout-side-menu__steps {
  margin: 28.7% 0 0 9.2%;
  display: flex;
  flex-direction: column;
}

.edit-layout-side-menu__step {
  @include fonts.font-medium-size20-line24;

  color: var(--grey-dark);
  transition: 0.5s;
  display: flex;
  cursor: pointer;
  padding: 7.5% 0 7.5% 20%;
  border-radius: 10px 0 0 10px;
  text-decoration: none;

  &:hover {
    @media (hover: hover) {
      background-color: var(--secondary-main);
    }
  }

  &:active {
    background-color: var(--main-white);
  }

  &.active {
    background-color: var(--main-white);
  }
}

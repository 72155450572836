@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.circle-progress-bar__card {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 80%;
  transition: 0.5s;

  & svg {
    fill: transparent;
    transform: rotate(-90deg);

    & circle {
      stroke: var(--secondary-main);
      stroke-width: 7;
      stroke-linecap: round;
    }
  }

  & .circle-progress-bar__circle-offset {
    stroke-dasharray: 280;
    animation: slidein 3s linear;
    stroke-dashoffset: var(--progress);
    stroke: var(--green-main);
  }
}

.circle-progress-bar__number {
  @include fonts.font-medium-size24-line28;

  color: var(--green-main);
  position: absolute;
  transition: 0.5s;
}

.circle-progress-bar__number-more {
  @include fonts.font-medium-size20-line24;
}

.circle-progress-bar__number-zero {
  color: var(--blue-main);
}

.circle-progress-bar__card--archived {
  & .circle-progress-bar__number {
    color: var(--grey-main);
  }

  & svg .circle-progress-bar__circle-offset {
    stroke: var(--grey-main);
  }
}

@keyframes slidein {
  0% {
    stroke-dashoffset: calc(280 - (280 * 0) / 100);
  }

  100% {
    stroke-dashoffset: var(--progress);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .circle-progress-bar__number {
    @include fonts_mobile.mobile-font-medium-size24-line30;
  }

  .circle-progress-bar__number-more {
    @include fonts_mobile.mobile-font-medium-size20-line28;
  }
}

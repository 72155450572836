@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.donation-success-modal {
  background: var(--main-white);
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 4.1% 3.1% 5.3%;
  max-width: 22vw;

  .donation-success__text {
    font-weight: 400;
    margin: 0 0 2.9em;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.zoom-control {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.zoom-control__icon {
  fill: var(--grey-dark);
  transition: 0.5s;

  &--active {
    fill: var(--blue-main);
  }
}

.zoom-control__button {
  @include fonts.font-size(10px);

  width: 4.8em;
  height: 4.8em;
  background: var(--main-white);
  border-radius: 1em;
  padding: 1.2em;
  cursor: pointer;

  .zoom-control__icon {
    font-size: inherit;
    width: 2.4em;
    height: 2.4em;
  }

  &:hover .zoom-control__icon {
    @media (hover: hover) and (pointer: fine) {
      fill: var(--blue-main);
    }
  }

  &:active .zoom-control__icon {
    fill: var(--blue-main);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .zoom-control__button {
    width: 46px;
    height: 46px;
    border-radius: 10px;
    padding: 11px;

    .zoom-control__icon {
      transition: 0.1s;
      width: 24px;
      height: 24px;
    }
  }
}

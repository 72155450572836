@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.search-control {
  @include fonts.font-size(16px);

  display: flex;
  gap: 0.6em;
  height: 3em;
  min-width: 1vw;
  background: var(--main-white);
  border-radius: 0.6em;
  padding: 0 1.25em;
  box-sizing: border-box;
  align-items: center;
  overflow: hidden;
}

.search-control__action {
  cursor: pointer;
  flex: 1;
  display: flex;

  &:disabled {
    cursor: default;
  }
}

.search-control__icon {
  fill: var(--grey-light);
  width: 100%;
  transition: 0.5s;
}

.search-control__input {
  font-size: inherit;
  flex: 10;
  border: none;
  outline: none;
  color: var(--grey-dark);
  padding: 0;
}


.search-control__input::placeholder {
  color: var(--grey-main);
}

.search-control__input::-webkit-input-placeholder {
  color: var(--grey-light);
}

@media screen and (max-width: common.$mobile-w) {
  .search-control {
    width: 100%;
    height: 46px;
  }

  .search-control__input,
  .search-control__input::placeholder {
    font-size: 14px;
  }

  .search-control__icon {
    width: 24px;
    height: 24px;
  }
}

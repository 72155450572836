@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.docs-uploader__title {
  color: var(--grey-dark);
  margin: 0;

  @include fonts.font-medium-size20-line24;
}

.docs-uploader__description {
  @include fonts.font-size(18px);

  line-height: 140%;
  margin: 1.1em 0;
  color: var(--grey-dark);

  & > a {
    color: var(--green-main);
    font-weight: 500;
  }
}

.docs-uploader__hint {
  @include fonts.font-size(16px);

  line-height: 140%;
  margin: 0 0 0 0.6em;
  color: var(--grey-main);
}

.docs-uploader__hint-accent {
  font-weight: 500;
}

.docs-uploader .file-input__error--active {
  margin-bottom: 0.5em;
}

.docs-uploader__preview-wrapper {
  min-height: 0;
}

.docs-uploader__preview {
  @include fonts.font-size(16px);

  display: flex;
  margin: 1em 0 0;
  gap: 1em;
  flex-wrap: wrap;
}

.docs-uploader__preview-block {
  @include fonts.font-size(14px);

  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 39%;
  border: 1px solid var(--secondary-dark);
  border-radius: 5px;
  padding: 0.75em 1.35em 0.75em 0.75em;
  gap: 0.43em;

  & > svg {
    width: 1.7em;
    height: 1.7em;
    flex-shrink: 0;
  }

  & > .preview-block__name {
    display: block;
    flex-grow: 1;
    color: var(--blue-main);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    line-height: 140%;
    height: fit-content;
  }

  & > .preview-block__cross {
    cursor: pointer;
    width: 1.2em;
    height: 1.2em;

    & > svg {
      width: 1.2em;
      height: 1.2em;

      & > path {
        fill: var(--blue-main);
      }
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .docs-uploader:last-of-type {
    padding-bottom: 21%;
  }

  .docs-uploader__title {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    margin: 0;
  }

  .docs-uploader__description {
    @include fonts_mobile.mobile-font-regular-14px;

    margin: 0.6em 0;
  }

  .docs-uploader__hint {
    margin: 0;
    font-size: 12px;
    line-height: 135%;
  }

  .docs-uploader__preview-block {
    gap: 10px;
    width: unset;
    min-width: 62%;
    max-width: 100%;
    font-size: 14px;
    padding: 0.9em;
  }
}

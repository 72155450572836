@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.sign-in__header {
  @include fonts.font-medium-big;

  text-align: center;
  margin: 0;
  color: var(--grey-dark);
}

.sign-in__no-account {
  display: flex;
  gap: 5px;
  width: fit-content;
  margin: 0 auto;
  align-items: center;
}

.sign-in__no-account-link,
.sign-in__no-account-label {
  @include fonts.font-medium-small;
}

.sign-in__no-account-label {
  color: var(--grey-dark);
}

.sign-in__no-account-link {
  padding: 0;
  border: none;
  color: var(--green-main);
  text-decoration: underline;
}

.sign-in__form {
  margin-top: 9%;
}

.sign-in__input {
  min-width: 19vw;
  border-width: 2px;

  &--email {
    padding: 3.6% 2.8% 3.6% 5.5%;
  }

  &--password {
    margin-top: 8.4%;
  }

  &--error {
    border: 2px solid var(--error-main);
  }
}

.sign-in__error-container {
  height: 0;
  margin-top: 9.5%;
  transition: 0.5s;

  & .sign-in__error-message {
    opacity: 0;
  }

  &--visible {
    height: 23px;
    margin-top: 2.5%;
    transition: 0.5s;

    & .sign-in__error-message {
      opacity: 1;
    }
  }
}

.sign-in__error-message {
  @include fonts.font-regular-very-small;

  color: var(--error-main);
  margin: 0;
}

.sign-in__button-container {
  display: flex;
  justify-content: space-between;
}

.sign-in__forgot-password-button {
  width: 50%;
  border-color: transparent;
}

.sign-in__button {
  width: 50%;
}

@media screen and (max-width: common.$mobile-w) {
  .sign-in {
    border: none;
    padding: 0;
    margin: 0 8px;
  }

  .sign-in__no-account {
    margin-top: 20px;
  }

  .sign-in__no-account-label,
  .sign-in__no-account-link {
    @include fonts_mobile.mobile-font-bold-14px-line20;
  }

  .sign-in__form {
    margin-top: 16px;
  }

  .sign-in__input {
    padding: 3% 4%;
    border-radius: 10px;

    @include fonts_mobile.mobile-font-regular-14px-line20;

    &::placeholder {
      @include fonts_mobile.mobile-font-regular-14px-line20;
    }

    &--password {
      margin-top: 18px;
      padding: 0;

      & > input {
        padding: 3% 4%;
        width: calc(100% - 34px);
        border-radius: 10px;

        @include fonts_mobile.mobile-font-regular-14px-line20;
      }

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .sign-in__error-container {
    margin-top: 46px;
    margin-left: 3px;

    &--visible {
      display: flex;
      padding: 0;
      height: 14px;
      margin: 16px 0 16px 3px;
    }
  }

  .sign-in__error-message {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .sign-in__button-container {
    flex-direction: column-reverse;
    align-items: center;
  }

  .sign-in__button,
  .sign-in__forgot-password-button {
    padding: 3.4% 0;
    width: 100%;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

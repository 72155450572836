@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.layout-with-logo {
  display: flex;
  flex-direction: column;
  height: common.get-mobile-vh(100);

  &__gate {
    overflow: auto;

    @include scroll.scroll-none;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.share-plate__background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}

.share-plate {
  display: flex;
  flex-direction: column;
  padding: 3% 4.9% 6.3%;
  min-width: 18.82vw;
  background: white;
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
}

.share-plate__title {
  @include fonts.font-size(14px);

  font-weight: 500;
  line-height: 228.6%;
  margin: 0;
  color: var(--grey-dark);
}

.share-plate__links,
.share-plate__contacts {
  display: flex;
  flex-direction: row;
}

.share-plate__links {
  gap: 8px;
}

.share-plate__link-input {
  border: 1px solid var(--grey-light);
  border-radius: 5px;
}

.share-plate__link-copy-button {
  padding: 0.69em;
  margin: 0;
  border: 1px solid var(--secondary-dark);
  border-radius: 5px;

  & > svg {
    width: 1.5em;
    height: 1.5em;
    stroke: var(--blue-main);
  }

  &::after {
    position: absolute;
    top: 6em;
    right: -6em;
    content: "Скопировано";
    background: var(--secondary-main);
    color: var(--grey-main);
    padding: 9px 13px;
    border-radius: 8px;
    opacity: 0;
    max-width: 0;
    transition:
      opacity 0.3s,
      font-size 0.5s,
      max-width 0.5s,
      visibility 0s linear;
    transition-delay: 1s;
    font-size: 1px;
  }

  &:active {
    &::after {
      visibility: visible;
      opacity: 1;
      max-width: 100px;
      font-size: 12px;
      transition: opacity 0s, font-size 0s, max-width 0s, visibility 0s linear;
    }
  }
}

.share-plate__contacts {
  margin: 4.5% 0 0;
  gap: 16px;

  & > a {
    text-decoration: none;
    border-bottom: none;
  }

  & > .share-plate__contact--vk,
  & > .share-plate__contact--telegram,
  & > .share-plate__contact--classmates {
    padding: 0;
    margin: 0;

    & > svg {
      width: 1.9vw;
      height: 1.9vw;
      fill: var(--blue-main);

      & path {
        stroke: transparent;
      }
    }

    &:hover {
      background-color: transparent;

      svg {
        fill: var(--blue-light);

        & path {
          stroke: transparent;
        }
      }
    }

    &:active svg {
      fill: var(--blue-dark);

      & path {
        stroke: transparent;
      }
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.photo-carousel {
  grid-gap: 12px;
  display: grid;
  gap: 12px;
  grid-template: "cover preview" 1fr / 86% 12%;
  overflow: hidden;
  position: relative;
}

.photo-carousel__button {
  cursor: pointer;
}

.photo-carousel__stub,
.photo-carousel__stub--small {
  background-color: var(--grey-light);
}

.photo-carousel__cover {
  aspect-ratio: 16 / 9;
  width: 100%;
  object-fit: cover;

  .photo-carousel__content {
    aspect-ratio: 16 / 9;
    width: 100%;
    object-fit: cover;
  }
}

.photo-carousel__cover--reordered {
  width: 100%;

  .photo-carousel__content {
    width: 100%;
    max-height: 50vh;
    object-fit: cover;
  }
}

.photo-carousel__cover--single,
.photo-carousel__cover--single > .photo-carousel__content {
  width: 100%;
  height: unset;
  max-height: 50vh;
  object-fit: cover;
}

.photo-carousel__preview > .photo-carousel__content {
  width: 100%;
  height: auto;
  aspect-ratio: 1 / 1;
  object-fit: cover;
  flex-shrink: 0;
}

.photo-carousel__button--cover,
.photo-carousel__stub--cover {
  grid-area: cover;
}

.photo-carousel__button--preview,
.photo-carousel__stub--preview {
  grid-area: preview;
}

.photo-carousel__block--preview,
.photo-carousel__block--preview--reordered {
  @include scroll.scroll;

  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow-x: auto;
  padding-right: 4px;
}

.photo-carousel__block--preview--reordered {
  @include scroll.scroll;

  flex-direction: row;
  gap: 12px;
  margin-top: 20px;
  overflow-y: hidden;

  & .photo-carousel__content {
    aspect-ratio: 1/1;
    width: 3.8vw;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-status-tile {
  @include fonts.font-medium-very-small;

  position: absolute;
  left: 0.6em;
  bottom: 1em;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  padding: 4px 8px;
  border-radius: 5px;

  &--default {
    background-color: var(--green-main);
  }

  &--gov {
    background-color: var(--tag-blue);
  }

  &--event {
    background-color: var(--yellow-dark);
  }

  &--news {
    background-color: var(--tag-purple);
  }

  &__type-marker {
    width: 2em;
    height: 2em;

    & > * {
      fill: var(--main-white);
    }
  }

  &__status-name {
    color: var(--main-white);
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .project-status-tile {
    padding: 4px 8px;

    &__status-name {
      @include fonts_mobile.mobile-font-bold-12px-line17;
    }
  }
}

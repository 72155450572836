@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.recurrent-user-payments-list {
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__placeholder {
    @include fonts_mobile.mobile-font-regular-size16-line22;

    margin: 0;
  }
}

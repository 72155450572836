@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.detailed-info__categories {
  display: flex;
  gap: 1.3em;

  & > .category-button {
    color: var(--grey-dark);
    border-radius: 20px;
    padding: 0.1em 1.5em;
    transition: 0.5s;
    -webkit-tap-highlight-color: transparent;

    &::after {
      display: none;
    }

    & > button {
      padding: 0;

      @include fonts.font-size(14px);

      font-weight: 500;
      line-height: 2.3em;
    }

    &:hover {
      @media (hover: hover) {
        background-color: var(--secondary-main);
        transition: 0.5s;
      }
    }
  }

  & > .category-button--selected {
    background-color: var(--secondary-main);
    transition: 0.5s;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .detailed-info__categories {
    margin: 24px 8px 0;
    gap: 0;

    & > .category-button > button {
      font-size: 14px;
      line-height: 32px;
    }
  }
}

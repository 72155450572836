@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.user-payments {
  padding-bottom: 1.5em;
}

.user-payments__categories {
  display: flex;
  gap: 2.7vw;
  align-items: center;
}

.user-payments__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  min-height: 70px;
}

.user-payments__header-block {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.user-payments__title {
  margin: 0;
  padding: 0;
}

.user-payments__header-block-tooltip-container {
  svg {
    @include fonts.font-size(20px);

    flex-shrink: 0;

    path {
      stroke: var(--grey-dark);
    }
  }
}

.user-payments__header-block-tooltip {
  max-width: 10vw;
  margin-left: 0.5em;
  overflow-wrap: break-word;

  @include fonts.font-regular-small14;
}

.user-payments__total-donation {
  @include fonts.font-regular-size16-line32;
}

.user-payments__total-donation-amount {
  color: var(--green-main);

  @include fonts.font-medium-size20-line24;
}

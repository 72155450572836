@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.sign-up__header {
  @include fonts.font-medium-big;

  text-align: center;
  margin: 0;
  color: var(--grey-dark);
}

.sign-up__have-account {
  @include fonts.font-medium-small;

  width: fit-content;
  margin: 20px auto 0;
  color: var(--grey-dark);
}

.sign-up__have-account-link {
  font: inherit;
  color: var(--green-main);
  padding: 0;
  border: none;
  text-decoration: underline;
}

.sign-up__form {
  display: flex;
  flex-direction: column;
}

.sign-up__input {
  min-width: 19vw;
  border-width: 2px;
  padding: 3.6% 2.8% 3.6% 5.5%;
}

.sign-up__input-password {
  padding: 0;
}

.sign-up__input--error {
  border-color: var(--error-main);
}

.sign-up__error-container {
  height: 0;
  margin: 7.8% 0 0 2%;
  transition: 0.5s;

  &--visible {
    height: 0;
    margin: 0.8% 0 7% 2%;
    transition: 0.5s;
    overflow: visible;
  }
}

.sign-up__error-text {
  color: var(--error-main);
  animation: fadeIn 0.5s;
  display: block;

  @include fonts.font-regular-very-small;
}

.sign-up__agreement {
  margin-top: 4px;
  display: flex;
  gap: 5px;
  align-items: center;
}

.sign-up__agreement-label {
  transition: 0.5s;
}

#user-agreement.sign-up__agreement-checkbox {
  border-color: var(--green-main);

  &::before {
    background-color: var(--green-main);
  }
}

.sign-up__agreement-wrapper--error #user-agreement.sign-up__agreement-checkbox {
  border-color: var(--error-main);
}

.sign-up__agreement-wrapper--error label,
.sign-up__agreement-wrapper--error label a {
  color: var(--error-main);
}

.sign-up__agreement-link {
  color: var(--green-main);

  @include fonts.font-medium-very-small;
}

.sign-up__captcha {
  margin: 6% auto 0;
}

.sign-up__button {
  margin-top: 5.5%;
  width: 100%;
}

@media screen and (max-width: common.$mobile-w) {
  .sign-up {
    border: none;
    padding: 0 8px;
  }

  .sign-up__form {
    margin: 24px 0 0;
  }

  .sign-up__input {
    padding: 2.5% 4%;
    border-radius: 10px;

    @include fonts_mobile.mobile-font-regular-14px-line20;

    &::placeholder {
      @include fonts_mobile.mobile-font-regular-14px-line20;
    }

    &-password {
      padding: 0;

      & input {
        padding: 2.5% 4%;
        width: calc(100% - 34px);
        border-radius: 10px;

        @include fonts_mobile.mobile-font-regular-14px-line20;
      }

      & svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .sign-up__error-container {
    margin: 7% 0 0 2%;

    &--visible {
      margin: 0.5% 0 6.5% 2%;
    }
  }

  .sign-up__error-text {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .sign-up__captcha {
    margin: 20px auto 0;
  }

  .sign-up__button {
    padding: 3.4% 0;
    margin-top: 18px;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .sign-up__have-account {
    margin-top: 18px;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

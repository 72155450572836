@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.support-block {
  display: flex;
  flex-direction: column;
  gap: 1.25em;
}

.support-block__email-block {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.support-block__email {
  height: auto;
  padding: 1.8vh 1vw;
  width: 21vw;

  @include fonts.font-regular-small;

  &::placeholder {
    @include fonts.font-regular-small;
  }
}

.support-block__email--error {
  border-color: var(--error-main);
}

.support-block__error-text {
  color: var(--error-main);
  animation: fadeIn 0.5s;
  display: block;
  margin: 0;

  @include fonts.font-regular-very-small;
}

.support-block__error-btn {
  color: var(--error-dark);
  animation: fadeIn 0.5s;
  text-decoration: underline;
  cursor: pointer;

  @include fonts.font-regular-very-small;
}

.support-block__checkbox {
  width: 21vw;

  & > .checkbox__label {
    @include fonts.font-size(14px);

    line-height: 140%;
  }
}

.link-button {
  line-height: 140%;
  font-weight: 500;
  text-decoration: underline;

  @include fonts.font-size(14px);
}

@media screen and (max-width: common.$mobile-w) {
  .support-block {
    gap: 1em;
  }

  .support-block__email {
    padding: 0 22px;
    height: 63px;
    width: 100%;

    &::placeholder {
      @include fonts_mobile.mobile-font-regular-size16-line22;
    }

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .support-block__checkbox {
    width: 100%;

    & > .checkbox__label {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .support-block__error-text {
    color: var(--error-main);
    animation: fadeIn 0.5s;
    display: block;
    margin: 0;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .support-block__error-btn {
    color: var(--error-dark);
    animation: fadeIn 0.5s;
    text-decoration: underline;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .link-button {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.header-wrapper {
  display: flow-root;
}

.request-form-mobile__error {
  @include fonts_mobile.mobile-font-bold-10px;
}

.request-form-mobile {
  color: var(--grey-dark);

  .dropdown-tag__selector {
    min-width: 100%;

    & .selector__multi-value {
      border-radius: 14px;
      border: var(--blue-main) solid 1px;
    }

    & .selector__multi-value__label {
      padding: 6px 0 6px 14px;

      @include fonts_mobile.mobile-font-bold-10px;
    }
  }

  & .selector__option {
    text-transform: capitalize;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .become-specialist-form__input,
  .become-specialist-form__submit-wrapper,
  .become-specialist-form__submit-button {
    height: 45px;
  }
}

.request-form-mobile__selector-roles {
  & .search-dropdown__input {
    width: initial;
  }

  .dropdown-tag__group {
    text-transform: capitalize;

    @include fonts_mobile.mobile-font-regular-size12-line17;
  }

  .dropdown-tag__group-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 10px;
    height: 10px;
    border-radius: 10px;

    @include fonts_mobile.mobile-font-regular-size12-line20;
  }

  & .selector__option {
    text-transform: capitalize;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }
}

.request-form-mobile__block {
  padding: 0 2%;
}

.request-form-mobile__title-role {
  @include fonts_mobile.mobile-font-regular-14px;
}

.request-form-mobile__title-comment {
  margin: 14px 0 0;

  @include fonts_mobile.mobile-font-regular-14px;
}

.request-form-mobile__comment {
  margin: 8px 0 0;
  height: 48vh;
  color: var(--grey-dark);

  @include fonts_mobile.mobile-font-regular-14px;
}

.request-form-mobile__button {
  padding: 11px 0;
  position: fixed;
  width: 96%;
  bottom: 2%;
  left: 2%;

  @include fonts_mobile.mobile-font-regular-14px;
}

.request-form-mobile__huge {
  padding: 0 2.1%;
}

@media screen and (max-width: common.$mobile-w) {
  .file-input__trigger {
    height: 45px;
  }

  .request-form-mobile__huge {
    .become-specialist-form {
      margin: 0;
    }

    .photo-uploader-label {
      & > svg {
        height: 64px;
        width: 64px;
      }
    }

    .become-specialist-form__photo-uploader {
      padding-left: 5px;
    }

    .become-specialist-form__portfolio-input {
      height: 45px;
      gap: 0;

      .file-input__error--active {
        margin-top: 2px;
      }
    }

    .become-specialist-container__prompt {
      margin: 0 0 11px 3px;
      font-size: 11px;
      line-height: 20px;
      font-weight: 400;
    }

    .become-specialist-form__element {
      margin-bottom: 18px;

      & .search-dropdown__input {
        width: initial;
      }

      & input,
      input::placeholder,
      textarea,
      textarea::placeholder {
        @include fonts_mobile.mobile-font-regular-14px-line20;

        width: 100%;
      }

      .file-input__trigger {
        @include fonts_mobile.mobile-font-regular-14px;
      }

      .become-specialist-form__name {
        min-width: 359px;
      }

      .become-specialist-form__social-link-input {
        min-width: 313px;
      }

      .become-specialist__error-text {
        @include fonts_mobile.mobile-font-regular-10px;
      }

      .file-input__error--active {
        margin-left: 0;
      }

      .become-specialist-form__social-links > li {
        width: 100%;
      }
    }

    .become-specialist-form__submit-wrapper {
      padding-bottom: 2%;
      height: unset;
    }

    .become-specialist-form__label_portfolio-link__error {
      margin-top: 16px;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.team {
  position: relative;
}

.team-user {
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > .team__label-and-name {
    margin: 0;
  }
}

.team-user__accept-status {
  @include fonts.font-regular-small;

  display: flex;
  align-items: center;
  margin: 0;
  gap: 14px;
  padding: 0 4.5%;
  width: stretch;
  border-radius: 10px;
  background: var(--secondary-light);
}

.team__title {
  white-space: pre-line;
  flex: 4;
}

.team__title__header-text {
  margin: 0 0 1em;
}

.team__title__subheader-text {
  line-height: 140%;
  color: var(--grey-dark);
  margin: 0;
}

.team__title-block {
  display: flex;
  flex: 1;
}

.team__title-block-button {
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  & .team__add-button {
    padding: 2% 3.1%;
  }
}

.team__label-and-name {
  @include fonts.font-regular-small;

  flex: 3;
  margin-top: 2em;
  color: var(--grey-dark);
}

.team__initiator-label {
  font-weight: 500;
}

.team__buttons {
  border-bottom: 1px solid var(--blue-main);
  display: flex;
  justify-content: space-between;
}

.team-buttons__button {
  @include fonts_mobile.mobile-font-bold-14px-line20;

  position: relative;
}

.team-buttons__button--active::after {
  position: absolute;
  content: "";
  border-bottom: 2px solid var(--blue-main);
  bottom: -4px;
  left: 0;
  width: 100%;
}

.team__add-button {
  margin-top: 3.3%;
  display: flex;
  align-items: center;
  background-color: unset;
  padding: 1.2% 2%;

  &:disabled {
    background-color: unset;

    &:hover {
      @media (hover: hover) {
        background-color: unset;
      }
    }
  }

  & > svg {
    margin-right: 8px;
  }
}

.team__members {
  @include scroll.scroll;

  position: unset;
  margin-top: 2.75em;
  display: flex;
  padding: 0 2.5% 0 1px;
  flex-direction: column;

  .members__button-list {
    @include fonts.font-size(16px);

    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 2em;
    overflow: unset;
  }

  .members__button {
    margin-top: 0;
  }
}

.team-member__delete-button {
  padding: 0;
  margin: 0;
  border: 0;
}

.team-members-status__spinner {
  & svg {
    width: 1.5em;
    height: 1.5em;
    fill: var(--blue-dark);
  }
}

.team-member__container {
  width: 30em;

  .member__block {
    margin: 0;
  }
}

.team-user__button-block {
  display: flex;
  flex: 4;
  gap: 4%;
}

.team-user-status__error {
  fill: var(--error-main);
}

.accept-status__text {
  flex: 14;
}

.team-user-status__rejected {
  color: var(--error-main);

  & button svg path {
    stroke: var(--error-main);
  }
}

.team-user-status__active {
  color: var(--blue-dark);

  & button svg path {
    stroke: var(--blue-dark);
  }
}

.team-user-status__execute {
  color: var(--green-light);

  & button svg path {
    stroke: var(--green-light);
  }
}

.team__notification-create {
  @include fonts.font-regular-small;

  display: flex;
  color: var(--blue-main);
  background: var(--secondary-light);
  align-items: center;
  padding: 2% 8.6% 2% 2%;
  margin: 3.8% 0;
  gap: 1.7%;
  border-radius: 10px;

  & > p {
    margin: 0;
  }

  & > svg {
    width: 1.5em;
    height: 1.5em;
    flex-shrink: 0;

    & > path {
      stroke: var(--blue-main);
    }
  }
}

.team__navigate-buttons {
  position: absolute;
  width: stretch;
  bottom: 0;
}

.team-create {
  max-width: 66%;

  & .team__members {
    gap: 10px;
  }
}

.team__table-header {
  @include fonts.font-size(20px);

  line-height: 160%;
  margin: 2.7em 0 1.6em;
  color: var(--grey-dark);
}

.team__table-tabs {
  display: flex;
  gap: 50px;
}

.team__table-placeholder {
  @include fonts.font-size(18px);

  line-height: 140%;
  color: var(--grey-main);
}

.team__tables-tab-label {
  @include fonts.font-size(16px);

  line-height: 140%;
  font-weight: 400;
}

.team__tables-tab-conter {
  @include fonts.font-size(14px);

  font-weight: 500;
}

@media screen and (max-width: common.$mobile-w) {
  .team {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .team-user__accept-status {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    padding: 0;
    width: 85%;

    & p {
      margin: 0;
    }
  }

  .team__title-block {
    flex: 0;
  }

  .team__title__subheader-text {
    line-height: 133%;
  }

  .team-edit {
    height: inherit;
  }

  .team__label-and-name {
    flex: none;
    margin: 0.25em 0 0.5em;
    font-size: 14px;
    line-height: 229%;
  }

  .team-user {
    flex-direction: column;
    background: var(--secondary-light);
    border-radius: 10px;
    padding: 16px;
    align-items: flex-start;
  }

  .team-user__button-block {
    & span {
      @include fonts_mobile.mobile-font-medium-size14-line17;
    }

    width: 100%;
    box-sizing: border-box;

    & > button > svg {
      height: 1.85em;
      width: 1.85em;
    }
  }

  .edit-layout__gate {
    .team {
      padding: 0 8px;
    }

    .team__title__header-text {
      display: none;
    }
  }

  .team__add-button {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    padding: 3.5%;
    justify-content: center;
    margin-bottom: 0;
  }

  .team__add-button-create {
    margin-bottom: 19%;
  }

  .team__add-button--show {
    transition: 0.5s;
  }

  .team__members {
    @include scroll.scroll-none;

    flex: 45;
    flex-grow: 1;
    max-height: inherit;
    transition: flex 1s;
    margin-top: 0;

    & > .members__button-list > .member__container {
      align-items: flex-start;
      width: unset;

      &:first-of-type {
        margin-top: 16px;
      }

      & > .member__block {
        width: auto;

        & > .member__name,
        & > .member__name > .member__name_clickable,
        & > .member__name > .member__name_clickable-reverted {
          @include fonts_mobile.mobile-font-medium-size14-line17;

          margin: 0;
        }
      }
    }
  }

  .team__members-no-one {
    transition: flex 1s ease-in-out;
    flex: 0;
    flex-grow: 1;

    & + .team__add-button {
      flex: 0;
    }
  }

  .team-member__buttons {
    display: flex;
    border: none;
    width: calc(100% - 16px);
    margin: 0 8px 15px;
    gap: 8px;
    padding: 0;
    justify-content: center;
    background: var(--main-white);

    span {
      @include fonts_mobile.mobile-font-regular-size16-line22;
    }
  }

  .team-member__button {
    flex: 1;
    height: 48px;
    padding: 0;
  }

  .team-member__delete-button > svg {
    width: 20px;
    height: 20px;
  }

  .team-member-reject__container {
    margin: 0 8px;
  }

  .team-member-reject__title {
    margin: 8px 0 1.3em;
    max-width: 70vw;
    color: var(--grey-dark);

    @include fonts_mobile.mobile-font-bold-20px;
  }

  .team-member-reject__comment {
    height: 250px;
    margin: 0;
    margin-bottom: 1.7em;
    padding: 5% 4.2%;

    @include fonts_mobile.mobile-font-regular-14px;

    &:focus,
    &:active {
      height: 250px;
    }

    & > textarea::placeholder {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .team__requests-list {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    gap: 10px;
    margin: 18px 0;
  }

  .team__requests-placeholder {
    font-size: 14px;
    line-height: 120%;
    color: var(--grey-main);
  }

  .team__add-button--no-margin {
    margin-bottom: 0;
  }

  .team__notification {
    @include fonts_mobile.mobile-font-regular-size12-line17;

    height: unset;
    margin: 0 auto 5%;
    padding: 16px 14px;
    gap: 4.2%;
    width: calc(100% - 44px);
    left: 50%;

    & > svg {
      width: 1em;
      height: 1em;
    }
  }

  .team__notification-create {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    gap: 4.2%;
    margin-bottom: 19%;

    & > svg {
      width: 1.7em;
      height: 1.7em;
    }
  }
}

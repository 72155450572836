.donation-success-page {
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;

  .donation-success {
    margin-top: 41px;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.user-payments-item-list {
  display: flex;
  gap: 0.7em;
  color: var(--grey-dark);
  margin-bottom: 1.5em;

  &__icon {
    min-width: 32px;
    min-height: 32px;
  }

  &__payment-project {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__payment-status,
  &__payment-date {
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  &__link {
    @include fonts_mobile.mobile-font-bold-14px-line20;

    margin: 0;
    color: var(--grey-dark);
    font-weight: 500;
    transition: 0.5s;

    &:hover {
      @media (hover: hover) {
        color: var(--blue-light);
      }
    }

    &:active {
      color: var(--blue-main);
    }
  }

  &__payment-info {
    margin-left: auto;
    text-align: right;
  }

  &__amount {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    margin: 0;
  }
}

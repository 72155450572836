@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-preview__mock-image {
  border-radius: 10px;
  background-repeat: no-repeat;
  height: 190px;
  background-size: cover;
  min-width: 270px;
  background-position-y: center;
  background-image: url("./img/mock.svg");
}

.project-preview {
  border: 2px solid transparent;
  display: flex;
  border-radius: 12px;
  padding: 8px 10px;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.5s;
  color: var(--grey-dark);

  &--large {
    padding: 18px 10px 44px;
  }

  &:hover {
    @media (hover: hover) {
      border-color: var(--secondary-dark);

      .project-preview__cover::after {
        opacity: 0.4;
      }
    }
  }

  &:active {
    .project-preview__cover::after {
      opacity: 0;
    }

    .progress-bar__background {
      background-color: var(--main-white);
    }
  }
}

.project-preview__cover {
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: black;
    border-radius: 10px;
    height: calc(100% - 4px); // idk why but there was 4px at bottom
    width: 100%;
    opacity: 0;
    transition: 0.5s;
  }
}

.project-preview__image {
  border-radius: 10px;
  object-fit: cover;

  // Хардкожено, чтоб отображалось красиво даже для этих ваших яблочных.
  // Адаптив достигается за счёт грида, который сам решает сколько таких карточек показывать.
  aspect-ratio: 3/2;
  width: 11.774vw;
  min-width: 100%;

  &--large {
    height: 70%;
  }
}

.project-preview__title {
  margin: 12px 0 0;
  height: 2.6em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 11.774vw;
  word-break: break-word;

  @include fonts.font-medium-small;

  &--large {
    width: unset;
    margin-top: 10px;
  }
}

.project-preview__status {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @include fonts.font-regular-very-small;
  @include fonts.font-regular-size18-line24;
}

.project-preview__progress {
  height: 1.5vh;
  border-radius: 28px;
  margin-top: 10px;
}

.project-preview__reactions {
  display: flex;
  margin-top: 8px;

  @include fonts.font-medium-small14;

  &--large {
    margin-top: 18px;
  }
}

.project-preview__reaction-icon {
  stroke: var(--blue-main);
  stroke-width: 2;
  transition: 0.5s;

  & > path {
    stroke: var(--blue-main);
  }

  &--active {
    & > path {
      fill: var(--blue-main);
    }

    &_joined {
      & > path {
        stroke: none;
      }
    }
  }
}

.project-preview__reaction-container {
  @include fonts.font-medium-small;

  display: flex;
  align-items: center;
  color: var(--blue-main);
  transition: 0.5s;

  & > p {
    margin: 0 8px 0 4px;
  }

  &:visited {
    color: var(--visited);

    & > .project-preview__reaction-icon {
      stroke: var(--visited);
    }
  }

  &:hover {
    @media (hover: hover) {
      color: var(--blue-light);

      & > .project-preview__reaction-icon {
        stroke: var(--blue-light);
      }
    }
  }

  &:active {
    color: var(--blue-dark);

    & > .project-preview__reaction-icon {
      stroke: var(--blue-dark);
    }
  }
}

.project-preview__reaction-container--large {
  & > p {
    margin: 0 10px 0 4px;
  }

  & > svg {
    height: 1em;
    width: 1em;
  }
}

.budget-info {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &__collected {
    @include fonts.font-regular-very-small;

    margin: 0;
  }

  &__amount {
    @include fonts.font-regular-very-small;

    margin: 0;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .budget-info {
    &__collected,
    &__amount {
      @include fonts_mobile.mobile-font-regular-size12-line17;
    }
  }

  .project-preview__title {
    @include fonts_mobile.mobile-font-medium-size16-line19;

    width: unset;
  }

  .project-preview__status span {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .project-preview__image {
    width: 100%;
  }

  .project-preview__reaction-text {
    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .project-preview__reaction-container--large {
    & > svg {
      height: 16px;
      width: 16px;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.donation-form {
  display: flex;
  flex-direction: column;
}

.donation-form__title {
  margin: 0;

  @include fonts.font-medium-size20-line24;
}

.donation-form__description {
  margin: 1.8vh 0 0.7vh;

  @include fonts.font-regular-small;
}

.donation-from__sum {
  margin: 1.7vh 0 2.4vh;
  height: auto;
  padding: 1.8vh 1vw;
  width: 21vw;

  @include fonts.font-regular-small;

  &::placeholder {
    @include fonts.font-regular-small;
  }
}

.donation-form__checkbox-container {
  display: flex;
  flex-direction: column;
  gap: 1.25em;
}

.donation-form__checkbox {
  width: 21vw;

  & > .checkbox__label {
    @include fonts.font-size(14px);

    line-height: 140%;
  }
}

.donation-form__delay-warning {
  display: flex;
  align-items: center;
  margin-top: 1.25em;
  gap: 2.2%;

  svg {
    @include fonts.font-size(20px);

    width: 1em;
    height: 1em;
    flex-shrink: 0;

    path {
      stroke: var(--blue-main);
    }
  }

  span {
    @include fonts.font-size(14px);

    line-height: 140%;
  }
}

.donation-form__button {
  margin: 3vh 0 1.4vh;
  height: auto;
  width: 21vw;
  padding: 2.5vh 0;

  @include fonts.font-medium-size24-line28;
}

@media screen and (max-width: common.$mobile-w) {
  .donation-form__title {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .donation-form__description {
    margin: 6px 0;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .donation-from__sum {
    padding: 0 22px;
    height: 63px;
    width: 100%;
    margin: 18px 0 21px;

    &::placeholder {
      @include fonts_mobile.mobile-font-regular-size16-line22;
    }

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .donation-form__checkbox {
    width: 100%;

    & > .checkbox__label {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .donation-form__delay-warning {
    svg {
      width: 20px;
      height: 20px;
    }

    span {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .donation-form__button {
    padding: 0;
    height: 47px;
    width: 100%;
    margin-top: 27px;

    @include fonts_mobile.mobile-font-medium-size14-line17;
  }
}

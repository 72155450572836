.reporting-chart-tooltip__label {
  margin: 0 0 0.5em;
  text-transform: capitalize;
}

.reporting-types-list {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 0.25em;
}

.reporting-types-list__projects {
  color: var(--green-main);
}

.reporting-types-list__platform {
  color: var(--tag-purple);
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.remarkable__button-img {
  cursor: pointer;
  margin: 1em 0;
  width: stretch;

  & img {
    width: 100%;
  }
}

.instruction-container {
  display: flex;
  height: 100%;
}

.instruction-container--mobile {
  flex-direction: column;
  justify-content: center;
}

.instruction-left-container {
  display: flex;
  flex: 5 1;
  margin-top: 10%;
  justify-content: center;
}

.instruction-table-of-content {
  max-width: 75%;
}

.instruction-middle-container {
  @include scroll.scroll-none;

  display: flex;
  align-items: center;
  flex: 9;
  flex-direction: column;
  max-height: 100%;
  overflow: auto;
  align-self: flex-start;
  line-height: 155%;

  & a {
    &:link {
      color: var(--green-main);
    }

    &:visited {
      color: var(--green-light);
    }

    &:hover {
      @media (hover: hover) {
        color: var(--green-light);
      }
    }

    &:active {
      color: var(--green-dark);
    }
  }

  & h1 {
    @include fonts.font-medium-big;

    padding-top: 5%;
    margin-top: 0;
  }

  & h3 {
    @include fonts.font-medium-size20-line24;

    padding-top: 5%;
    margin-top: 0;
  }

  & h2 {
    @include fonts.font-medium-size20-line24;

    padding-top: 5%;
    margin-top: 0;
  }

  & li {
    @include fonts.font-regular-medium22;

    color: var(--grey-dark);
  }

  & p {
    @include fonts.font-regular-medium22;

    color: var(--grey-dark);
  }

  & strong {
    font-weight: 500;
  }
}

.instruction-right-container {
  flex: 5;
}

.instruction-footer {
  display: flex;
  margin-bottom: 7%;
  max-width: 75%;
  margin-top: 4%;
  text-align: center;

  & p {
    @include fonts.font-regular-small;

    color: var(--grey-main);
  }
}

.instruction-link {
  @include fonts.font-medium-small;

  transition: 0.5s;
  align-items: center;
  padding: 3% 4.5%;
  width: 100%;
  text-decoration: none;
  text-align: initial;
  gap: 8px;
  display: flex;
  color: var(--grey-main);
  cursor: pointer;

  &:hover {
    @media (hover: hover) {
      color: var(--grey-dark);
    }
  }

  &:active {
    color: var(--grey-dark);
    background: var(--secondary-main);
    border-radius: 10px;
  }

  &--reverted {
    flex-direction: row-reverse;
    width: fit-content;
    margin-right: auto;
  }
}

.instruction-links {
  display: flex;
  gap: 6px;
  flex-direction: column;
  width: 88%;
  align-items: flex-end;
}

.instruction-links__header {
  @include fonts.font-medium-size20-line24;

  margin-bottom: 11%;
  margin-right: 4.4%;
}

.instruction-sub-link {
  @include fonts.font-medium-small;

  padding: 1.8% 4.8% 1.8% 2.4%;
  color: var(--grey-main);
  cursor: pointer;
  text-align: end;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  &::after {
    content: " ";
    vertical-align: middle;
    padding: 4px;
    background: var(--secondary-main);
    font-size: 0;
    margin-left: 3%;
    visibility: hidden;
    border-radius: 10px;
  }

  &:hover {
    @media (hover: hover) {
      color: var(--grey-dark);
    }
  }

  &:active {
    color: var(--grey-dark);
  }
}

.instruction-sub-link--selected {
  color: var(--grey-dark);

  &::after {
    visibility: visible;
  }
}

.instruction-link--selected {
  color: var(--grey-dark);
  background: var(--secondary-main);
  border-radius: 10px;
}

.instruction-sub-links {
  @include scroll.scroll-none;

  display: flex;
  height: fit-content;
  flex-direction: column;
  transition: max-height 1s cubic-bezier(0, 1, 0, 1);
  overflow: auto;
  align-items: flex-end;
  max-height: 0;
  width: 100%;
}

.instruction-sub-links_open {
  max-height: 1000px;
  transition: max-height 1.5s ease-in-out;
}

.instruction-sub-links_open:last-child {
  padding-bottom: 10px;
}

.instruction-link__icon {
  visibility: hidden;
}

.instruction-link__no-icon {
  visibility: hidden;
  width: 1.5em;
  height: 1.5em;
}

.instruction-link--selected > .instruction-link__icon {
  visibility: visible;
  width: 1.5em;
  height: 1.5em;
}

.instruction-link:hover > .instruction-link__icon {
  @media (hover: hover) {
    visibility: visible;
  }
}

.instruction-link__chevron {
  transform: rotate(-90deg);
  transition: 0.5s;

  & > svg {
    width: 1.5em;
    height: 1.5em;

    & > path {
      fill: var(--grey-dark);
    }
  }
}

.instruction-link__chevron--active {
  transform: rotate(0deg);
}

@media screen and (max-width: common.$mobile-w) {
  .instruction-links {
    width: 100%;
  }

  .instruction-links__open-button {
    width: 96%;
    height: 46px;
    margin: 0 2%;

    @include fonts_mobile.mobile-font-regular-16px-line32;
  }

  .instruction-links__bottom-sheet [data-rsbs-overlay] {
    max-height: 50%;
    background: var(--secondary-dark);
  }

  .instruction-links__bottom-sheet [data-rsbs-header]::before {
    background: var(--main-white);
  }

  .instruction-table-of-content {
    max-width: 100%;
    margin: 4px 0 common.get-mobile-vh(9) 7%;
  }

  .instuction-table-of-content__header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 4px 20px 4px 0;
  }

  .instruction-table-of-content__header {
    display: inline;
    margin: 0;
    color: var(--grey-dark);

    @include fonts_mobile.mobile-font-bold-14px-line32;
  }

  .instruction-table-of-content__button-close {
    width: 24px;
    height: 24px;
    padding: 0;
    border: none;

    & > svg {
      fill: var(--grey-dark);
      stroke: none;
    }
  }

  .instruction-middle-container {
    margin: 3%;

    & h1,
    & h3,
    & h2 {
      @include fonts_mobile.mobile-font-bold-16px-line32;
    }

    & p,
    & li {
      @include fonts_mobile.mobile-font-regular-16px-line32;
    }
  }

  .instruction-link,
  .instruction-links,
  .instruction-sub-link,
  .instruction-sub-links {
    color: var(--grey-dark);

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .instruction-link,
  .instruction-sub-link {
    text-align: start;
    padding: 11px 8px;
  }

  .instruction-sub-link {
    border-radius: 10px 0 0 10px;
    justify-content: flex-start;

    &::after {
      display: none;
    }

    &::before {
      content: " ";
      padding: 4px;
      background: var(--blue-main);
      font-size: 0;
      margin: 8px 3% 0 6%;
      border-radius: 10px;
      align-self: flex-start;
    }

    &:hover {
      @media (hover: hover) {
        background: var(--secondary-main);
      }
    }
  }

  .instruction-link--selected {
    background: var(--secondary-dark);
  }

  .instruction-sub-link--selected {
    background: var(--secondary-main);
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.recovery-password-success {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.recovery-password-success__title {
  text-align: center;
  margin: 0 0 1em;
}

.recovery-password-success__button {
  width: 100%;
  margin-top: 3em;

  @include fonts.font-medium-small;
}

@media screen and (max-width: common.$mobile-w) {
  .recovery-password-success {
    border: none;
    padding: 0;
    margin: 0 8px;
  }

  .recovery-password-success__button {
    width: 100%;

    @include fonts_mobile.mobile-font-medium-size16-line19;
  }
}

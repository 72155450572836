@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.status-with-timeline {
  display: inline-flex;
  align-items: center;
  gap: 0.625em;

  &__status {
    position: unset;
    height: fit-content;
  }

  &__timeline {
    @include fonts.font-medium-medium18;

    margin: 0;
    white-space: pre-line;
    color: var(--grey-dark);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .status-with-timeline {
    &__timeline {
      @include fonts_mobile.mobile-font-medium-size14-line17;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_colors.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.recurrent-unauthorized-user-payments {
  @include fonts.font-size(16px);

  padding: 4.5em 15em 0 10.75em;
}

.recurrent-unauthorized-user-payments__header {
  @include fonts.font-size(32px);

  line-height: 118%;
  font-weight: 500;
  color: var(--grey-dark);
  margin-top: 0;
  margin-bottom: 60px;
}

@media screen and (max-width: common.$mobile-w) {
  .recurrent-unauthorized-user-payments {
    padding: 12px 12px 32px;
  }
}

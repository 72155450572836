@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-idea {
  max-width: 63.1%;
  margin: 0 0 3.334vh;
  height: 80%;
}

.project-idea__footer {
  max-width: 63.1%;
}

.project-idea__title {
  margin-bottom: 1.8%;
}

.project-idea__idea-description {
  max-width: 78%;
  height: fit-content;
  padding: 0.5% 0 1.5%;
  margin: 0;
  color: var(--grey-dark);

  @include fonts.font-regular-size18-line24;

  & > a {
    color: var(--green-main);
    font-weight: 500;
  }
}

.project-idea__input-wrapper--line {
  height: 12%;
  margin-left: 2px;
}

.project-idea__input-wrapper--area {
  height: 50%;

  & > textarea {
    @include scroll.scroll;
  }
}

.project-idea__input {
  display: contents;
  height: 90%;
}

.project-idea__warning {
  display: inline-block;
  margin: 2px 0 6px;
  color: var(--warning-main);
  opacity: 1;
  transition: 0.5s;

  @include fonts.font-regular-small;

  &--hidden {
    margin: 2px 0 6px;
    color: var(--warning-main);
    opacity: 0;
    transition: 0.5s;
  }
}

.project-idea__error {
  display: inline-block;
  margin: 0.5% 0 8px;
  color: var(--error-main);
  opacity: 1;
  transition: 0.5s;

  @include fonts.font-regular-small;

  &--hidden {
    margin: 2px 0 8px;
    color: var(--error-main);
    opacity: 0;
    transition: 0.5s;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .project-idea {
    max-width: 100%;
    height: auto;
  }

  .project-idea__footer {
    max-width: unset;
  }

  .project-idea__title {
    margin-bottom: 8px;
  }

  .edit-layout__gate {
    .project-idea {
      padding: 0 8px;
    }

    .project-idea__title__header-text {
      display: none;
    }
  }

  .project-idea__input-wrapper--line {
    margin: 0;
    height: auto;
    transition: 0.5s;

    .text-input {
      padding: 4% 2.2%;

      @include fonts_mobile.mobile-font-regular-14px;
    }

    & > input::placeholder {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .project-idea__input-wrapper--area {
    height: 250px;

    &:focus,
    &:active {
      height: 250px;
    }

    .text-area {
      padding: 5% 2.2%;
      margin: 0;

      @include fonts_mobile.mobile-font-regular-14px;
    }

    & > textarea::placeholder {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .project-idea__idea-description {
    max-width: 95%;
    padding: 12px 0 10px;

    @include fonts_mobile.mobile-font-regular-14px;
  }

  .project-idea__error {
    @include fonts_mobile.mobile-font-regular-14px;

    margin: 4px 0 0;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-status {
  position: relative;
}

.project-status__text-row {
  display: flex;
  justify-content: space-between;
}

.project-status__text-row-date {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}

.project-status__text-row-edit-date {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5em;
}

.project-status__text {
  width: 92px;
  color: var(--grey-main);

  @include fonts.font-medium-small;

  &--passed {
    color: var(--green-main);
  }
}

.project-status__text-row--5 > .project-status__text {
  &:nth-child(3) {
    text-align: center;
  }

  &:nth-child(4) {
    text-align: right;
  }

  &:nth-child(5) {
    text-align: right;
  }
}

.project-status__text-row--4 > .project-status__text {
  &:nth-child(2) {
    text-align: center;
  }

  &:nth-child(3) {
    text-align: center;
  }

  &:nth-child(4) {
    text-align: right;
  }
}

.project-status-edit__text {
  color: var(--grey-dark);
}

.project-status-edit__text--disabled {
  color: var(--grey-light);
}

.project-status__bar-row {
  display: flex;
  margin: 13px 10px 0;
}

.project-status__bar-row-edit {
  min-height: 15px;
  display: flex;
  margin: 1% 6.67% 0;
  gap: 7.7%;
}

.project-status__bar-part,
.project-status__bar-part--passed {
  flex: 1;
  border-bottom: 2px dashed var(--grey-main);
  margin-top: 12px;
}

.project-status__bar-part--passed {
  border-bottom-color: var(--green-main);
}

.project-status__bar-part-edit,
.project-status__bar-part-edit--passed,
.project-status__bar-part-edit--disabled {
  flex: 1;
  margin-top: 1%;
  height: 0.7vh;
  transform: translateY(0.35vh);
  background-image: url("./img/status-dot.svg");
  background-repeat: space;
  background-size: contain;
  transition: 0.5s;
}

.project-status__bar-part-edit--passed {
  background-image: url("./img/status-dot-passed.svg");
}

.project-status__bar-part-edit--disabled {
  background-image: none;
  transition: 0.5s;
}

.project-status__point-row {
  display: flex;
  justify-content: space-between;
  margin-top: -10px;
}

.project-status__point-row-edit {
  display: flex;
  justify-content: space-between;
  padding-inline: 1.54%;
  margin-top: -1.84%;
}

.project-status__point,
.project-status__point--passed {
  width: 20px;
  height: 20px;
  stroke: var(--grey-main);
  stroke-width: 2;
  fill: var(--main-white);
}

.project-status__point-edit {
  width: 3em;
  height: 3em;
  transition: 0.5s;

  @include fonts.font-regular-small;
}

.project-status__point-edit--passed {
  width: 3em;
  height: 3em;
  stroke: var(--grey-main);
  stroke-width: 1;
  fill: var(--main-white);

  @include fonts.font-regular-small;
}

.project-status__point--passed {
  fill: var(--green-main);
  stroke: var(--green-main);
}

.project-status__point-edit--disabled {
  width: 3em;
  height: 3em;
  stroke-width: 1;

  @include fonts.font-size(16px);

  font-weight: 400;
  line-height: 140%;
  transition: 0.5s;

  & > circle {
    stroke-width: 1;
    stroke: var(--grey-light);
    fill: var(--grey-light);
  }

  & > path {
    fill: var(--main-white);
  }
}

.project-status__date {
  display: block;
  color: var(--grey-main);
  width: 6em;

  @include fonts.font-regular-very-small;
}

.project-status-edit__date {
  width: 6em;

  @include fonts.font-regular-small;
}

.project-status-edit__toggle {
  margin: 0 2.25%;
}

.project-status-hint-container {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.project-status-hint {
  padding: 1vh 1vw;
  max-width: 10vw;
  border: 1px solid var(--grey-light);
  border-radius: 10px;
  z-index: 10;
  top: calc(100% + 8px);
  background-color: var(--main-white);
  opacity: 0;

  @include fonts.font-regular-very-small;

  &--third {
    text-align: center;
  }

  &--fourth {
    text-align: right;
  }

  &--fifth {
    text-align: right;
  }
}

.project-status-hint-container--4 > .project-status-hint {
  &--second {
    text-align: center;
  }

  &--third {
    text-align: center;
  }

  &--fourth {
    text-align: right;
  }
}

.project-status-hint--visible {
  opacity: 1;
}

.project-status--archived {
  .project-status__text--passed {
    color: var(--grey-main);
  }

  .project-status__point--passed {
    fill: var(--grey-main);
    stroke: var(--grey-main);
  }

  .project-status__bar-part--passed {
    border-bottom-color: var(--grey-main);
  }
}

.edit-layout__gate .project-status-hint {
  &--first {
    left: 0;
  }

  &--second {
    left: 18.5%;
  }

  &--third {
    text-align: center;
    left: 32%;
  }

  &--fourth {
    text-align: center;
    right: 32%;
  }

  &--fifth {
    text-align: right;
    right: 19.5%;
  }

  &--sixth {
    text-align: right;
    right: 0;
  }
}

.edit-layout__gate .project-status-hint--non-active {
  top: calc(70% + 8px);
}

@media screen and (min-width: common.$two-k-w), (min-height: common.$two-k-h) {
  .edit-layout__gate .project-status-hint--non-active {
    top: calc(80% + 8px);
  }
}

@media (max-width: common.$mobile-w) {
  .edit-layout__gate {
    & .project-status__point-row-edit {
      padding-inline: 8.54%;
      width: 133%;
    }

    & .project-status__bar-row-edit {
      width: 119%;
      margin: 4% 16% 0;
      justify-content: space-between;
    }

    & .project-status__text-row {
      width: 150%;
    }

    & .project-status__text-row-edit-date {
      width: 142%;
      margin-left: 5%;
      margin-top: 8px;
    }
  }

  .project-status__bar-part-edit,
  .project-status__bar-part-edit--passed {
    height: 4px;
    transform: translateY(-2px);
  }

  .project-status__point-row-edit {
    margin-top: -4%;
  }

  .project-status__text-row-date {
    width: 160%;
  }

  .project-status__point-edit,
  .project-status__point-edit--passed {
    width: 24px;
    height: 24px;

    @include fonts.font-regular-small;
  }

  .project-status__text {
    @include fonts_mobile.mobile-font-bold-12px;
  }

  .project-status-edit__date {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }
}

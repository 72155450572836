@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.reporting-bar-chart {
  width: 54em;
  height: 14em;

  & .recharts-cartesian-axis-tick {
    & line {
      stroke-opacity: 0;
    }

    & tspan {
      fill: var(--grey-dark);
    }
  }

  & .recharts-legend-wrapper {
    top: 0 !important;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .reporting-bar-chart {
    width: 90vw;
    height: 40vh;

    & .recharts-surface {
      margin-top: 2vh;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.filter-card__header {
  color: var(--grey-main);
  margin-left: 22px;
  margin-bottom: 8px;

  @include fonts.font-size(12px);
}

.filter-card__filter-category {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 10px;
}

@media screen and (max-width: common.$mobile-w) {
  .filter-card__header {
    text-align: left;

    @include fonts_mobile.mobile-font-regular-size12-line20;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.recurrent-user-payments-table-row {
  @include fonts.font-size(16px);

  color: var(--grey-dark);

  & span,
  & a {
    white-space: pre-wrap;
    word-wrap: break-word;
    width: fit-content;
    height: fit-content;
  }

  &__link {
    @include fonts.font-size(16px);

    color: var(--grey-dark);
    font-weight: 500;
    transition: 0.5s;

    &:hover {
      @media (hover: hover) {
        color: var(--blue-light);
      }
    }

    &:active {
      color: var(--blue-main);
    }
  }

  align-items: center;

  & > button {
    border: 0;
    padding-left: 0;
    margin-left: 0;
  }
}

.recurrent-user-payments__header-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  border: 0;

  @include fonts.font-size(16px);

  font-weight: 400;
  transition: none;
  color: var(--blue-main);

  & svg > path {
    color: var(--blue-main);
  }

  &:hover:is(button) {
    cursor: pointer;
    color: var(--blue-light);

    & svg > path {
      color: var(--blue-light);
    }
  }

  &:active:is(button) {
    color: var(--blue-dark);

    & svg > path {
      color: var(--blue-dark);
    }
  }
}

.recurrent-user-payments-table-row__stop-support-button {
  @include fonts.font-regular-small;
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.create-project-button,
.create-project-button--mobile {
  white-space: nowrap;
}

.create-project-button {
  height: 3em;
  width: 12.5em;
}

@media screen and (max-width: common.$mobile-w) {
  .create-project-button {
    margin: 10px 2%;
    height: 3em;
    width: 96%;
    z-index: 500;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

@media (max-width: common.$mobile-w) {
  .edit-menu {
    box-sizing: border-box;
    height: 100%;
    background-color: var(--main-white);
    margin: 0;
    padding-top: 10px;
  }

  .edit-menu__step {
    @include fonts_mobile.mobile-font-regular-18px;

    display: flex;
    justify-content: space-between;
    color: var(--grey-dark);
    padding: 14px 16px;
    text-decoration: none;
    transition: 0.5s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      @media (hover: hover) {
        background-color: var(--secondary-main);
      }
    }

    &:active {
      background-color: var(--secondary-light);
    }
  }

  .edit-menu__step-icon {
    display: block;
    transform: rotate(-90deg);

    & path {
      fill: var(--grey-dark);
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

@media (max-width: common.$mobile-w) {
  .profile-menu__logout {
    margin-right: 2vw;
    display: flex;
    align-items: center;
    gap: 5px;
    width: fit-content;
    color: var(--error-main);
    text-decoration: none;
    padding: 14px 16px;

    & > svg {
      width: 1.2em;
      height: 1.2em;

      & > path {
        stroke: var(--error-main);
        transition: 0.5s;
      }
    }

    @include fonts_mobile.mobile-font-regular-18px;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.only-map-layout {
  position: relative;
  height: 100%;
}

.only-map-layout .map,
.only-map-layout .map .map-container {
  height: calc(var(--vh, 1vh) * 100);
}

.only-map-layout .leaflet-interactive {
  cursor: crosshair;
  pointer-events: none;
}

.only-map-layout__button {
  @include fonts.font-size(16px);

  font-weight: 400;
  line-height: 140%;
  z-index: 1000;
  position: absolute;
  display: flex;
  top: 1.75em;
  right: 1em;
  color: var(--grey-dark);
  background-color: var(--main-white);
  align-items: center;
  padding: 0.75em 1.25em;

  &:hover {
    @media (hover: hover) {
      background-color: var(--main-white);
      color: var(--green-cold);

      & > .only-map-layout__button-icon {
        stroke: var(--green-cold);
      }
    }
  }

  &:active {
    background-color: var(--main-white);
    color: var(--green-main);

    & > .only-map-layout__button-icon {
      stroke: var(--green-main);
    }
  }
}

.only-map-layout__button-icon {
  stroke: var(--grey-dark);
  margin-right: 9px;
  transition: 0.5s;
}

@media screen and (max-width: common.$mobile-w) {
  .only-map-layout .map-container div.leaflet-control-container {
    & > div.leaflet-top.leaflet-right .leaflet-control {
      height: calc(var(--vh, 1vh) * 100 - 67px);
    }

    & > div.leaflet-top.leaflet-right > .leaflet-control.leaflet-control-attribution {
      right: 0;
    }
  }
}

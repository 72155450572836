@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.selector {
  display: flex;
  flex-direction: column;
}

.selector__buttons {
  margin-top: 2.8vh;
  display: flex;
  flex-direction: row;
  gap: 9px;
}

.selector__save-button,
.selector__cancel-button {
  padding: 1% 2%;
}

.selector__dropdown {
  margin-left: 17px;

  & .selector__control {
    @include fonts.font-size(16px);

    line-height: calc(3em - 2px);
    padding: 0 1.25em;
    border: 1px solid var(--grey-light);
    border-radius: 5px;
    box-shadow: none;

    &:hover {
      @media (hover: hover) {
        border: 1px solid var(--secondary-dark);
      }
    }

    &:focus {
      outline: none;
    }

    & .selector__indicators {
      & > span {
        display: none;
      }
    }

    & .selector__dropdown-indicator {
      padding: 0;
      transition: 0.5s;
      color: var(--grey-dark);

      & > svg {
        width: 1.5em;
        height: 1.5em;
      }
    }

    &.selector__control--menu-is-open {
      border-radius: 5px 5px 0 0;
      border: 1px solid var(--secondary-dark);
      border-bottom: 1px solid var(--main-white);

      & .selector__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  & .selector__value-container,
  & .selector__single-value,
  & .selector__input-container {
    padding: 0;
    margin: 0;
  }

  & .selector__menu {
    margin: 0;
    border-left: 1px solid var(--secondary-dark);
    border-right: 1px solid var(--secondary-dark);
    border-bottom: 1px solid var(--secondary-dark);
    box-shadow: none;
    border-radius: 0 0 5px 5px;

    & .selector__menu-list {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  & .selector__option {
    @include fonts.font-regular-small;

    padding: 0.75em 1.25em;
    color: var(--grey-dark);

    &.selector__option--is-selected {
      background: none;

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }

    &:hover {
      @media (hover: hover) {
        background: var(--secondary-main);

        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }

    &.selector__option--is-focused {
      background: var(--secondary-main);

      &:last-child {
        border-radius: 0 0 5px 5px;
      }
    }
  }
}

.team-create .selector__dropdown {
  margin: 0;
}

.sorting-selector {
  display: flex;
  flex-direction: column;
}

.sorting-selector__buttons {
  margin-top: 2.8vh;
  display: flex;
  flex-direction: row;
  gap: 9px;
}

.sorting-selector__save-button,
.sorting-selector__cancel-button {
  padding: 1% 2%;
}

.sorting-selector__dropdown {
  margin-left: 17px;

  & .sorting-selector__control {
    @include fonts.font-regular-small;

    padding: 0.75em 0;
    width: 10vw;
    box-shadow: none;
    border-width: 0;

    &:focus {
      outline: none;
    }

    & .sorting-selector__indicators {
      & > span {
        display: none;
      }
    }

    & .sorting-selector__value-container {
      padding-left: 3%;

      & .sorting-selector__single-value {
        color: var(--blue-main);
      }
    }

    & .sorting-selector__dropdown-indicator {
      padding: 0;
      transition: 0.5s;
      color: var(--blue-main);

      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    &.sorting-selector__control--menu-is-open .sorting-selector__dropdown-indicator {
      transform: rotate(180deg);
    }
  }

  & .sorting-selector__menu {
    margin: 0;
    border: 1px solid var(--secondary-dark);
    box-shadow: none;
    border-radius: 5px;
    width: 15vw;
    min-width: 190px;
    left: -6em;

    & .sorting-selector__menu-list {
      padding-bottom: 0;
      padding-top: 8px;

      & .sorting-selector__group {
        padding-bottom: 0;
      }
    }
  }

  & .sorting-selector__option {
    @include fonts.font-regular-small;

    padding: 0.75em 1.25em;
    color: var(--grey-dark);

    &.sorting-selector__option--is-selected {
      background: none;
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 16px;
        height: 16px;
        background-image: url("./img/checkmark.svg");
        background-position: center;
        background-repeat: no-repeat;
        vertical-align: middle;
        left: 10px;
        bottom: 16px;
      }
    }

    &:hover {
      @media (hover: hover) {
        background: var(--secondary-main);
      }
    }

    &.sorting-selector__option--is-focused {
      background: var(--secondary-main);
    }
  }

  & .sorting-selector__group {
    .sorting-selector__option,
    .sorting-selector__group-heading {
      padding-left: 35px;
    }

    .sorting-selector__group-heading {
      text-transform: none;

      @include fonts.font-regular-small;
    }

    &:last-child .sorting-selector__option {
      &:hover {
        @media (hover: hover) {
          &:last-child {
            border-radius: 0 0 5px 5px;
          }
        }
      }

      &.sorting-selector__option--is-focused,
      &.sorting-selector__option--is-selected {
        &:last-child {
          border-radius: 0 0 5px 5px;
        }
      }
    }
  }
}

.selector__dropdown-error .selector__control {
  border: 1px solid var(--error-main);
}

.add-person-modal__select-type {
  margin-left: 0;
  width: 50%;
}

@media screen and (max-width: common.$mobile-w) {
  .selector__dropdown > .selector__control {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .selector__dropdown .selector__option {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-photo-mock {
  padding: 5em;
  border: 2px solid var(--secondary-main);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;

  @include fonts.font-size(20px);
}

.project-photo-mock__image {
  width: 11em;
  height: 11em;
}

.project-photo-mock__label {
  margin: 0;
  font-weight: 500;
  line-height: 1.45em;
  color: var(--blue-light);
}

@media screen and (max-width: common.$mobile-w) {
  .project-photo-mock {
    padding: 4.1vh 0 2.1vh;
    gap: 1em;
  }

  .project-photo-mock__image {
    width: 102px;
    height: 102px;
  }

  .project-photo-mock__label {
    max-width: 80%;
    line-height: 20px;
    font-size: 14px;
    text-align: center;
  }
}

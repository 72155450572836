.modal-window {
  width: 100vw;
  height: 100vh;
  background-color: rgb(200 200 200 / 50%);
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000000;
}

.modal-window__background {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: -1;
}

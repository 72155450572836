@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.email-confirmed {
  background: var(--main-white);
  padding: 6.86vh 3.15vw 10.1vh;
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  z-index: 1;
}

.email-confirmed__header {
  display: block;
  text-align: center;
  margin: 0;
  color: var(--grey-dark);
  width: 21.56vw;

  @include fonts.font-medium-big;
}

.email-confirmation__invitation {
  margin: 24px auto 0;
  width: 19vw;
  text-align: center;
  color: var(--grey-dark);

  @include fonts.font-regular-small;
}

.email-confirmation__image {
  display: block;
  width: 4.07vw;
  height: 7.23vh;
  margin: 1.625em auto 0;
}

.email-confirmed__button {
  display: block;
  margin: 28px auto 0;
  width: 11vw;

  @include fonts.font-regular-small;
}

@media screen and (max-width: common.$mobile-w) {
  .email-confirmed {
    border: none;
    padding: 0 8px;
    margin-top: 42px;
  }

  .email-confirmed__header {
    width: 60vw;
    margin: 0 auto;
  }

  .email-confirmation__invitation {
    width: 80%;
    margin-top: 18px;

    @include fonts_mobile.mobile-font-regular-16px-line24;
  }

  .email-confirmation__image {
    margin-top: 42px;
    width: 38vw;
    height: auto;
  }

  .email-confirmed__button {
    position: absolute;
    left: 8px;
    bottom: 24px;
    margin: 0;
    padding: 3.4% 0;
    width: calc(100% - 16px);

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

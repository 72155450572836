@use "@platform-for-public-places/components-library/styles/_common.scss";

.status-container__title {
  color: var(--grey-dark);
  margin-bottom: 3.59%;
}

.status-container__bar > .project-status__text-row > .project-status__text {
  text-align: center;
}

@media screen and (max-width: common.$mobile-w) {
  .edit-layout__gate .status-editor {
    padding: 0 8px;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.diary-entry-creation {
  height: 100%;
  padding: 0 8px;
  display: flex;
  flex-direction: column;
}

.diary-entry-creation__scrollable-content {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 0 10px;
  height: calc(100% - 46px);
  overflow: auto;
  gap: 16px;

  @include scroll.scroll-none;
}

.diary-entry-creation__pick-date-button {
  width: 100%;
  height: 46px;
  padding: 11px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
  -webkit-tap-highlight-color: transparent;

  @include fonts_mobile.mobile-font-medium-size14-line17;

  &:hover {
    @media (hover: hover) {
      background-color: var(--secondary-light);
    }
  }

  &:active {
    background-color: var(--secondary-light);
  }

  svg path {
    stroke: var(--blue-main);
  }
}

.diary-entry-creation__file-input .file-input__trigger {
  width: 100%;
  height: 46px;
  padding: 11px 0;
}

.diary-entry-creation__date-picker {
  width: calc(100% - 16px);
  height: 300px;
  margin-top: -10px;

  .rdp-month {
    width: 100%;
  }

  .date-picker-caption {
    box-sizing: border-box;
    justify-content: center;
    gap: 21px;
    width: 100%;
  }

  .date-picker-caption__button--prev,
  .date-picker-caption__button--next {
    box-sizing: content-box;
  }

  .rdp-table {
    width: 100%;
    max-width: 100%;
    margin: 0;
    transform: none;
  }

  .rdp-button:not([aria-disabled="true"]) {
    margin: 0 auto;

    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .rdp-head_cell {
    @include fonts_mobile.mobile-font-bold-12px;
  }
}

.diary-entry-creation__title-wrapper,
.diary-entry-creation__text-wrapper {
  display: flex;
  flex-direction: column;
}

.diary-entry-creation__title,
.diary-entry-creation__text {
  @include fonts_mobile.mobile-font-regular-14px;

  &::placeholder {
    @include fonts_mobile.mobile-font-regular-14px;
  }
}

.diary-entry-creation__error {
  display: block;
  height: 0;
  margin: 0;
  opacity: 0;

  @include fonts_mobile.mobile-font-regular-size12-line14;
}

.diary-entry-creation__error--active {
  display: block;
  height: 16px;
  margin: 3px 0 0 7px;
  opacity: 1;
  transition: 0.5s;
  color: var(--error-main);

  @include fonts_mobile.mobile-font-regular-size12-line14;
}

.diary-entry-creation__block-file {
  display: flex;
}

.diary-entry-creation__preview-file {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: var(--blue-main);

  @include fonts_mobile.mobile-font-regular-14px;

  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 38vw;
  }
}

.diary-entry-creation__buttons-block {
  box-sizing: border-box;
  display: flex;
  gap: 9px;
  height: 68px;
  padding: 14px 0 8px;
}

.diary-entry-creation__control-button {
  flex-grow: 1;

  @include fonts_mobile.mobile-font-regular-size16-line22;
}

.diary-entry-creation__icon-cross {
  stroke: var(--secondary-dark);
}

@media screen and (max-width: common.$hd-w) {
  .diary-entry-creation__text {
    height: 54vh;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.search-pane-control {
  @include fonts.font-size(16px);

  height: 3em;
  width: 3em;
  border-radius: 0.6em;
  background: var(--main-white);
  cursor: pointer;

  &:hover .search-pane-control__icon {
    @media (hover: hover) {
      fill: var(--blue-main);
    }
  }
}

.search-pane-control__icon {
  fill: var(--grey-dark);
  width: 1.5em;
  height: 1.5em;
  transition: 0.5s;

  &--chevron {
    transform: rotate(90deg);
  }
}

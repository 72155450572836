@use "@platform-for-public-places/components-library/styles/_common.scss";

@media screen and (max-width: common.$mobile-w) {
  .base-layout {
    height: calc(common.get-mobile-vh(100));

    &--fullscreen {
      height: common.get-mobile-vh(100);
    }
  }
}

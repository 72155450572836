@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.creation {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  height: 100%;
}

.creation-grid {
  &-1-elements {
    max-width: 50%;
  }

  &-2-elements {
    max-width: 50%;
  }

  &-3-elements {
    max-width: 60%;
  }

  &-4-elements {
    max-width: 80%;
  }

  &-5-elements {
    max-width: 90%;
  }
}

.creation__header {
  margin-right: auto;
  margin-bottom: 2.25em;
}

.creation__header__subheader-text {
  margin-top: 6%;
  color: var(--grey-dark);
}

.creation__types {
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 5em;
}

.creation__types-grid {
  &-1-elements {
    grid-template-columns: repeat(1, auto);
  }

  &-2-elements {
    grid-template-columns: repeat(2, auto);
  }

  &-3-elements {
    grid-template-columns: repeat(3, auto);
  }

  &-4-elements {
    grid-template-columns: repeat(4, auto);
  }

  &-5-elements {
    grid-template-columns: repeat(5, auto);
  }
}

.creation__continue-button {
  margin: 0 auto;
  padding: 0.75em 2.8em;
  width: fit-content;
}

.project-type {
  display: flex;
  align-items: flex-start;
  border: 1px solid var(--secondary-dark);
  border-radius: 16px;
  padding: 2.5em 3.5em;

  &--small {
    padding: 1em;
  }

  &--active {
    border: 1px solid var(--green-main);
    transition: 0.5s;
  }
}

.project-type__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 15em;

  &--small {
    flex-direction: row;
    max-width: 22em;
    max-height: 8em;
    text-align: left;
    gap: 1em;
  }
}

.project-type__icon {
  stroke: var(--green-main);
  width: 9em;
  height: 9em;
  margin-bottom: 1.875em;

  &--small {
    margin-bottom: 0;
    width: 13em;
    height: 13em;
  }

  & > path {
    stroke: var(--green-main);
    stroke-width: 1.5px;
  }
}

.project-type__title {
  @include fonts.font-medium-small;

  color: var(--grey-dark);
  margin: 0 0 0.75em;
}

.project-type__subtitle {
  @include fonts.font-regular-small;

  color: var(--grey-dark);
  margin: 0;
}

@media screen and (max-width: common.$mobile-w) {
  .creation {
    max-width: fit-content;
    margin: 0;
  }

  .creation__header {
    margin-bottom: 3.5%;
  }

  .creation__header__subheader-text {
    margin: 2% 10% 0;
    text-align: center;
  }

  .creation__types {
    display: flex;
    flex-direction: column;
  }

  .project-type {
    padding: 4.5% 3.5%;
  }

  .project-type__content {
    flex-direction: row;
    gap: 10px;
    max-width: 100%;
  }

  .project-type__title {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    margin-bottom: 1%;
  }

  .project-type__subtitle {
    @include fonts_mobile.mobile-font-regular-14px;
  }

  .project-type__description {
    text-align: initial;
  }

  .project-type__icon {
    width: 5em;
    height: 5em;
    margin: 0;
    min-width: 64px;
    min-height: 64px;
  }

  .creation__continue-button {
    position: absolute;
    bottom: 0;
    margin-bottom: 3%;
    width: 96%;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .creation__types-scroll {
    @include scroll.scroll-none;

    overflow: auto;
  }
}

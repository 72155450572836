@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.reporting-mobile__header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4%;
}

.reporting-mobile__total-count {
  @include fonts_mobile.mobile-font-regular-16px-line24;

  display: flex;
  flex-direction: column;
  text-align: end;
  margin: 0;

  & > span {
    @include fonts_mobile.mobile-font-medium-size20-line28;
  }
}

.reporting-mobile__annual-report {
  padding: 0 5%;
}

.reporting-mobile__annual_report_docs {
  margin-top: 5%;
}

.reporting-mobile__paginate {
  @include fonts_mobile.mobile-font-bold-20px;

  color: var(--grey-dark);

  & [class*="-control"] {
    border: none;
    box-shadow: none;
    width: fit-content;
    min-height: fit-content;
  }

  & [class*="-ValueContainer"] {
    padding: 0;
  }

  & [class*="-IndicatorsContainer"] {
    position: relative;
  }

  & [class*="-loadingIndicator"] {
    position: absolute;
    left: 100%;
  }

  & [class*="-indicatorSeparator"] {
    display: none;
  }

  & [class*="-indicatorContainer"] {
    padding: 0 8px;
  }

  & [class*="-Svg"] {
    width: 1em;
    height: 1em;
    transition: 0.5s;
    color: var(--grey-dark);
  }

  & [class*="-menu"] {
    top: 50%;
    left: 15%;
    box-shadow: none;
    border: solid 1px var(--secondary-dark);
    border-radius: 5px;
    width: fit-content;

    & [class*="-option"] {
      @include fonts_mobile.mobile-font-regular-14px-line20;

      text-align: center;
      padding: 8px 32px;
    }

    // active
    & [class*="-tr4s17-option"] {
      color: var(--grey-dark);
      background: var(--secondary-light);
    }
  }

  &_selected {
    & [class*="-Svg"] {
      transform: rotate(180deg);
    }
  }
}

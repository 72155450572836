@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.date-picker-button__date {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 9px 20px 10px;
  transition: unset;
  font-weight: 500;
  line-height: 140%; // 22px

  @include fonts.font-size(16px);

  &--editing {
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--blue-main);
  }

  & svg path {
    stroke: var(--blue-main);
  }

  &:disabled {
    border: none;
    padding: 0;
    background-color: unset;
    color: var(--grey-main);

    &:hover {
      @media (hover: hover) {
        background-color: unset;
      }
    }

    &:active {
      background-color: unset;
    }

    & svg {
      display: none;
    }
  }
}

.date-picker-button__date-picker {
  margin-top: 0.5em;
}

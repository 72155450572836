@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.draw-control {
  @include fonts.font-size(10px);

  width: 4.8em;
  height: 4.8em;
  background: var(--main-white);
  border-radius: 1em;
  padding: 1.2em;
  margin-bottom: 6em;
  cursor: pointer;
}

.draw-control__icon {
  fill: var(--grey-dark);
  transition: 0.5s;
  font-size: inherit;
  width: 2.4em;
  height: 2.4em;

  &--active {
    fill: var(--green-main);
    font-size: inherit;
    width: 2.4em;
    height: 2.4em;
  }
}

.draw-control:hover .draw-control__icon {
  @media (hover: hover) {
    fill: var(--green-main);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .draw-control {
    width: 46px;
    height: 46px;
    border-radius: 10px;
    padding: 11px;
    margin-bottom: 20vh;
  }

  .draw-control__icon {
    width: 24px;
    height: 24px;

    &--active {
      width: 24px;
      height: 24px;
    }
  }
}

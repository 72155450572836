@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.autopayments-management-form-container__title {
  text-align: center;
  margin: 0 0 0.5em;
}

.autopayments-management-form-container__description {
  text-align: center;
  margin: 0 auto 2.5em;
}

.autopayments-management-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.autopayments-management-form__email {
  margin-bottom: 0.5em;
  padding: 3.6% 2.8% 3.6% 5.5%;
  max-width: 6vw;
}

.autopayments-management-form__button {
  width: 50%;
}

.autopayments-management-form__sign-in-button {
  cursor: pointer;

  @include fonts.font-size(12px);
}

.autopayments-management-form__inctruction {
  color: var(--grey-dark);
  margin: 0 0 2.4em;

  @include fonts.font-size(12px);
}

@media screen and (max-width: common.$mobile-w) {
  .autopayments-management-form-container {
    border: none;
    padding: 0;
    margin: 0 8px;
  }

  .autopayments-management-form-container__description {
    @include fonts_mobile.mobile-font-regular-16px-line24;
  }

  .autopayments-management-form__email {
    max-width: 100%;
  }

  .autopayments-management-form__sign-in-button {
    @include fonts_mobile.mobile-font-regular-size12-line20;
  }

  .autopayments-management-form__inctruction {
    @include fonts_mobile.mobile-font-regular-size12-line20;
  }

  .autopayments-management-form__button {
    width: 100%;
  }
}

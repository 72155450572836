@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

// todo delete all unused style
.layer-control {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-end;
  margin-top: 1.7vh;
  font-family: Rubik, sans-serif;
  gap: 1em;
}

.layer-control__button {
  @include fonts.font-size(10px);

  width: 4.8em;
  height: 4.8em;
  background: var(--main-white);
  border-radius: 1em;
  padding: 1.2em;
  cursor: pointer;
}

.layer-control__icon {
  transition: 0.5s;
  font-size: inherit;
  width: 2.4em;
  height: 2.4em;

  & * {
    fill: var(--main-white);
    stroke: var(--grey-dark);
  }

  &--active {
    &-map * {
      fill: var(--light-green);
      stroke: var(--green-dark);
    }

    &-polygon * {
      fill: var(--error-light);
      stroke: var(--error-dark);
    }

    font-size: inherit;
    width: 2.4em;
    height: 2.4em;
  }
}

.layer-control:hover .layer-control__icon {
  @media (hover: hover) {
    fill: var(--blue-main);
  }
}

.layer-control__card {
  @include fonts.font-size(10px);

  position: relative;
  padding: 2.2em 2.2em 3.6em;
  background: var(--main-white);
  border-radius: 1em;
  cursor: default;
}

.layer-control__card--invisible {
  display: none;
}

.layer-control__header {
  color: var(--grey-dark);
  text-align: left;
  margin: 0;

  @include fonts.font-regular-small;
}

.layer-control__map-types {
  display: flex;
  flex-direction: row;
  gap: 12px;
  margin-top: 10px;
}

.layer-control__map-type {
  @include fonts.font-size(14px);

  line-height: 120%;
  font-weight: 400;
  min-width: 8.3em;
  padding: 0.5em 0;
  border-radius: 5px;
  transition: 0.5s;
}

.layer-control__layers-list {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.layer-control__line {
  border-top: none;
  margin: 23px 0 20px;
  color: var(--secondary-dark);
  background-color: var(--secondary-dark);
}

.layer-control__do-not-response-card--invisible {
  display: none;
}

@media screen and (max-width: common.$mobile-w) {
  .layer-control__header,
  .layer-control__map-type,
  .layer-control__map-type--active {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .layer-control {
    gap: 8px;
  }

  .layer-control__button {
    width: 46px;
    height: 46px;
    border-radius: 10px;
    padding: 11px;
  }

  .layer-control__icon {
    width: 24px;
    height: 24px;

    &--active {
      width: 24px;
      height: 24px;
    }
  }

  .layer-control__card {
    padding: 16px 0 28px;
  }

  .layer-control__header,
  .layer-control__map-types,
  .layer-control__layers-list,
  .layer-control__line {
    margin-left: 18px;
    margin-right: 18px;
  }

  .layer-control__legend {
    margin-left: 10px;
    margin-right: 10px;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.file-input {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.file-input__main {
  display: flex;
}

.file-input__trigger {
  @include fonts.font-size(16px);

  z-index: 1;
  flex-shrink: 0;
  font-weight: 400;
  line-height: 140%;
  padding: 0.56em 1.8em 0.63em;
  white-space: nowrap;
}

.file-input__file-input {
  display: none;
}

.file-input__text {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  border: 1px solid var(--grey-light);
  border-radius: 10px;
  margin-left: -15px;
  width: 100%;
}

.file-input__submit-button {
  display: flex;
  flex-shrink: 0;
  padding: 5px 20px;
  height: 44px;
  opacity: 1;
  transition: 0.5s;
  align-self: center;

  & svg path {
    stroke: var(--blue-main);
  }

  &:disabled {
    opacity: 0;
  }
}

.file-input__text-field {
  padding-left: 35px;
  border-radius: 10px;
  margin-right: 2px;
  border: none;
  height: 100%;
}

%file-input-error {
  @include fonts.font-regular-small;

  margin: 0 10px;
  color: var(--error-main);
  max-height: 0;
  opacity: 0;
  transition: 0.5s;
}

.file-input__error {
  @extend %file-input-error;

  &--active {
    @extend %file-input-error;

    max-height: 34px;
    opacity: 1;
  }

  &--hidden {
    width: 0;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .file-input__main {
    flex-direction: column;
  }

  .file-input__trigger {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    width: 100%;
    display: flex;
    background: var(--main-white);
    align-items: center;
    justify-content: center;
    font-weight: 500;

    & > svg {
      width: 1.9em;
      height: 1.9em;
    }
  }

  .file-input__label {
    margin: 8px 0;
    display: block;
    text-align: center;

    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .file-input__text {
    margin-left: 0;
  }

  .file-input__submit-button {
    padding: 6px;
  }

  %file-input-error {
    margin: 0;
  }

  .file-input__text-field--mobile {
    width: 100%;
    height: 45px;
    border: none;

    @include fonts_mobile.mobile-font-regular-14px-line20;

    &::placeholder {
      @include fonts_mobile.mobile-font-regular-14px-line20;
    }
  }

  .file-input__error--active {
    margin-bottom: 8px;
  }

  .file-input__text--error {
    border-color: var(--error-main);
  }
}

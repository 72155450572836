@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.edit-layout-footer__buttons {
  display: flex;
}

.edit-layout-footer__submit {
  margin: 2.6% 0 3.65%;
  display: flex;
  padding: 16px 32px;
  border: 1px solid var(--secondary-main);
  border-radius: 10px;
  max-width: calc(63.1% - 64px);
  justify-content: space-between;

  @media (min-height: common.$full-hd-h) {
    margin: 1% 0 3.65%;
  }
}

.edit-layout-footer__button {
  text-decoration: none;
  padding: 0.8em 2.35em;
}

.edit-layout-footer__text {
  @include fonts.font-regular-small;
}

@media (max-width: common.$mobile-w) {
  .edit-layout-footer__submit {
    border: none;
    width: auto;
    padding: 0;
    justify-content: center;
    margin: 0;
  }

  .edit-layout-footer__text {
    display: none;
  }

  .edit-layout-footer__buttons {
    border: none;
    width: 100%;
    bottom: 6.5%;
    left: 0;
    z-index: 10000;
    padding: 0;
    position: absolute;
    justify-content: center;
    background: var(--main-white);

    span {
      @include fonts_mobile.mobile-font-regular-size16-line22;
    }
  }

  .edit-layout-footer__button {
    flex: 1;
    margin: 8px;
    height: 48px;
    padding: 0;
  }
}

.indicator {
  background: var(--green-light);
  transition: 0.5s;
  width: 0;
  aspect-ratio: 1 / 1;
  border-radius: 50%;

  &--show {
    width: 0.6em;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.specialist-request-container {
  padding: 2.5vh 1.4vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  background-color: var(--secondary-light);
}

.specialist-request_active {
  .specialist-request__date-time {
    color: var(--blue-main);
  }

  .specialist-request__status,
  .specialist-request__button {
    color: var(--blue-dark);
  }

  .specialist-request__button {
    & > svg {
      fill: var(--blue-main);
    }
  }
}

.specialist-request_rejected {
  .specialist-request__status,
  .specialist-request__button,
  .specialist-request__date-time {
    color: var(--error-main);
  }

  .specialist-request__button {
    & > svg {
      fill: var(--error-main);
    }
  }
}

.specialist-request_executed {
  .specialist-request__status,
  .specialist-request__button,
  .specialist-request__date-time {
    color: var(--green-light);
  }

  .specialist-request__button {
    & > svg {
      fill: var(--green-light);
    }
  }
}

.specialist-request__text {
  display: flex;
  gap: 2vw;
}

.specialist-request__status {
  margin: 0;
}

.specialist-request__status_rejected {
  margin: 0;
  display: inline-block;
  font-style: italic;
}

.specialist-request__status,
.specialist-request__date-time {
  @include fonts.font-regular-small;
}

.specialist-request__button {
  padding: 0;
  display: flex;
  align-items: center;

  & > svg {
    fill: var(--blue-main);
  }
}

.specialist-request__button-icon_opened {
  transform: rotate(180deg);
}

@media screen and (max-width: common.$mobile-w) {
  .specialist-request-container {
    padding: 1.5vh 3vw;
  }

  .specialist-request__text {
    width: 80%;
    flex-direction: column;
    gap: 0.5vh;
  }
}

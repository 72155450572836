@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.container {
  position: relative;
  display: flex;
  align-items: center;
}

.tooltip {
  width: max-content;
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  background-color: var(--main-white);
  padding: 1em 0.8em;
  z-index: 10;
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.stage-header__header-text {
  @include fonts.font-medium-big;

  color: var(--grey-dark);
  margin: 0;
}

.stage-header__subheader-text {
  color: var(--grey);
  margin: 3.37% 0 0;

  @include fonts.font-regular-size18-line24;
}

@media (max-width: common.$mobile-w) {
  .stage-header__header-text {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    text-align: center;
  }

  .stage-header__subheader-text {
    @include fonts_mobile.mobile-font-regular-14px;
  }
}

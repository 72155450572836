@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.profile-participations-table-row {
  @include fonts.font-size(16px);

  width: 58vw;
  min-height: 6em;
  color: var(--grey-dark);
  background-color: var(--secondary-light);
  border-radius: 10px;

  & span,
  & a {
    white-space: pre-wrap;
    word-wrap: break-word;
    height: fit-content;
  }

  & p {
    margin: 0;
  }
}

.profile-participations-table-row__project-name_active,
.profile-participations-table-row__project-name_rejected,
.profile-participations-table-row__project-name_executed,
.profile-participations-table-row__project-refuce-action {
  padding: 0;
  border: 0;
  text-align: start;
  text-decoration: underline;

  & > p {
    overflow-wrap: break-word;
  }
}

.profile-participations-table-row__project-name_active > p,
.profile-participations-table-row__project-name_rejected > p,
.profile-participations-table-row__project-name_executed > p {
  height: 100%;
  padding: 1.9em 3.125em 1.9em 1.25em;
}

.profile-participations-table-row__status_active,
.profile-participations-table-row__status_rejected,
.profile-participations-table-row__status_executed {
  padding-top: 30px;
  font-weight: 500;
}

.profile-participations-table-row__request-body {
  padding-top: 30px;
  padding-right: 5em;
}

.profile-participations-table-row__request-body_bold {
  font-weight: 500;
}

.profile-participations-table-row__project-refuce-action {
  @include fonts.font-size(14px);

  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  text-align: start;
  text-decoration: none;
  color: var(--blue-dark);
}

.profile-participations-table-row__date_active,
.profile-participations-table-row__status_active,
.profile-participations-table-row__project-name_active {
  color: var(--blue-dark);
}

.profile-participations-table-row__date_rejected,
.profile-participations-table-row__status_rejected,
.profile-participations-table-row__project-name_rejected {
  color: var(--error-main);
}

.profile-participations-table-row__date_executed,
.profile-participations-table-row__status_executed,
.profile-participations-table-row__project-name_executed {
  color: var(--green-light);
}

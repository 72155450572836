@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.follow-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  gap: 0.5vw;
  padding: 1vh 1vw;

  @include fonts.font-size(16px);

  &--clicked-follow {
    &::before {
      content: "";
      position: absolute;
      top: -55%;
      left: -55%;
      width: 220%;
      height: 220%;
      background-image:
        radial-gradient(circle at 50% 50%, var(--ppp-blue) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-blue) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-orange) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-orange) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-purple) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-purple) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-blue) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-blue) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-purple) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-purple) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-orange) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-orange) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-purple) 70%, transparent 71%),
        radial-gradient(circle at 50% 50%, var(--ppp-purple) 70%, transparent 71%);
      background-position:
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%,
        27.08% 27.08%;
      background-size:
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0,
        0 0;
      background-repeat: no-repeat;
      animation: confeti-animation 1s ease both;
    }

    .follow-button__icon {
      animation: 1s bell-follow-animation;
    }
  }

  &--clicked-unfollow {
    .follow-button__icon {
      animation: 1s bell-unfollow-animation;
    }
  }

  &:disabled .follow-button__icon {
    path {
      fill: var(--grey-light);
      stroke: var(--grey-light);
    }
  }

  &:disabled .follow-button__icon--followed path {
    fill: var(--grey-light);
  }
}

.follow-button__icon {
  width: 1.5em;
  height: 1.5em;
  fill: var(--blue-main);

  path:first-child {
    fill: transparent;
    transition: 1s;
  }

  &--followed path:first-child {
    fill: var(--blue-main);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .follow-button {
    @include fonts_mobile.mobile-font-medium-size14-line17;

    color: var(--blue-dark);

    path:first-child {
      stroke-width: 2.5px;
      stroke: var(--blue-dark);
    }

    & > svg {
      height: 1.2em;
      width: 1.2em;
    }

    &--clicked-follow {
      &::before {
        display: none;
      }

      .follow-button__icon {
        animation: 1s heart-animation;
      }
    }

    &:disabled .follow-button__icon path:last-child {
      fill: transparent;
    }
  }

  .follow-button--clicked-unfollow .follow-button__icon {
    animation: none;
  }

  .follow-button__icon {
    fill: transparent;
    stroke-width: 2px;

    path:first-child {
      fill: transparent;
      transition: 1s;
    }

    &--followed path:first-child {
      fill: var(--blue-dark);
    }
  }
}

@keyframes bell-follow-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes confeti-animation {
  0% {
    background-position:
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%;
    background-size:
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0;
  }

  20% {
    background-position:
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%,
      27.08% 27.08%;
    background-size:
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0;
  }

  35% {
    background-position:
      52% 16.25%,
      46.25% 16.25%,
      75.83% 30%,
      72.5% 26.67%,
      80.42% 58.75%,
      81.25% 53.33%,
      60.42% 80%,
      65.42% 77.08%,
      31.67% 77.5%,
      37.08% 79.17%,
      16.25% 53.33%,
      17.92% 58.75%,
      25% 25.83%,
      21.67% 30.83%;
    background-size:
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px,
      5px 5px;
  }

  50% {
    background-position:
      52% 10%,
      46.25% 16.25%,
      80% 27.5%,
      72.5% 26.67%,
      83.75% 60.42%,
      81.25% 53.33%,
      60.42% 83.75%,
      65.42% 77.08%,
      27.5% 80%,
      37.08% 79.17%,
      10% 52%,
      17.92% 58.75%,
      20.83% 20.83%,
      21.67% 30.83%;
    background-size:
      10px 10px,
      5px 5px,
      10px 10px,
      5px 5px,
      10px 10px,
      5px 5px,
      10px 10px,
      5px 5px,
      10px 10px,
      5px 5px,
      10px 10px,
      5px 5px,
      10px 10px,
      5px 5px;
  }

  100% {
    background-position:
      54.17% 12.08%,
      47.08% 17.08%,
      82.08% 29.58%,
      73.33% 27.5%,
      85.83% 62.5%,
      82.08% 54.17%,
      62.5% 85.83%,
      66.25% 77.92%,
      29.58% 82.08%,
      37.92% 80%,
      12.08% 54.17%,
      18.75% 59.58%,
      22.92% 22.92%,
      22.5% 31.67%;
    background-size:
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0,
      0 0;
  }
}

@keyframes bell-unfollow-animation {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(30deg);
  }

  50% {
    transform: rotate(-10deg);
  }

  75% {
    transform: rotate(15deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-docs__title {
  @include fonts.font-size(20px);

  font-weight: 500;
  line-height: 120%;
  margin: 0;
  margin-bottom: 4px;
  color: var(--grey-dark);
}

.project-docs__button {
  border: none;
  transition: 0.5s;
}

.project-docs__button--open {
  & > svg {
    transition: 0.5s;
    transform: rotate(180deg);
  }
}

.project-docs-list--empty {
  margin: 9% 0 0;
  color: var(--grey-main);

  @include fonts.font-size(16px);

  font-weight: 400;
  line-height: 120%;
}

@media screen and (max-width: common.$mobile-w) {
  .project-docs__title {
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    padding: 0;
    margin: 0 8px;
  }

  .project-docs-list--empty {
    margin: 10px 0 0 9px;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
  }

  .links-doc__icon {
    width: 24px;
    height: 24px;
  }

  .link-doc__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
  }
}

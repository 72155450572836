@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.mobile-sidebar {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.mobile-sidebar__profile-header {
  margin-bottom: 2.6rem;
}

.mobile-sidebar__links {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.mobile-sidebar__link-element {
  margin-bottom: 1.5rem;
}

.mobile-sidebar__link {
  @include fonts_mobile.mobile-font-regular-size16-line22;

  color: var(--grey-dark);
  text-decoration: none;
  display: flex;
  align-items: center;

  & > .mobile-sidebar__link-icon {
    margin-right: 8px;
    height: 24px;
    width: 24px;

    &--circled {
      stroke: var(--grey-dark);
    }

    &--filled {
      fill: var(--grey-dark);
    }
  }

  &.active {
    color: var(--green-main);

    & > .mobile-sidebar__link-icon {
      stroke: var(--green-main);
    }
  }
}

.mobile-sidebar__create-project-button {
  margin-top: auto;
}

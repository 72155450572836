@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.photos-uploader__overlay {
  @include fonts.font-regular-very-small;

  position: absolute;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  opacity: 0;
  transition: 0.5s;
  background-image:
    linear-gradient(
      180deg,
      rgb(38 38 38 / 0%) 0%,
      var(--grey-dark) 100%
    );

  & > svg {
    display: block;
    margin: 0 auto;
    width: 2.7em;
    height: 2.7em;

    & > path {
      fill: var(--main-white);
    }
  }

  & > button {
    opacity: 0;
    background: var(--grey-dark);
    cursor: pointer;
    border-radius: 3px;
    align-self: flex-end;
    margin: 3px 3px 0 0;
    transition: 0.5s;

    & > svg {
      fill: var(--main-white);
      height: 1.1em;
      width: 1.2em;

      @include fonts.font-regular-very-small;
    }
  }

  &:hover {
    @media (hover: hover) {
      opacity: 1;

      & > button {
        transition: 0.5s;
        opacity: 1;
      }
    }
  }

  & > span {
    display: block;
    color: var(--main-white);
    margin: 1px auto 0;
    text-align: center;
    cursor: default;
  }
}

.photos-uploader__overlay--selected {
  opacity: 1;
  cursor: default;
}

.photos-uploader__title {
  margin: 0;
  color: var(--grey-dark);

  @include fonts.font-medium-size20-line24;
}

.photos-uploader__description {
  @include fonts.font-size(18px);

  line-height: 140%;
  margin: 1.1em 0 0;
  max-width: 948px;
  color: var(--grey-dark);

  & > a {
    color: var(--green-main);
    font-weight: 500;
  }
}

.photos-uploader__input {
  @include fonts.font-size(10px);

  margin: 2em 0 0;
}

.photos-uploader__preview {
  @include fonts.font-size(10px);

  display: flex;
  flex-wrap: wrap;
  margin: 1.6em 0 0;
  gap: 1.6em;
}

.photos-uploader__preview-image {
  @include fonts.font-size(10px);

  position: relative;
  aspect-ratio: 1 / 1;
  width: 7.6em;

  & > img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
  }
}

.photos-uploader__cover-hint {
  @include fonts.font-size(18px);

  display: block;
  margin: 0.9em 0 0;
  line-height: 140%;
  color: var(--grey-dark);
}

.photos-uploader__annotation {
  display: block;
  margin: 0 10px;
  color: var(--grey-main);

  @include fonts.font-regular-small;
}

@media screen and (max-width: common.$mobile-w) {
  .photos-uploader__overlay {
    opacity: 1;
    background: transparent;

    & > button {
      opacity: 1;

      & > svg {
        width: 2em;
        height: 1.5em;
      }
    }

    & > svg {
      opacity: 0;
    }

    & > span {
      opacity: 0;
    }
  }

  .photos-uploader__overlay--selected {
    background-image:
      linear-gradient(
        180deg,
        rgb(38 38 38 / 0%) 0%,
        var(--grey-dark) 100%
      );

    & > svg {
      opacity: 1;
    }

    & > span {
      opacity: 1;
    }
  }

  .photos-uploader:last-of-type {
    padding-bottom: 21%;
  }

  .photos-uploader__title {
    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .photos-uploader__cover-hint {
    font-size: 14px;
    line-height: 135%;
  }

  .photos-uploader__description {
    font-size: 14px;
    line-height: 135%;
    margin: 10px 0 0;
  }

  .photos-uploader__input {
    margin: 10px 0 0;
  }

  .photos-uploader__preview {
    @include scroll.scroll-none;

    overflow-x: auto;
    flex-wrap: nowrap;
    margin: 2px 0 0;
  }

  .photos-uploader__preview-image {
    width: 15%;
    min-width: 56px;
  }

  .photos-uploader__annotation {
    @include fonts_mobile.mobile-font-regular-size12-line14;

    color: var(--grey-dark);
    display: flex;
    margin: 0;

    & > svg {
      flex: none;
      width: 1.24em;
      height: 1.24em;
      margin-right: 6px;
    }
  }
}

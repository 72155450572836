@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.filter-order__card {
  display: block;
  position: absolute;
  background: var(--main-white);
  border: 1px solid var(--secondary-dark);
  border-radius: 5px;
  padding: 14px 52px 5px 15px;
  right: 0;
  top: 3vh;
  z-index: 1000001;
}

.filter-order__card--invisible {
  display: none;
}

.filter-order__wrapper {
  position: relative;
}

.filter-order__button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-weight: 400;
  gap: 0.5em;
  align-items: center;
  white-space: nowrap;
  border: none;

  @include fonts.font-size(14px);

  svg {
    fill: var(--blue-main);
    stroke: var(--blue-main);
    width: 1.15em;
    height: 1.15em;
    transition: 0.5s;
  }

  &:hover svg {
    @media (hover: hover) {
      fill: var(--blue-light);
      stroke: var(--blue-light);
    }
  }

  &:active svg {
    fill: var(--blue-dark);
    stroke: var(--blue-dark);
  }

  &--close svg {
    transform: rotate(180deg);
  }
}

.filter-order__title {
  margin: 0;

  @include fonts.font-regular-small14;

  color: var(--grey-dark);
}

.filter-order__background {
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 1000000;
  position: fixed;
  display: none;
}

.filter-order__background--open {
  display: block;
}

@media screen and (max-width: common.$mobile-w) {
  .filter-order__card {
    padding: 20px 52px 12px 15px;
  }

  .filter-order__button,
  .filter-order__title {
    @include fonts_mobile.mobile-font-regular-14px;
  }
}

@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.edit-layout {
  display: flex;
  justify-content: center;
}

.edit-layout__gate {
  @include scroll.scroll-none;

  box-sizing: border-box;
  flex: 9 1;
  padding: 3.6% 4.2%;
  height: 100%;
  overflow: auto;
}

.edit-layout-loader {
  display: flex;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

@media (max-width: common.$mobile-w) {
  .edit-layout {
    height: calc(common.get-mobile-vh(100) - 56px);
    flex-direction: column;
  }

  .edit-layout__gate {
    margin: 0;
    padding: 0;

    & .team__members {
      flex-grow: 1;
      overflow: auto;
      padding-right: 0;
    }
  }

  .edit-layout-loader {
    height: calc(var(--vh) * 100);
  }
}

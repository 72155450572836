@use "@platform-for-public-places/components-library/styles/_common.scss";

.member-request-card {
  display: flex;
  background-color: var(--secondary-light);
  border-radius: 10px;
  padding: 10px 16px 16px;
  gap: 14px;

  &--outgoing {
    padding-right: 10px;
  }

  &--active {
    color: var(--blue-main);
    stroke: var(--blue-main);
  }

  &--rejected {
    color: var(--error-main);
    stroke: var(--error-main);
  }
}

.member-request-card__loader {
  width: 18px;
  height: 18px;
  padding: 6px;
  align-self: center;
  flex-shrink: 0;
  animation: spinning 2s linear infinite;

  & > path {
    stroke: var(--blue-dark);
  }
}

.member-request-card__content {
  display: flex;
  flex-direction: column;
  width: calc(100% - 38px);
  text-align: left;
  cursor: pointer;
}

.member-request-card__specialist {
  font-weight: 500;
  font-size: 14px;
  line-height: 229%;
}

.member-request-card__status {
  font-size: 14px;
  line-height: 135%;
}

.member-request-card__comment {
  font-size: 14px;
  line-height: 135%;
  margin: 0;
}

.member-request-card__comment-body {
  font-style: italic;
}

.member-request-card__specialist,
.member-request-card__status,
.member-request-card__comment {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.member-request-card__button {
  padding: 0;
  border: 0;
  height: 24px;
}

@keyframes spinning {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .member-request-card__specialist {
    line-height: 229%;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.diary-entry {
  position: relative;
  scroll-margin: 40px;
  margin-bottom: 2px;
  padding-bottom: 55px;
  border-left: 3px dashed var(--secondary-dark);

  &:hover {
    @media (hover: hover) {
      .diary-entry__edit {
        opacity: 1;
      }

      .diary-entry__popup {
        opacity: 1;
      }
    }
  }

  &:last-child {
    border-left: 3px dashed var(--main-white);
  }

  &--creatable {
    margin-top: 28px;
    margin-bottom: 56px;
    padding-bottom: 0;
    border-left: 0;

    &:last-child {
      border-left: 0;
    }

    & > header {
      flex-direction: column;
      gap: 6px;
      margin-left: 0;

      & > svg {
        display: none;
      }
    }

    & > article {
      margin-top: 30px;
      margin-left: 0;
    }

    & .diary-entry__pick-date-error {
      display: inline;
      margin: 0 10px;
      color: var(--error-main);
      height: 0;
      opacity: 0;
      transition: 0.5s;
      font-weight: 400;
      line-height: 140%; // 22px

      @include fonts.font-size(16px);

      &--active {
        height: 22px;
        opacity: 1;
      }
    }

    & .diary-entry__action {
      padding: 1.3% 3.2%;
    }
  }
}

.diary-entry__creation-button {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 1.2% 2.2%;
  margin-top: 2.8%;

  & svg path {
    stroke: var(--main-white);
  }
}

.diary-entry__date {
  display: flex;
  align-items: center;
  gap: 6px;
  padding: 9px 20px 10px;
  transition: unset;
  font-weight: 500;
  line-height: 140%; // 22px
  color: var(--grey-main);

  @include fonts.font-size(16px);

  &--editing {
    display: flex;
    align-items: center;
    width: fit-content;
    color: var(--blue-main);
  }

  & svg path {
    stroke: var(--blue-main);
  }

  &:disabled {
    border: none;
    padding: 0;
    background-color: unset;
    color: var(--grey-main);

    &:hover {
      @media (hover: hover) {
        background-color: unset;
      }
    }

    &:active {
      background-color: unset;
    }

    & svg {
      display: none;
    }
  }
}

.button.diary-entry__date--status {
  color: var(--green-main);
}

.diary-entry__date-and-edit {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.diary-entry__edit {
  @extend %diary-entry-edit;

  & > span {
    color: var(--blue-main);
    transition: 0.5s;
    text-decoration: none;
  }

  & svg {
    width: 1.13em;
    height: 1.13em;

    & path {
      stroke: var(--blue-main);
      transition: 0.5s;
    }
  }

  &:hover {
    @media (hover: hover) {
      & > span {
        color: var(--secondary-dark);
      }

      & svg path {
        stroke: var(--secondary-dark);
      }
    }
  }

  &:active {
    & > span {
      color: var(--blue-dark);
    }

    & svg path {
      stroke: var(--blue-dark);
    }
  }
}

.diary-entry__header {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-left: 3%;
  font-weight: 500;
  line-height: 140%; // 22px

  @include fonts.font-size(16px);

  & > .diary-entry__date-picker {
    font-weight: 400;
    left: 12em;
    top: -0.3em;
  }

  &--editing {
    flex-direction: column;
    justify-content: space-between;
  }
}

.diary-entry__marker {
  position: absolute;
  width: 1.5em;
  height: 1.5em;
  background: var(--main-white);
  left: -0.85em;

  & circle {
    fill: var(--secondary-dark);
  }

  & path {
    fill: var(--secondary-dark);
    stroke: var(--secondary-dark);
  }

  &--status {
    & circle {
      fill: var(--green-main);
    }

    & path {
      fill: var(--green-main);
      stroke: var(--main-white);
    }
  }
}

.diary-entry__title-wrapper {
  width: 100%;

  & .diary-entry__error {
    margin: 0;

    &--active {
      margin-top: 0;
    }
  }
}

.diary-entry__title {
  color: var(--grey-dark);
  margin-top: 25px;
  padding: 1.5% 2.2%;
  background: transparent;
  -webkit-text-fill-color: var(--grey-dark);
  opacity: 1;
  font-weight: 500;
  line-height: 140%; // 22px

  @include fonts.font-size(16px);

  &--status {
    color: var(--green-main);
    -webkit-text-fill-color: var(--green-main);
  }

  &--editing {
    height: 3.2em !important; // How should I set height of this field?
    font-weight: 400;
  }

  &:disabled {
    border: none;
    padding: 0;
    margin: 0 0 0 16px;
  }

  &::placeholder {
    color: var(--grey-light);
    -webkit-text-fill-color: var(--grey-light);
  }
}

.diary-entry__text-wrapper {
  &--editing {
    margin-top: 5px;
    height: 29vh;
  }
}

.diary-entry__file {
  margin-top: 24px;

  & .text-input::placeholder {
    color: var(--grey-light);
    -webkit-text-fill-color: var(--grey-light);
  }
}

.diary-entry__text {
  margin: 0;
  color: var(--grey-dark);
  -webkit-text-fill-color: var(--grey-dark);
  opacity: 1;
  font-weight: 400;
  line-height: 133%; // 24px

  @include fonts.font-size(18px);

  &--status {
    display: none;
  }

  &--editing {
    height: 100%;
  }

  &:disabled {
    height: unset;
    border: none;
    padding: 0;
  }
}

%diary-entry-edit {
  display: flex;
  position: relative;
  align-items: flex-start;
  gap: 8px;
  margin-left: auto;
  padding: 0;
  transition: 0.5s;
}

.diary-entry__popup {
  @extend %diary-entry-edit;
}

.diary-entry__popup-trigger {
  & svg {
    width: 1.5em;
    height: 1.5em;

    & path {
      stroke: var(--blue-main);
    }
  }
}

.diary-entry__actions {
  display: flex;
  gap: 9px;
  margin-top: 3%;

  & button {
    height: fit-content;
    padding: 1.5% 3.4%;
  }
}

.diary-entry__popup-actions {
  width: 11.7em;
  position: absolute;
  right: 0;
  top: 34px;
  background-color: var(--main-white);
  border: 2px solid var(--secondary-dark);
  border-radius: 10px;
  padding: 75%;
  animation: fadeIn 0.5s;
}

%diary-entry-popup-action {
  display: flex;
  gap: 6px;
  align-items: center;
  font-weight: 500;
  line-height: 140%; // 22px

  @include fonts.font-size(16px);
}

.diary-entry-popup-action {
  &--edit {
    @extend %diary-entry-popup-action;

    color: var(--grey-dark);
    transition: 0.5s;

    & svg {
      width: 1.5em;
      height: 1.5em;

      & path {
        stroke: var(--grey-dark);
        transition: 0.5s;
      }
    }

    &:hover {
      @media (hover: hover) {
        color: var(--blue-main);

        & svg path {
          stroke: var(--blue-main);
        }
      }
    }

    &:active {
      color: var(--blue-dark);

      & svg path {
        stroke: var(--blue-dark);
      }
    }
  }
}

.diary-entry__delete {
  @extend %diary-entry-popup-action;

  padding: 1% 2%;
  color: var(--error-main);
  margin-left: auto;

  & svg {
    width: 1.5em;
    height: 1.5em;

    & path {
      stroke: var(--error-main);
      transition: 0.5s;
    }
  }

  &:hover {
    @media (hover: hover) {
      color: var(--error-light);

      & svg path {
        stroke: var(--error-light);
      }
    }
  }

  &:active {
    color: var(--error-dark);

    & svg path {
      stroke: var(--error-dark);
    }
  }
}

.diary-entry__popup-action {
  &--delete {
    @extend %diary-entry-popup-action;

    color: var(--error-main);
    margin-top: 13%;

    & svg {
      width: 1.5em;
      height: 1.5em;

      & path {
        stroke: var(--error-main);
        transition: 0.5s;
      }
    }

    &:hover {
      @media (hover: hover) {
        color: var(--error-light);

        & svg path {
          stroke: var(--error-light);
        }
      }
    }

    &:active {
      color: var(--error-dark);

      & svg path {
        stroke: var(--error-dark);
      }
    }
  }
}

.diary-entry__edit-label {
  color: var(--blue-main);
  font-weight: 500;
  line-height: 140%; // 22px

  @include fonts.font-size(16px);
}

.diary-entry__article {
  margin-top: 11px;
  margin-left: 3.16%;
}

.diary-entry__photos,
.diary-entry__photos-preview {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 24px;
  gap: 20px;

  @include scroll.scroll;

  & img,
  & > button,
  & .diary-entry__preview-image {
    width: 100%;
    aspect-ratio: 37/19;
  }

  & > button {
    cursor: pointer;
  }

  & img,
  & .diary-entry__preview-image {
    position: relative;
    border-radius: 10px;
    background: var(--main-white);
    object-fit: cover;
  }

  &--small {
    grid-template-columns: repeat(6, 1fr);
  }
}

.diary-entry__overlay {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  aspect-ratio: 37/19;
  border-radius: 10px;
  background-image:
    linear-gradient(
      180deg,
      rgb(38 38 38 / 0%) 0%,
      var(--grey-dark) 100%
    );
  opacity: 0;
  transition: 0.5s;

  &:hover {
    @media (hover: hover) {
      opacity: 1;

      & > button {
        opacity: 1;
      }
    }
  }

  &:active {
    opacity: 1;
  }

  & > button {
    opacity: 0;
    width: 1em;
    height: 1em;
    cursor: pointer;
    border-radius: 3px;
    align-self: flex-end;
    margin: 0.3em 1em 0 0;

    & > svg {
      fill: var(--main-white);
      width: 24px;
      height: 24px;
    }
  }
}

.diary-entry__error {
  opacity: 0;
  margin-top: 20px;
  margin-left: 10px;
  color: var(--error-main);

  &--active {
    display: inline-block;
    margin-left: 10px;
    height: 22px;
    color: var(--error-main);
    opacity: 1;
    transition: 0.5s;
    font-weight: 400;
    line-height: 140%; // 22px

    @include fonts.font-size(16px);
  }
}

.diary-entry__button {
  &--creatable {
    margin-top: 20px;
    padding: 0;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .diary__entries {
    color: var(--grey-dark);
  }

  .diary-entry__date {
    position: static;
    display: block;
    line-height: 17px;
    font-weight: 500;
    font-size: 12px;
  }

  .diary-entry__header {
    display: flex;
    flex-direction: column;
    gap: 1.2vh;
  }

  .diary-entry__title-wrapper {
    margin-top: 8px;
  }

  .diary-entry__title {
    color: var(--grey-dark);
    opacity: 1;
    line-height: 17px;
    font-weight: 500;
    font-size: 14px;

    &--status {
      color: var(--green-main);
    }

    &:disabled {
      margin-left: 0;
    }
  }

  .diary-entry__text {
    color: var(--grey-dark);
    opacity: 1;
    line-height: 20px;
    font-weight: 400;
    font-size: 14px;
  }

  .diary-entry__photos,
  .diary-entry__photos-preview {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 16px;
    gap: 12px;
    overflow: scroll clip;

    @include scroll.scroll-none;

    & img,
    & > button,
    & .diary-entry__preview-image {
      height: 58px;
      width: 94px;
    }

    & > button {
      cursor: pointer;
    }

    & img,
    & .diary-entry__preview-image {
      position: relative;
      border-radius: 10px;
      background: var(--main-white);
      object-fit: cover;
    }

    &--small {
      grid-template-columns: repeat(6, 1fr);
    }
  }

  .diary-entry__marker {
    left: -0.9em;

    & path {
      transform: scale(1);
    }
  }

  .diary-entry__date-and-edit--mobile {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  .diary-entry__edit {
    opacity: 1;
    transition: 0s;
    line-height: 14px;
    font-weight: 500;
    font-size: 12px;

    svg {
      width: 16px;
      height: 16px;
    }
  }

  .diary-entry__edit-label {
    line-height: 14px;
    font-weight: 500;
    font-size: 12px;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

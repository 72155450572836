@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.archived-badge {
  @include fonts.font-size(14px);

  font-weight: 500;
  line-height: 120%;
  color: var(--error-main);
  padding: 0.5em 1.6em;
  border: 2px solid #e66d6d;
  border-radius: 1.4rem;
  width: fit-content;
  height: fit-content;
}

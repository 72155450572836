@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.platform-team {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.platform-team__title {
  @include fonts.font-size(64px);

  font-family: Raleway, sans-serif;
  font-weight: 700;
  line-height: 117%;
  color: var(--grey-dark);
  margin: 0 0 1.3em;
}

.platform-team__flex {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 170px);
  max-width: 1577px;
}

.platform-team__flex-item {
  display: flex;
  flex-direction: column;
}

.platform-team__item-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover .platform-team__avatar {
    @media (hover: hover) {
      background-color: var(--green-light);
      border-color: var(--green-light);
    }
  }

  &:active .platform-team__avatar {
    background-color: var(--green-dark);
    border-color: var(--green-dark);
  }
}

.platform-team__avatar {
  width: 15vw;
  border-radius: 50%;
  border: 16px solid transparent;
  transition: 0.5s;
}

.platform-team__name {
  @include fonts.font-size(24px);

  font-weight: 600;
  line-height: 117%;
  color: var(--grey-dark);
  margin: 0.65em 0 0;
}

.platform-team__role {
  @include fonts.font-size(20px);

  font-weight: 500;
  line-height: 120%;
  color: var(--green-light);
  margin: 0.5em 0 0;
}

.platform-team__mobile {
  @include scroll.scroll-none;

  overflow: auto;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  display: flex;
}

.platform-team__mobile-container {
  max-width: stretch;
  margin-inline: 10px;
  flex-direction: column;
}

.platform-team__mobile-preview {
  display: flex;
  justify-content: space-evenly;
}

@media screen and (max-width: common.$mobile-w) {
  .platform-team__avatar {
    width: 40px;
    border: 4px;
  }

  .platform-team__title {
    @include fonts_mobile.mobile-font-bold-size36-line42;

    margin-bottom: 26px;
  }

  .platform-team__avatar--active {
    background-color: var(--green-dark);
    border-color: var(--green-dark);
  }

  .platform-team__member {
    width: 100%;
    scroll-snap-align: start;
    height: fit-content;
    position: relative;
  }

  .platform-team__member--before {
    position: absolute;
    width: inherit;
    height: 10px;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.status-block__title {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1.2em;
}

.status-block__comment,
.status-block__comment--digest {
  font-style: italic;
  text-align: left;
  margin-top: 8px;
  word-break: break-word;
}

.status-block__comment--digest {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.status-block__comment-date {
  margin-right: 10px;
}

.status-block__project-name {
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

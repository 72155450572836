@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.all-user-payments__loader {
  align-items: center;
}

.all-user-payments-table__header {
  top: -7.23vh;
  padding: 2vh 0 3vh;
}

.all-user-payments__table-columns {
  grid-template-columns:
    minmax(4.43vw, 11fr)
    minmax(15.63vw, 23fr)
    minmax(23.44vw, 35fr)
    minmax(14.1vw, 21fr)
    minmax(7.92vw, 10fr);

  & > button {
    padding: 0;
    border: 0;
  }
}

.all-user-payments__header-cell {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  @include fonts.font-size(16px);

  font-weight: 400;
  transition: none;
  color: var(--blue-main);

  & svg > path {
    color: var(--blue-main);
  }

  &:hover:is(button) {
    cursor: pointer;
    color: var(--blue-light);

    & svg > path {
      color: var(--blue-light);
    }
  }

  &:active:is(button) {
    color: var(--blue-dark);

    & svg > path {
      color: var(--blue-dark);
    }
  }
}

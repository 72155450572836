@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.checkbox {
  @include fonts.font-size(14px);

  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.8em;
}

.checkbox__label {
  color: var(--grey-dark);
  cursor: pointer;
}

.checkbox__input {
  margin: 0;
  background-color: var(--main-white);
  appearance: none;
  font: inherit;
  color: var(--blue-main);
  border: 2px solid var(--blue-main);
  border-radius: 4px;
  cursor: pointer;
  display: grid;
  place-content: center;

  &:disabled,
  &:disabled + .checkbox__label {
    cursor: default;
  }
}

.checkbox__input::before {
  content: "";
  transform: scale(0);
  background-image: url("./img/checkmark.svg");
  background-position: center;
  background-size: 80%;
  background-repeat: no-repeat;
  background-color: var(--blue-main);
  border-radius: 4px;
  transition: 0.5s;
}

.checkbox__input,
.checkbox__input::before {
  @include fonts.font-size(18px);

  width: 1em;
  height: 1em;
}

.checkbox__input:checked::before {
  transform: scale(1);
}

@media screen and (max-width: common.$mobile-w) {
  .checkbox {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .checkbox__input,
  .checkbox__input::before {
    @include fonts.font-size(18px);

    width: 1.78em;
    height: 1.78em;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.forgot-password-sent {
  max-width: 80%;
}

.forgot-password-sent__title {
  text-align: center;
  margin: 0 0 0.5em;
}

.forgot-password-sent__description {
  @include fonts.font-regular-small;

  text-align: center;
  margin: 0 0 0.5em;
  color: var(--grey-dark);
}

.forgot-password-sent__email {
  margin: 0 0 0.5em;
  color: var(--green-main);
  text-align: center;

  @include fonts.font-regular-small;
}

.forgot-password-sent__back {
  position: absolute;
  top: 2.2em;
  left: 1em;
  margin-left: 2.1%;
  border: 0;
  padding: 0;
  color: var(--grey-dark);

  @include fonts.font-medium-small;

  & > svg {
    width: 32px;
    height: 32px;
  }

  & > svg > path {
    width: 32px;
    height: 32px;
    fill: var(--grey-dark);
    stroke: none;
    transform-origin: center;
    transform: rotate(90deg);
  }

  & > span {
    vertical-align: 4px;
    margin-left: 0;
  }

  &:hover {
    & > svg > path {
      fill: var(--blue-light);
      stroke: none;
    }
  }
}

.forgot-password-sent__go-by-link {
  text-align: center;
  margin: 0 0 2.5em;
}

.forgot-password-sent__button {
  width: 50%;
  margin: 0 auto;
  display: block;
}

@media screen and (max-width: common.$mobile-w) {
  .forgot-password-sent {
    border: none;
    padding: 0;
    margin: 4em auto 8px;
  }

  .forgot-password-sent__description,
  .forgot-password-sent__go-by-link,
  .forgot-password-sent__email {
    @include fonts_mobile.mobile-font-regular-16px-line24;
  }
}

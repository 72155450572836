@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.status-editor {
  margin-top: 4.7vh;
  display: flex;
  flex-direction: column;
}

.status-editor__dropdown-label {
  @include fonts.font-medium-small;

  display: flex;
  align-items: center;
}

.status-editor__buttons {
  margin-top: 2.8vh;
  display: flex;
  flex-direction: row;
  gap: 9px;
}

.status-editor__save-button,
.status-editor__cancel-button {
  padding: 1% 2%;
}

.status-editor__dropdown {
  margin-left: 17px;
  width: 16.2em;

  & .status-editor__control {
    @include fonts.font-regular-small;

    padding: 0.75em 1.25em;
    border: 1px solid var(--secondary-dark);
    border-radius: 10px;
    box-shadow: none;

    &:hover {
      @media (hover: hover) {
        border: 1px solid var(--secondary-dark);
      }
    }

    &:focus {
      outline: none;
    }

    & .status-editor__indicators {
      & > span {
        display: none;
      }
    }

    & .status-editor__dropdown-indicator {
      padding: 0;
      transition: 0.5s;
      color: var(--grey-dark);

      & > svg {
        width: 24px;
        height: 24px;
      }
    }

    &.status-editor__control--menu-is-open {
      border-radius: 10px 10px 0 0;
      border-bottom: 1px solid var(--main-white);

      & .status-editor__dropdown-indicator {
        transform: rotate(180deg);
      }
    }
  }

  & .status-editor__value-container,
  & .status-editor__single-value,
  & .status-editor__input-container {
    padding: 0;
    margin: 0;
  }

  & .status-editor__menu {
    margin: 0;
    border-left: 1px solid var(--secondary-dark);
    border-right: 1px solid var(--secondary-dark);
    border-bottom: 1px solid var(--secondary-dark);
    box-shadow: none;
    border-radius: 0 0 10px 10px;
  }

  & .status-editor__option {
    @include fonts.font-regular-small;

    padding: 0.75em 1.25em;
    color: var(--grey-dark);

    &.status-editor__option--is-selected {
      background: none;
    }

    &:hover {
      @media (hover: hover) {
        background: var(--secondary-main);
      }
    }

    &.status-editor__option--is-focused {
      background: var(--secondary-main);
    }
  }
}

@media (max-width: common.$mobile-w) {
  .status-editor__dropdown-label {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .status-editor__dropdown .status-editor__control {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .status-editor__buttons {
    & button {
      @include fonts_mobile.mobile-font-regular-size16-line22;

      padding: 3%;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";

.swiper-photo-carousel {
  width: 100%;
  height: auto;

  & div.swiper-wrapper {
    align-items: center;

    .swiper-slide button {
      display: block;
      margin: 0 auto;
    }
  }
}

.mobile-photo-carousel--default {
  div.swiper-slide {
    max-height: 90%;
    height: 90%;
    opacity: 0.4;
    overflow: hidden;
    transition: 0.5s;

    & > button {
      max-height: 100%;
      height: 100%;
    }
  }

  div.swiper-slide-active {
    max-height: 100%;
    height: 100%;
    transition: 0.5s;
    opacity: 1;

    & > button {
      max-height: 100%;
      height: 100%;
    }
  }

  .swiper-photo-carousel__image {
    max-width: 100%;
    width: 323px;
    height: 196px;
    min-height: 100%;
    object-fit: cover;
  }
}

.swiper-photo-carousel__image {
  max-width: 100%;
}

.desktop-photo-carousel--default {
  @media screen and (min-width: common.$hd-w) {
    height: 80%;
  }

  @media screen and (min-width: common.$wsxga-w) {
    height: 70%;
  }

  @media screen and (min-width: common.$full-hd-w) {
    height: 60%;
  }

  @media screen and (min-width: common.$two-k-w) {
    height: 50%;
  }

  @media screen and (min-width: common.$ultra-hd-w) {
    height: 45%;
  }

  div.swiper-slide {
    opacity: 0.4;
    overflow: hidden;
    transition: 0.5s;

    & > button > img {
      transition: 0.75s;
      object-fit: cover;
      width: 100%;
      height: 90%;
    }

    & > button {
      width: 100%;
      height: 100%;
    }
  }

  div.swiper-slide-active {
    transition: 0.5s;
    opacity: 1;

    & > button > img {
      transition: 0.75s;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    & > button {
      width: 100%;
      height: 100%;
    }
  }

  .button-round {
    border-radius: 100%;
    padding: 12px;
    line-height: 0;
    z-index: 1;
    margin: 12px;
  }
}

.swiper {
  display: flex;
  align-items: center;

  & .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
  }

  &:hover {
    & .swiper-button-prev,
    & .swiper-button-next {
      position: absolute;
      opacity: 1;
      z-index: 1;
    }

    & .swiper-button-next {
      right: 5%;
    }

    & .swiper-button-prev {
      left: 5%;
    }
  }
}

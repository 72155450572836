@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.warning-message {
  display: flex;
  flex-direction: row;
  gap: 6px;
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  background-color: var(--main-white);
  padding: 15px;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    path {
      stroke: var(--grey-dark);
    }
  }
}

.warning-message-text {
  margin: 0;

  @include fonts_mobile.mobile-font-regular-size12-line17;
}

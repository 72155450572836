@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.user-projects {
  margin: 0.5em;
  height: 100%;

  &__title {
    margin-bottom: 44px;
  }

  & .projects-list-container__header {
    margin-bottom: 35px;
  }

  & .search-input {
    width: 43.5vw;
  }

  & .projects-list-container__projects-list {
    grid-template-columns: repeat(auto-fill, 341px);
  }

  & .projects-list__empty-list {
    margin: 16px 8px;

    @include fonts.font-regular-size18-line27;

    line-height: 140%;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .user-projects {
    & .projects-list-container__header {
      margin-bottom: 0;
    }

    & .search-input {
      width: 75vw;
    }

    & .projects-list__empty-list {
      margin: 16px 8px;

      @include fonts_mobile.mobile-font-regular-size16-line22;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.files-uploader-widget__subtitle {
  @include fonts.font-size(18px);

  line-height: 140%;
  margin: 1.55em 0 0;
  color: var(--grey-dark);
}

.files-uploader-widget__photos-uploader {
  @include fonts.font-size(10px);

  max-width: 64%;
  margin: 3.6em 0 0;
}

.files-uploader-widget__designs-uploader {
  max-width: 69%;
  margin: 4.2% 0 0;
}

.files-uploader-widget__docs-uploader {
  max-width: 69%;
  margin: 5% 0 0;
}

.files-uploader-widget__notification {
  z-index: 100000;
  left: 50%;
}

@media screen and (max-width: common.$mobile-w) {
  .files-uploader-widget {
    max-width: 100%;
    padding: 0 8px;
  }

  .files-uploader-widget__title {
    display: none;
  }

  .files-uploader-widget__subtitle {
    font-size: 14px;
    line-height: 135%;
    margin: 0;
  }

  .files-uploader-widget__photos-uploader {
    max-width: 100%;
    margin: 24px 0 0;

    &:first-of-type {
      margin: 18px 0 0;
    }
  }

  .files-uploader-widget__designs-uploader {
    max-width: 100%;
    margin: 24px 0 0;
  }

  .files-uploader-widget__docs-uploader {
    max-width: 100%;
    margin: 24px 0 0;
  }

  .files-uploader-widget__notification {
    width: calc(100vw - 46px);
    border-radius: 5px;
    padding: 12px 16px;
    gap: 11px;

    & > .notification-popup__icon {
      width: 16px;
      height: 16px;
      margin: 0;
      flex-shrink: 0;
    }

    & > .notification-popup__text {
      @include fonts_mobile.mobile-font-regular-size12-line17;
    }
  }
}

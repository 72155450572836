@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.donation-modal {
  background: var(--main-white);
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 4.1% 3.1%;
  max-width: 22vw;
}

.donation-spinner {
  @include fonts.font-size(10px);

  position: absolute;
  width: 8em;
  height: 8em;
  top: calc(50% - 4em);
  left: calc(50% - 4em);

  svg {
    width: 8em;
    height: 8em;
  }
}

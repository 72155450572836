@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.detailed-info {
  height: 90.8%;
  padding-top: 2.1%;
}

.detailed-info__back {
  @include fonts.font-medium-size20-line24;

  margin-left: 2.1%;
  border: 0;
  padding: 0;
  color: var(--grey-dark);

  & > svg > path {
    transform-origin: center;
    transform: rotate(180deg);
  }

  & > span {
    vertical-align: 4px;
    margin-left: 10px;
  }
}

.link-doc svg path {
  stroke: var(--blue-main);
  transition: 0.5s;
}

.detailed-info__main {
  display: flex;
  gap: 16.5%;
  margin: 38px 5%;
  padding-right: 2%;
  overflow-y: auto;
  overflow-x: hidden;

  @include scroll.scroll;
}

.detailed-info__content {
  min-width: 52%;
}

.detailed-info__side {
  width: 29.6%;
  flex-shrink: 0;
}

.detailed-info__title-wrapper {
  @include fonts.font-size(10px);

  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 2em;
}

.detailed-info__title {
  margin: 0;
  font-size: 3.2em;
  font-weight: 500;
  line-height: 120%;
  color: var(--grey-dark);
  width: fit-content;
  word-break: break-word;
}

.detailed-info__archived-badge {
  font-size: 1.4em;
  font-weight: 500;
  line-height: 120%;
  color: var(--error-main);
  padding: 0.36em 1.57em 0.43em;
  border: 2px solid #e66d6d;
  border-radius: 1.4em;
}

.detailed-info__address {
  @include fonts.font-medium-size20-line24;

  margin-top: 16px;
  color: var(--grey-main);
}

.detailed-info__photo-carousel {
  margin-top: 34px;
}

.detailed-info__description {
  margin-top: 62px;
  color: var(--grey-dark);
  overflow-wrap: break-word;
  white-space: pre-wrap;

  @include fonts.font-regular-size18-line24;
}

.detailed-info__diary {
  margin-top: 54px;
}

.detailed-info__diary-title {
  @include fonts.font-medium-size20-line24;

  color: var(--grey-dark);
}

.detailed-info__diary-description {
  margin-top: 20px;
  color: var(--grey-dark);

  @include fonts.font-regular-size18-line24;
}

.detailed-info__diary-no-data {
  @include fonts.font-regular-small;

  margin-top: 18px;
  color: var(--blue-main);
}

.detailed-info__buttons {
  display: flex;
}

.detailed-info__buttons--left {
  display: flex;
  align-items: center;
  gap: 1.5em;
  position: relative;
}

.detailed-info__side-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 7%;
}

.detailed-info__show-map:disabled {
  background-color: transparent;
  color: var(--grey-main);
  border-color: var(--grey-main);

  & svg path {
    stroke: var(--grey-main);
  }
}

.detailed-info__status-with-timeline {
  margin: 1.25em 0 1.125em;
}

.detailed-info__edit {
  @include fonts.font-medium-small;

  padding: 1.4% 0 0;
}

.detailed-info__timeline {
  position: relative;
}

.detailed-info__members,
.detailed-info__timeline,
.detailed-info__donation,
.detailed-info__docs {
  margin-top: 5vh;
  margin-bottom: 5vh;
  padding: 8.6%;
  border: 2px solid var(--secondary-main);
  border-radius: 10px;

  @media screen and (max-width: common.$hd-w) {
    width: 14rem;
  }
}

.button_text-link {
  margin: 0;
}

.detailed-info__donation-btn {
  cursor: pointer;
}

.detailed-info__members {
  margin-top: 3.3vh;
}

.detailed-info__timeline-title {
  @include fonts.font-size(20px);

  font-weight: 500;
  line-height: 120%;
  margin: 0;
  color: var(--grey-dark);
}

.detailed-info__timeline-chart {
  margin-top: 6.3%;
}

.detailed-info__comments {
  margin-top: 6.5%;
  padding-bottom: 13%;

  &-spoiler {
    @include fonts.font-medium-small;

    display: flex;
    align-self: flex-start;
    align-items: center;
    color: var(--blue-main);
    cursor: pointer;

    & > svg {
      transition: 0.5s;

      & > path {
        fill: var(--blue-main);
      }
    }

    &--open {
      @include fonts.font-medium-small;

      display: flex;
      align-items: center;
      color: var(--blue-main);
      cursor: pointer;

      & > svg {
        transition: 0.5s;
        transform: rotate(180deg);

        & > path {
          fill: var(--blue-main);
        }
      }
    }
  }

  &-form {
    height: 0;
    overflow: hidden;

    &--open {
      margin-top: 3.8%;
    }
  }

  &-list {
    height: 0;
    overflow: hidden;
    transition: 0.5s;

    &--open {
      margin-top: 3.3%;
      overflow: hidden;
      transition: 0.5s;
    }
  }
}

.link-doc {
  @include fonts.font-medium-small;

  display: flex;
  align-items: center;
  gap: 6px;
  text-decoration: none;
  padding-top: 20px;
  color: var(--blue-main);
  transition: 0.5s;
  padding-right: 30px;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;

  &:hover {
    @media (hover: hover) {
      color: var(--blue-light);

      & > svg path {
        stroke: var(--blue-light);
      }
    }
  }

  &:active {
    color: var(--blue-dark);

    & > svg path {
      stroke: var(--blue-dark);
    }
  }
}

.list-docs {
  margin-top: 4px;
  display: flex;
  overflow: auto;
  flex-direction: column;

  @include scroll.scroll;
}

.link-doc__text {
  overflow: hidden;
  text-overflow: ellipsis;
}

.links-doc__icon {
  min-width: 32px;
}

.detailed-info__donation-form {
  color: var(--grey-dark);

  .donation-form__checkbox {
    width: 100%;
  }

  .donation-from__sum {
    width: 100%;
    padding: 1.15vh 1vw;
    margin-top: 0.9vh;
  }

  .support-block__email {
    padding: 1.15vh 1vw;
  }

  .donation-form__button {
    width: 65%;
    padding: 1vh 0;

    @include fonts.font-medium-small;
  }
}

.detailed-info__notification {
  left: 50%;
}

.detailed-info__total-budget {
  margin-top: 9%;
}

.detailed-info__photo-mock {
  margin-top: 1.6em;
  border-radius: 20px;
  min-height: 12em;
}

.detailed-info__share-plate {
  position: absolute;
  top: 100%;
  margin-top: 0.75em;
  margin-left: 1.7em;
}

.detailed-info__diary-entries {
  padding-left: 0;

  & > .diary-entry {
    border-left: 0;
    padding-bottom: 0;

    &:last-child {
      border-left: 0;
    }

    & > header {
      flex-direction: column;
      gap: 6px;
      margin: 0;

      & textarea {
        margin: 0;
      }

      & > svg {
        display: none;
      }
    }

    & > article {
      margin-top: 6px;
      margin-left: 0;
    }
  }

  & > .diary-entry__button {
    margin-top: 20px;
    padding: 0;

    & svg {
      height: 1em;
      width: 1em;
      stroke-width: 4px;
    }
  }
}

.detailed-info__show-map {
  padding: 1.4% 2.4%;
  white-space: nowrap;
}

.detailed-info__button--share {
  padding: 0;
  border: none;
}

.detailed-info__button--share > svg {
  fill: var(--blue-dark);
  height: 2em;
  width: 2em;
}

.detailed-info__button--share > svg > path {
  fill: var(--blue-dark);
}

@media screen and (max-width: common.$hd-w) {
  .detailed-info__timeline {
    & .project-status-vertical__dates {
      padding-top: 0;
      padding-bottom: 0;
      gap: 16%;
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .detailed-info__button--like,
  .detailed-info__button--comment {
    & > svg {
      width: 1em;
      height: 1em;
    }
  }

  .detailed-info-fixed-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 0;
    opacity: 0;
    margin: 0 0 0 8px;
    transition: opacity 0.5s;

    &--visible {
      height: 32px;
      opacity: 1;
    }
  }

  .detailed-info__total-budget {
    margin-top: 20px;
  }

  .detailed-info-fixed-header__project-name {
    max-width: 70vw;
    margin: 0;
    color: var(--grey-dark);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    @include fonts_mobile.mobile-font-bold-14px-line32;
  }

  .detailed-info-fixed-header__close-button > svg > path {
    fill: var(--grey-dark);
  }

  .detailed-info-fixed-footer {
    height: 0;
    display: none;
    box-sizing: border-box;

    &--with-header {
      height: 50px;
      display: block;
    }

    &--with-header#{&}--shrinked {
      height: 92px;
    }

    &--with-header-full {
      height: 111px;
      display: block;
    }

    &--with-header-full#{&}--shrinked {
      height: 153px;
    }

    &__control {
      display: flex;
      justify-content: space-between;
      padding: 0 8px;

      & .detailed-info__button--like {
        color: var(--blue-main);

        & > svg > path {
          stroke: var(--blue-dark);
        }

        & .like-button__icon--liked path:first-child {
          fill: var(--blue-dark);
        }
      }

      .detailed-info__button--share {
        & > svg {
          height: 1.5em;
          width: 1.5em;
        }
      }

      .detailed-info__button--comment,
      .detailed-info__button--share {
        color: var(--blue-dark);

        & > svg > path {
          fill: var(--blue-dark);
        }
      }

      .detailed-info__button--join {
        color: var(--blue-dark);

        & > svg > path {
          stroke: var(--blue-dark);
        }

        .join-button-compact__icon {
          &--joined > path {
            fill: var(--blue-dark);
            stroke: none;
          }
        }
      }

      .detailed-info__edit {
        padding: 10px 0 12px;
        color: var(--blue-dark);

        // TODO(Nariman): Нужно добавить новый тип кнопки для share(как IconButton в MUI) в библотеку компонентов
        & > svg {
          height: 1.2em;
          width: 1.2em;
        }

        & > svg > path {
          stroke: var(--blue-dark);
        }
      }
    }

    &__buttons--left {
      display: flex;
    }
  }

  .detailed-info {
    height: 100%;
    margin: 0;
    overflow: auto;
    padding: 0;

    @include scroll.scroll-none;

    &--with-header {
      height: calc(100% - 82px);
      transition: 0.5s;
    }

    &--with-header-full {
      height: calc(100% - 143px);
      transition: 0.5s;
    }

    &--with-header#{&}--shrinked {
      height: calc(100% - 185px);
    }
  }

  .detailed-info__image-header {
    position: relative;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 217px;
    justify-content: space-between;
    overflow: visible;

    &--hidden {
      opacity: 0;
    }
  }

  .detailed-info__image-wrapper {
    height: 240px;
    width: 100%;
    position: absolute;
    z-index: -1;

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background:
        linear-gradient(
          180deg,
          #3a3a3a -15.29%,
          rgb(5 5 5 / 21%) 100%
        );
    }

    background-image: url("../../../features/project/components/ProjectPreviewContainer/img/mock.svg");
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .detailed-info__image-background {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
  }

  .detailed-info__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 8px 0;
    gap: 8px;
  }

  .detailed-info__project-name {
    margin: 0;
    color: var(--main-white);
    word-wrap: break-word;
    overflow: hidden;

    @include fonts_mobile.mobile-font-bold-20px-line24;
  }

  .detailed-info__status-with-timeline {
    margin: 0 8px 3%;
  }

  .detailed-info__close-button--header {
    width: 24px;
    height: 24px;
    padding: 0;

    & > svg {
      fill: var(--main-white);
    }

    &:active {
      -webkit-tap-highlight-color: transparent;
    }
  }

  .detailed-info__header-control {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .detailed-info__button--join {
      .join-button-compact__icon {
        &--joined > path {
          fill: var(--main-white);
          stroke-width: 0;
        }
      }
    }
  }

  .detailed-info__buttons--left {
    gap: 0;
  }

  .detailed-info__button--like,
  .detailed-info__button--comment,
  .detailed-info__button--share,
  .detailed-info__button--join,
  .detailed-info__edit {
    height: auto;
    padding: 14px 0;
    display: flex;

    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .detailed-info__edit {
    transition: none;
  }

  .detailed-info__button--like > svg > path {
    stroke: var(--main-white);
  }

  .detailed-info__button--comment > svg > path {
    fill: var(--main-white);
    stroke: none;
  }

  .detailed-info__edit > svg {
    & > path {
      stroke: var(--main-white);
    }
  }

  .detailed-info__content {
    background-color: var(--main-white);
    border-radius: 22px 22px 0 0;
    padding: 22px 0 0;
    min-height: common.get-mobile-vh(100);

    &--full {
      padding: 0;
      border-radius: 0;
    }
  }

  .detailed-info__address {
    margin: 0 8px;
    color: var(--grey-dark);

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .detailed-info__timeline-chart {
    margin-top: 20px;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    padding: 0 8px;

    @include scroll.scroll-none;

    & > .project-status__text-row {
      display: flex;
      width: 160%;
      gap: 10vw;
      justify-content: space-between;

      & > .project-status__text {
        display: flex;
        flex-grow: 1;
        width: 20vw;
        max-width: 84px;

        @include fonts_mobile.mobile-font-bold-12px-line17;
      }
    }

    & > .project-status__bar-row {
      flex-grow: 1;
      margin-top: 7px;
      box-sizing: border-box;
      width: 157%;

      & > .project-status__bar-part {
        margin-top: 6px;
      }
    }

    & > .project-status__point-row {
      width: 160%;

      & > div {
        display: flex;
      }

      & svg {
        width: 16px;
        height: 16px;
      }
    }

    & > .project-status__text-row-edit-date {
      display: flex;
      margin-top: 8px;
      width: 161%;
      justify-content: space-between;

      & > .project-status__date {
        color: var(--grey-dark);

        @include fonts_mobile.mobile-font-regular-10px;
      }
    }
  }

  .detailed-info__meeting-point {
    margin: 3% 8px 0;
    max-width: 95%;
  }

  div.detailed-info__photos {
    margin-top: 18px;
    min-height: 27vh;
    height: 27vh;

    & div.swiper-wrapper {
      min-height: 27vh;
      height: 100%;
    }
  }

  .detailed-info__photo-mock {
    margin: 20px 8px 0;
    border-radius: 6px;
    min-height: auto;
    height: 20vh;
  }

  .detailed-info__description {
    margin: 16px 8px 0;

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .detailed-info__diary {
    margin: 12px 8px 0;

    & .detailed-info__diary-title {
      margin: 0 0 6px;

      @include fonts_mobile.mobile-font-bold-16px-line32;
    }

    & .detailed-info__diary-description {
      margin: 0 0 10px;

      @include fonts_mobile.mobile-font-regular-size16-line22;
    }

    & .diary__entries {
      & > .diary-entry {
        border-left: 0;
        padding-bottom: 0;

        &:last-child {
          border-left: 0;
        }

        & > header {
          flex-direction: column;
          gap: 6px;
          margin: 0;

          & textarea {
            margin: 0;
          }

          & > svg {
            display: none;
          }
        }

        & > article {
          margin-top: 6px;
          margin-left: 0;
        }
      }

      & > .diary-entry__button {
        margin-top: 20px;
        padding: 0;

        & svg {
          height: 1em;
          width: 1em;
          stroke-width: 4px;
        }
      }
    }
  }

  .detailed-info__comments-form {
    height: unset;
    margin: 0 8px 14px;
  }

  .detailed-info__donation-form {
    width: 95%;
    margin: 24px auto;

    .donation-from__sum {
      padding: 0 20px;
      height: 47px;
    }

    .support-block__email {
      padding: 0 20px;
      height: 47px;
    }

    .donation-form__button {
      width: 100%;

      @include fonts_mobile.mobile-font-medium-size14-line17;
    }
  }

  .detailed-info__notification {
    z-index: 1;
    width: calc(100vw - 46px);
    border-radius: 5px;
    padding: 12px 16px;
    gap: 11px;

    & > .notification-popup__icon {
      width: 16px;
      height: 16px;
      margin: 0;
      flex-shrink: 0;
    }

    & > .notification-popup__text {
      @include fonts_mobile.mobile-font-regular-size12-line17;
    }
  }

  @media screen and (max-width: 350px) {
    .detailed-info__timeline-chart > .project-status__bar-row {
      width: 150%;
    }
  }

  .diary__entries {
    & > button {
      font-size: 16px;
    }
  }
}

.detailed-project-info__spinner {
  @include fonts.font-size(10px);

  position: absolute;
  width: 8em;
  height: 8em;
  top: calc(50% - 4em);
  left: calc(50% - 4em);

  svg {
    width: 8em;
    height: 8em;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.quill-text-editor {
  display: flex;
  flex-direction: column;
  height: 100%;

  & .ql-toolbar {
    background: var(--secondary-main);
    border-radius: 10px;
    border-color: transparent;
    padding: 0.438em 0.875em;
    margin: 0 0 10px;
    display: flex;
    gap: 0;

    & .ql-formats {
      display: flex;
      align-items: center;
      margin-right: 0;
    }

    & button {
      height: 1.8em;
      padding: 0.25em;
      box-sizing: unset;
      border-radius: 10px;
      position: relative;
      transition: 0.5s;
      display: flex;
      justify-content: center;

      &:hover {
        @media (hover: hover) {
          background: var(--secondary-light);
          transition: 0.5s;
          cursor: pointer;

          &::after {
            transition: visibility 0s, opacity 0.3s linear;
            opacity: 1;
            visibility: visible;
          }
        }
      }

      &:active,
      &--active {
        background: var(--main-white);
        transition: 0.5s;
      }
    }
  }

  & .ql-container {
    height: 85%;
    display: flex;
    justify-content: space-between;
    min-width: 32%;
    border: 1px solid var(--grey-light) !important;
    padding: 0.75em 1.25em;
    margin: 0;
    border-radius: 10px;
    overflow: auto;

    & #emoji-palette {
      min-width: 260px;
      background: var(--main-white);
      border: 1px solid var(--blue-main);
      border-radius: 10px;

      & #tab-panel,
      #tab-toolbar {
        background-color: var(--main-white);
        padding: 5px;
        border: 1px solid var(--blue-main);
      }

      & #tab-toolbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        border-bottom: 0;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;

        & li {
          background-color: var(--main-white);
          border-radius: 5px;
          border: none;
        }

        & .active {
          background-color: var(--secondary-main);
          border-radius: 5px;
        }

        & li:hover {
          background-color: var(--secondary-light);
          border-radius: 5px;
        }
      }

      & #tab-panel {
        justify-content: start;
        border-top: 0;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        @include scroll.scroll; // This look oddly with our scroll, may be turn of?
      }
    }

    & > .ql-blank::before {
      left: 0;
      right: 0;
      font-style: normal;
      color: var(--grey-light);
      -webkit-text-fill-color: var(--grey-light);
    }

    & > .ql-editor {
      padding: 0;
      width: 100%;
      overflow-y: scroll;
      font-family: Rubik, sans-serif;

      @include fonts.font-size(16px);
      @include fonts.font-size(16px);
      @include scroll.scroll;

      & h1 {
        margin: 0;
        font-weight: 500;

        @include fonts.font-size(24px);
      }

      & h2 {
        margin: 0;
        font-weight: 500;

        @include fonts.font-size(22px);
      }

      & h3 {
        margin: 0;
        font-weight: 500;

        @include fonts.font-size(18px);
      }

      & p {
        margin: 0;
        font-weight: 400;
      }

      & ul {
        margin: 0;
        padding-left: 2em;
        line-height: 110%;
      }

      & ol {
        margin: 0;
        padding-left: 2em;
      }

      & ul li,
      ol li {
        margin: 0;
        padding: 0;
        line-height: 100%;
      }

      & a {
        margin: 0;
        color: var(--green-main);

        &:hover {
          color: var(--green-light);
        }

        &:active {
          color: var(--green-dark);
        }

        &:visited {
          color: var(--blue-main);
        }
      }
    }

    .ql-tooltip {
      transform: translateX(40%);
      border-radius: 10px;
      border-color: var(--grey-light);
      color: var(--grey-dark);

      &::before {
        content: "Ссылка:";
        color: inherit;
      }

      & > a.ql-action::after {
        content: "Редактировать";
        color: var(--blue-main);
      }

      & > a.ql-remove::before {
        content: "Удалить";
        color: var(--blue-main);
      }

      & > a.ql-preview {
        color: var(--green-light);

        &:active {
          color: var(--green-dark);
        }
      }
    }

    .ql-tooltip.ql-editing {
      &::before {
        content: "Вставьте ссылку:";
        color: inherit;
      }

      & > a.ql-action::after {
        content: "Сохранить";
        color: var(--blue-main);
      }

      & > input {
        color: inherit;
      }
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .quill-text-editor {
    & .ql-toolbar {
      width: 100%;
      padding: 0.4em 12px;
      white-space: nowrap;
      justify-content: space-between;
      gap: 0;

      @include scroll.scroll-none;

      & .ql-formats {
        margin: 0;
      }
    }

    & .ql-container {
      padding: 0;
      max-height: 250px;

      & > .ql-blank::before {
        padding: 0 0 0 2.2%;
      }

      & > .ql-editor {
        padding: 4% 2.2%;
        font-family: Rubik, sans-serif;
        font-size: 14px;
        line-height: 17px;

        @include scroll.scroll-none;

        & h1 {
          font-size: 20px;
          line-height: 28px;
        }

        & h2 {
          font-size: 18px;
          line-height: 24px;
        }

        & h3 {
          font-size: 16px;
          line-height: 22px;
        }
      }
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.donation-failure {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 8px;
}

.donation-failure__header {
  @include fonts.font-medium-big;

  text-align: center;
  margin: 0 0 1em;
  color: var(--grey-dark);
}

.donation-failure__text {
  @include fonts.font-size(18px);

  color: var(--grey-dark);
  line-height: 140%;
  text-align: center;
  margin: 0 0 2.4em;
}

@media screen and (max-width: common.$mobile-w) {
  .donation-failure__text {
    @include fonts_mobile.mobile-font-regular-16px-line24;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.profile-participations {
  color: var(--grey-dark);
}

.profile-participations__header {
  padding: 3vh 0 0;
  top: -7.23vh;
}

.profile-participations__table {
  margin-top: 0;
  gap: 1vh;
}

.profile-participations__no-active-requests {
  @include fonts.font-size(18px);

  font-weight: 400;
  padding: 0;
  margin: 1.563em 17em 0 1.15em;
  color: var(--grey-main);
}

.profile-participations__table-columns {
  grid-template-columns:
    minmax(9vw, 1fr)
    minmax(12.5vw, 1.5fr)
    minmax(18.8vw, 2fr)
    minmax(20.84vw, 2.2fr)
    minmax(12vw, 1fr);
  column-gap: 0;
}

.profile-participations__table-columns > span {
  padding: 1.9em 3.125em 1.9em 1.25em;
}

.profile-participations__empty-list-mobile {
  color: var(--grey-main);
  margin: 8px;

  @include fonts_mobile.mobile-font-regular-14px-line20;
}

@media (max-width: common.$mobile-w) {
  .profile-participations {
    gap: 10px;
    margin: 0 8px;
    display: flex;
    flex-direction: column;
  }

  .project-requests__notification {
    @include fonts.font-size(22px);

    font-weight: 400;
    line-height: 135%;
    max-height: 2.623vh;
    height: unset;
    margin: 0 auto 5%;
    padding: 16px 14px;
    gap: 4.2%;
    width: calc(100% - 44px);
    left: 50%;

    & > svg {
      width: 1em;
      height: 1em;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/fonts_mobile";

.status-button {
  padding: 0.55em 1em;
  color: var(--grey-dark);
  border: 1px solid var(--secondary-main);
  background: var(--main-white);
  border-radius: 8px;
  display: flex;
  gap: 0.5em;
  white-space: nowrap;
  align-items: center;

  @include fonts_mobile.mobile-font-regular-14px-line20;

  &--active {
    border-color: var(--secondary-dark);
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.cookie-card {
  position: fixed;
  right: 2vw;
  bottom: 2vh;
  z-index: 1000000;
  background: var(--main-white);
  border-radius: 10px;
  max-width: calc(382px - 68px);
  max-height: calc(245px - 68px);
  padding: 34px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 5px 0 rgba(55 54 107 / 13%);
  font-family: Rubik, sans-serif;

  &_animation_wobble {
    animation: wobble 1s;
  }

  &_disable {
    display: none;
  }

  &__header {
    @include fonts.font-medium-size20-line24;

    color: var(--grey-dark);
    margin-bottom: 16px;
  }

  &__content {
    width: 100%;
    margin-bottom: 24px;
  }

  &__message {
    @include fonts.font-regular-small;

    margin: 0;
    padding: 0;
    align-self: flex-start;
    color: var(--grey-dark);
  }

  &__link {
    @include fonts.font-medium-size16-line22;

    text-decoration: underline;
    color: var(--green-main);
  }

  &__button-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }
}

.cookie-card__reject,
.cookie-card__accept {
  padding: 12px 30px 13px;
  box-sizing: content-box;
  font-weight: 400;
  width: 45%;
  transition: 0.5s;
}

.cookie-card__reject {
  border: 1px solid var(--blue-main);
}

.cookie-card__accept {
  border: 1px;
}

@media (max-width: common.$mobile-w) {
  .cookie-card_mobile {
    padding: 24px;
    bottom: 1vh;
    width: 100%;
    height: 100%;
    z-index: 100002;
    max-width: calc(335px - 48px);
    max-height: calc(205px - 48px);
  }

  .cookie-card__header {
    @include fonts_mobile.mobile-font-bold-16px-line32;

    margin-bottom: 4px;
  }

  .cookie-card__content {
    margin-bottom: 16px;
  }

  .cookie-card__message {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .cookie-card__link {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .cookie-card__button-block {
    width: 100%;
    justify-content: space-between;
    margin-top: 0;
    box-sizing: border-box;
    gap: 10px;
  }

  .cookie-card__reject {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    flex: 1;
  }

  .cookie-card__accept {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    flex: 1;
  }
}

@keyframes wobble {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(3deg);
  }

  50% {
    transform: rotate(-2deg);
  }

  75% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

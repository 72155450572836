@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.status-card {
  @include fonts_mobile.mobile-font-regular-size12-line17;

  padding: 1em 1.14em 1.14em;
  border-radius: 10px;
  background: var(--secondary-light);

  & p {
    margin: 0;
  }

  &_notActive {
    background-color: var(--secondary-light);
    color: var(--grey-main);
  }
}

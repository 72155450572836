@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.participants {
  max-width: 70%;
  height: 80%;
}

.participants__title {
  margin-bottom: 1.5em;
}

.participants__initiator {
  margin-bottom: 1.25em;
}

.participants__list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 1em;
  max-height: 65%;
  overflow: auto;

  @include scroll.scroll;
}

.initiator {
  color: var(--grey-dark);
}

.initiator__label {
  font-weight: 500;
}

.participant {
  display: flex;
  align-items: center;
}

.participant__name {
  padding: 0;
  margin-left: 1.25em;
  color: var(--blue-dark);
  text-decoration: underline;

  & p {
    margin: 0;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .edit-layout__gate .participants__title__header-text {
    display: none;
  }

  .participants {
    max-width: 100%;
    padding: 0 8px;
  }

  .participant__name {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.members__support-button {
  margin-top: 24px;
  padding: 3.3% 10%;
}

.members__join-button {
  margin-top: 2em;
  padding: 1em 2em;
}

.members__support-button_mobile {
  display: block;
  margin: 24px auto 0;
  width: 96%;

  @include fonts_mobile.mobile-font-medium-size14-line17;
}

.members__button--open {
  & > svg {
    transition: 0.5s;
    transform: rotate(180deg);
  }
}

.members__title {
  @include fonts.font-medium-size20-line24;

  text-align: start;
  padding-left: 0;
  margin: 0;
  color: var(--grey-dark);
  border: none;
  border-radius: 0;
}

.members__title.button--text-dark:disabled {
  color: var(--grey-dark);
}

.members__button-list {
  margin-top: 0.741vh;
}

.member__block,
.member__block:disabled {
  display: block;
  margin: 1.483vh 0 0;
  padding: 0;
  text-align: left;
  overflow-wrap: anywhere;
  color: var(--grey-dark);
  border: none;
  border-radius: 0;
  white-space: pre-line;

  @include fonts.font-medium-small;

  &.member__block-small-margin {
    margin-top: 1em;
  }

  &:hover {
    @media (hover: hover) {
      color: var(--grey-dark);
    }
  }

  &:active {
    color: var(--grey-dark);
  }

  & > .member__name {
    font-weight: 500;

    & > .member__name_clickable {
      font-weight: 400;
      transition: 0.5s;
    }

    & > .member__name_clickable:hover {
      color: var(--blue-light);
      transition: 0.5s;
    }

    & > .member__name_clickable:active {
      color: var(--blue-dark);
      transition: 0.5s;
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .members__title {
    @include fonts_mobile.mobile-font-bold-16px-line32;

    padding: 0;
    margin: 0 8px;
  }

  .members__button-list {
    margin: 0 8px;
  }

  .member__block,
  .member__block:disabled {
    display: flex;
    align-items: flex-start;
    padding: 0;
    margin: 0;
    width: calc(50% - 10px);

    & > .members__mobile-container {
      & > .members__mobile-container-name {
        font-weight: 500;
        transition: 0.5s;
      }

      & > .members__mobile-container-name:hover {
        color: var(--blue-light);
        transition: 0.5s;
      }

      & > .members__mobile-container-name:active {
        color: var(--blue-dark);
        transition: 0.5s;
      }
    }
  }

  .member__block-initiator {
    display: inline;
    width: unset;
    margin: 12px 8px;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .member__block-initiator:hover {
    cursor: pointer;
  }

  .member__role {
    vertical-align: middle;
    color: var(--grey-main);

    @include fonts_mobile.mobile-font-regular-size12-line14;
  }
}

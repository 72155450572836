@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.file-preview__container {
  position: relative;
  box-sizing: border-box;
  padding: 0.7em 1.3em;
  display: flex;
  align-items: center;
  gap: 1em;
  border: 1px solid var(--blue-main);
  border-radius: 5px;

  &:hover {
    @media (hover: hover) {
      cursor: pointer;

      .file-preview__name {
        color: var(--blue-dark);
      }

      .file-preview__docs-icon > path {
        stroke: var(--blue-dark);
      }

      & > .file-preview__remove-button {
        & > svg > path {
          fill: var(--blue-main);
        }
      }
    }
  }

  &:active {
    & > .file-preview__remove-button {
      & > svg > path {
        fill: var(--blue-dark);
      }
    }
  }

  & > .file-preview__docs-icon {
    margin-right: 1vw;
    width: 24px;
    height: 24px;
  }

  & > .file-preview__remove-button {
    display: inline-block;
    cursor: pointer;
    padding: 0;

    & > svg {
      width: 16px;
      height: 16px;
    }

    & > svg > path {
      fill: var(--secondary-dark);
    }
  }
}

.file-preview__doc {
  display: flex;
  align-items: center;
  gap: 1em;
  padding: 0;
  text-decoration: none;
  max-width: calc(100% - 1.2em);
}

.file-preview__name {
  color: var(--blue-main);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  @include fonts.font-size(14px);

  line-height: 120%;
}

@media screen and (max-width: common.$mobile-w) {
  .file-preview__container {
    justify-content: space-between;
  }

  .file-preview__doc {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0;
    text-decoration: none;
    max-width: calc(100% - 26px);
  }

  .file-preview__name {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }
}

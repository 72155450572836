@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.profile {
  color: var(--grey-dark);

  &__user-card {
    margin-top: 4.3vh;
  }

  &__name {
    @include fonts.font-medium-size20-line24;
    @include fonts_mobile.mobile-font-bold-16px-line32;

    margin: 46px 0 0;
  }

  &__contact {
    margin-top: 2.6vh;
    display: flex;
    align-items: center;
    gap: 16px;

    &-icon path {
      stroke: var(--blue-main);
    }

    &-value {
      @include fonts.font-regular-small;
      @include fonts_mobile.mobile-font-regular-size16-line22;
    }
  }
}

.profile-no-auth {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  height: 100%;
  overflow: auto;

  &__header {
    color: var(--grey-dark);
    margin: 24px 0 0;

    @include fonts_mobile.mobile-font-bold-20px;
  }

  &__description {
    margin: 12px 0 0;

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  &__logo {
    margin: 27px auto 13px;
    width: 62vw;
  }

  &__button {
    margin-top: 14px;
    width: 100%;
    height: common.get-mobile-vh(7);
    min-height: 35px;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .profile {
    &__title {
      display: none;
    }

    &__name {
      margin: 3px 0 0 17px;
    }

    &__contact {
      margin-top: 10px;
      margin-left: 17px;
    }

    &__user-card {
      margin: 0;
      padding-top: 0;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.creation__buttons {
  display: flex;
  justify-content: space-between;
  max-width: 63%;
  padding: 2.9% 0 3.64%;
}

.creation__button {
  padding: 1.3% 6.3%;
}

.creation__button-previous {
  background: var(--main-white);
}

@media screen and (max-width: common.$mobile-w) {
  .creation__button {
    flex: 1;
    padding: 3.3% 6.3%;

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .creation__buttons {
    gap: 2.2%;
    padding: 2% 0;
    max-width: 100%;
    width: 96%;
    z-index: 100;
    position: absolute;
    bottom: 0;
  }
}

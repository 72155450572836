@use "@platform-for-public-places/components-library/styles/fonts";
@use "@platform-for-public-places/components-library/styles/fonts_mobile";
@use "@platform-for-public-places/components-library/styles/common";
@use "@platform-for-public-places/components-library/styles/scroll";

.search-dropdown__container {
  display: flex;
  gap: 2.1%;
  padding: 2px 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.search-dropdown__delete-button {
  padding: 0;
  align-items: normal;
  margin-top: 1.3%;
}

.search-dropdown__input {
  @include fonts.font-regular-small;

  width: 25vw;

  &:hover > .search-dropdown__paginate {
    & > [class*="-control"] {
      @media (hover: hover) {
        border: 1px solid var(--secondary-dark);
      }
    }
  }

  & > .search-dropdown__paginate {
    color: var(--grey-dark);

    & > [class*="-control"] {
      @include fonts.font-regular-small;

      padding: 0.25em 1.25em;
      border: 1px solid var(--grey-light);
      border-radius: 5px;
      color: var(--grey-dark);
      box-shadow: none;

      & > [class*="-ValueContainer"] {
        padding: 0;

        & > [class*="-singleValue"] {
          color: var(--grey-dark);
        }

        & > [class*="-placeholder"] {
          color: var(--grey-light);
        }
      }

      & > div > div > svg,
      & > [class*="-IndicatorsContainer"] > [class*="-indicatorContainer"] > [class*="-Svg"] {
        width: 1.5em;
        height: 1.5em;
        transition: 0.5s;
        color: var(--grey-dark);
      }

      & > [class*="-IndicatorsContainer"] > [class*="-indicatorSeparator"] {
        display: none;
      }
    }
  }

  & > .search-dropdown__paginate_selected {
    & > [class*="-control"] {
      @include fonts.font-regular-small;

      padding: 0.25em 1.25em;
      border: 1px solid var(--secondary-dark);
      border-bottom-color: transparent;
      border-radius: 5px 5px 0 0;
      color: var(--grey-dark);
      box-shadow: none;

      & > [class*="-ValueContainer"] {
        padding: 0;

        & > [class*="-singleValue"] {
          color: var(--grey-dark);
        }

        & > [class*="-placeholder"] {
          color: var(--grey-light);
        }
      }

      & > div > div > svg,
      & > [class*="-IndicatorsContainer"] > [class*="-indicatorContainer"] > [class*="-Svg"] {
        width: 1.5em;
        height: 1.5em;
        transition: 0.5s;
        transform: rotate(180deg);
        color: var(--grey-dark);
      }

      & > [class*="-IndicatorsContainer"] > [class*="-indicatorSeparator"] {
        display: none;
      }
    }

    & > [class*="-menu"] {
      border-radius: 0 0 5px 5px;
      box-shadow: none;
      margin: 0;
      border: 1px solid var(--secondary-dark);
      border-top: none;
      overflow: hidden;
    }
  }
}

.selector__error {
  & > [class*="-container"] > [class*="-control"] {
    transition: 1s;
    border: 1px solid var(--error-main);
  }

  & > .selector__control {
    transition: 1s;
    border: 1px solid var(--error-main);
  }
}

.selector__error-text {
  @include fonts.font-regular-small;

  transition: 0.5s;
  color: var(--error-main);
  opacity: 0;
  height: 0;
  margin: 0;
  overflow: hidden;
}

.selector__error-text--visible {
  transition: 0.5s;
  height: auto;
  margin: 8px 0;
}

.selector__error-text--error {
  transition: 0.5s;
  opacity: 1;
}

div.search-dropdown__user-list {
  @include scroll.scroll;

  position: relative;
  overflow: hidden auto;
  padding: 0;
}

.search-dropdown__user-card {
  display: flex;
  transition: 0.3s;
  height: auto;
  width: 100%;
  cursor: pointer;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;

  &:hover {
    @media (hover: hover) {
      background: var(--secondary-light);
    }
  }
}

.search-dropdown__user-main-info {
  @include fonts.font-regular-small;

  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 0.8vw;
}

.search-dropdown__user-name {
  color: var(--grey-dark);
  max-width: 14vw;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.search-dropdown__user-tag {
  @include fonts.font-regular-small14;

  margin: 0;
  font-style: italic;
  color: var(--blue-dark);
}

.search-dropdown__user-tags {
  margin-right: 3%;
}

.search-dropdown__empty-data {
  @include fonts.font-size(14px);

  line-height: 140%;
  font-style: italic;
  margin-left: 1.36em;
  color: var(--grey-main);
}

@media screen and (max-width: common.$mobile-w) {
  .search-dropdown__input {
    width: auto;

    & > [class*="container"] {
      & > [class*="-control"] {
        @include fonts_mobile.mobile-font-regular-14px-line20;
      }
    }
  }

  .search-dropdown__delete-button {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .search-dropdown__type-delete {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .search-dropdown__container {
    flex-direction: column;
    gap: 12px;
  }

  .search-dropdown__user-main-info > .user-card__avatar {
    width: 9.5vw;
    height: 9.5vw;
  }

  .search-dropdown__user-name {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    max-width: 64vw;
  }

  .search-dropdown__user-tag {
    @include fonts_mobile.mobile-font-regular-size12-line17;
  }

  .search-dropdown__container--margin:last-child {
    margin-bottom: 80%;
  }
}

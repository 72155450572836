@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.digest {
  position: relative;
  margin-bottom: 5.3%;
  padding: 0 3%;

  &:last-child {
    padding-bottom: 1vh;
  }

  &:active {
    background-color: var(--secondary-light);
    -webkit-tap-highlight-color: var(--secondary-light);
  }

  &:focus {
    background-color: var(--secondary-light);
    -webkit-tap-highlight-color: var(--secondary-light);
  }
}

.digest__marker {
  position: absolute;
  top: 3px;
  width: 16px;
  height: 16px;
  fill: transparent;
  transition: 0.5s;

  @include fonts.font-regular-small;

  & > circle {
    r: 0.5em;
    cy: 0.5em;
    cx: 0.5em;
  }
}

.digest__marker--active {
  fill: var(--secondary-dark);
}

.digest__button {
  display: block;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.digest__title,
.digest__date {
  @include fonts.font-regular-small;

  margin: 0;
  color: var(--grey-main);
  margin-left: 28px;
}

.digest__title {
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: 0.5s;
}

.digest__title--active {
  color: var(--grey-dark);
}

.digest__date {
  display: block;
  margin-top: 4px;
}

@media screen and (max-width: common.$mobile-w) {
  .digest {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    &__marker {
      display: none;
    }

    &__button {
      display: flex;
      justify-content: space-between;
    }

    &__title,
    &__date {
      margin: 0;
    }

    &__title {
      @include fonts_mobile.mobile-font-bold-14px-line32;

      color: var(--grey-dark);
    }

    &__date {
      @include fonts_mobile.mobile-font-regular-14px-line32;
    }
  }
}

.members-outgoing-table__header {
  top: -7.23vh;
  padding: 2vh 0 3vh;
}

.members-outgoing-table__columns {
  grid-template-columns:
    minmax(7.4vw, 11fr)
    minmax(19.6vw, 29fr)
    minmax(11.5vw, 17fr)
    minmax(30vw, 43fr);
}

.members-outgoing-table__body {
  margin-top: 0;
  gap: 2.5vh;
}

.recurrent-user-payments-table__header {
  top: -7.23vh;
  padding: 2vh 0 3vh;
}

.recurrent-user-payments__table-columns {
  grid-template-columns:
    minmax(19vw, 25fr)
    minmax(8vw, 13fr)
    minmax(10vw, 21fr)
    minmax(8vw, 11fr)
    minmax(5vw, 9fr)
    minmax(12vw, 26fr);
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.recovery-password__title {
  text-align: center;
  margin: 0 0 0.5em;
}

.recovery-password__description {
  text-align: center;
  margin: 0 auto 2.5em;
  width: 70%;
}

.recovery-password__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.recovery-password__input {
  margin: 0 0 2em;
  min-width: 19vw;
  border-width: 2px;
  padding: 3.6% 2.8% 3.6% 5.5%;

  & > input {
    padding: 0;
  }
}

.recovery-password__button {
  width: 50%;

  @include fonts.font-medium-small;
}

@media screen and (max-width: common.$mobile-w) {
  .recovery-password {
    border: none;
    padding: 0;
    margin: 0 8px;
  }

  .recovery-password__description {
    width: 55%;
  }

  .recovery-password__button {
    width: 100%;

    @include fonts_mobile.mobile-font-medium-size16-line19;
  }
}

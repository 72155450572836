@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.diary {
  display: flex;
  gap: 8.9%;
  height: 100%;
}

.edit-layout__gate--diary {
  padding-top: 0;
  padding-bottom: 0;
  height: 100%;
}

.digest-list__loading-trigger {
  width: 100%;
  min-height: 30px;
  background: transparent;
}

.diary__content {
  @include scroll.scroll-none;

  padding-top: 3.6%;
  min-width: 63%;
  height: 100%;
  overflow-y: scroll;
  box-sizing: border-box;
}

.diary__side {
  @include scroll.scroll-none;

  position: static;
  height: 100%;
  padding-top: 71px;
  padding-right: 65px;
  width: 22.6%;
  flex-shrink: 0;
  overflow: scroll;
  box-sizing: border-box;
}

.diary__entries {
  margin-top: 2.5%;
  padding-left: 1.3%;
}

.diary__side .side__title {
  @include fonts.font-medium-size20-line24;

  margin: 0;
  color: var(--grey-dark);
}

.diary__side .side__entries {
  margin-top: 36px;
}

.diary-entry__header {
  gap: 0;
}

@media screen and (max-width: common.$mobile-w) {
  .diary {
    flex-direction: column;
    gap: 0;
    padding: 0 8px;
  }

  .diary__description {
    color: var(--grey-dark);
    margin: 0;

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }

  .diary__digest-dropdown {
    display: contents;
    margin-top: 0;
    padding: 0;

    &--visible {
      .diary__digest-controls svg {
        transform: rotate(180deg);
      }

      .diary__digest-list {
        margin-top: 2.5vh;
        height: unset;

        @include scroll.scroll;
      }
    }
  }

  .diary__digest-controls {
    margin-top: 2vh;
    width: 100%;
    display: flex;
    justify-content: space-between;

    h4 {
      margin: 0;

      @include fonts_mobile.mobile-font-bold-14px-line20;
    }

    svg {
      transition: 0.3s;

      path {
        fill: var(--blue-main);
      }
    }
  }

  .diary__digest-list {
    display: flex;
    flex-direction: column;
    row-gap: 1.5vh;
    height: 0;
    max-height: 65vh;
    margin-bottom: 3vh;
    padding-right: 2vw;
    overflow: hidden scroll;
  }

  .diary__content {
    padding: 0 8px;

    .diary-entry__header {
      flex-direction: column;
      gap: 8px;
      margin-left: 18px;
    }

    .diary-entry__article {
      margin: 6px 0 0 18px;
    }

    &--visible {
      height: 0;
    }
  }

  .diary__create-button {
    display: flex;
    min-height: 46px;
    border-radius: 10px;
    margin-bottom: 8px;
    position: fixed;
    width: 96%;
    bottom: 7%;
    justify-content: center;

    @include fonts_mobile.mobile-font-medium-size14-line17;

    svg path {
      stroke: var(--main-white);
    }

    &:hover {
      svg path {
        stroke: var(--main-white);
      }
    }

    &:active {
      svg path {
        stroke: var(--main-white);
      }
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.status-recurrent-payment {
  text-align: left;

  &__project-name {
    margin: 0 0 2.5%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  &__payment-details_bold {
    @include fonts_mobile.mobile-font-bold-12px-line17;
  }
}

.sheet-recurrent-payment {
  padding: 0 3.2%;

  & span,
  & a {
    white-space: pre-wrap;
    word-wrap: break-word;
    width: fit-content;
    height: fit-content;
  }

  &__project-link {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    color: var(--grey-dark);
    transition: 0.5s;

    &:hover {
      @media (hover: hover) {
        color: var(--blue-light);
      }
    }

    &:active {
      color: var(--blue-main);
    }
  }

  &__status {
    margin: 0;

    @include fonts_mobile.mobile-font-regular-14px-line20;

    &_active {
      color: var(--green-main);
    }

    &_notActive {
      color: var(--error-main);
    }
  }

  &__payment-details {
    margin-top: 4.5%;

    & > p {
      margin: 0;
      margin-bottom: 1.2%;
    }

    & > p:last-child {
      margin: 0;
    }

    @include fonts_mobile.mobile-font-regular-size12-line17;

    &_bold {
      @include fonts_mobile.mobile-font-bold-12px-line20;
    }
  }

  &__action {
    margin: 5% 0;
  }

  &__action-button {
    display: flex;
    align-items: center;
    padding: 0;

    @include fonts_mobile.mobile-font-medium-size16-line22;
  }
}

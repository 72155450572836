@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.all-user-payments-info {
  align-items: center;

  &__empty-payments-message {
    @include fonts.font-regular-size18-line24;

    margin-top: 1em;
  }

  &__loader {
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);

    &_mobile {
      left: 50%;
    }

    &_desktop {
      left: 60%;
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .all-user-payments-info__empty-payments-message {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.user-total-donation {
  &__total-donation {
    margin: 0;
    display: flex;
    align-items: center;

    @include fonts.font-regular-size16-line32;
  }

  &__total-donation-amount {
    color: var(--green-main);
    margin-left: 5px;

    @include fonts.font-medium-size20-line24;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .user-total-donation {
    margin: 0 0 3.5%;
    padding-bottom: 1%;
    border-bottom: 1px solid var(--blue-light);

    &__header {
      display: flex;
      justify-content: space-between;
    }

    &__total-donation {
      display: flex;
      justify-content: space-between;

      @include fonts_mobile.mobile-font-regular-14px-line20;
    }

    &__total-donation-amount {
      @include fonts_mobile.mobile-font-medium-size16-line22;
    }
  }
}

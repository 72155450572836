@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.cadaster-information {
  display: flex;
  flex-direction: column;
  padding: 0.7vh 0 3vh 1.1vw;
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  position: relative;
  margin-bottom: 16px;
  box-sizing: border-box;
  max-height: 7.2vh;
  overflow: hidden;
  transition: max-height 1s;
}

.cadaster-information--open {
  max-height: 650px;
}

.cadaster-information__short-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cadaster-information__collapse-button {
  cursor: pointer;
  margin: 1.9vh;

  @include fonts.font-medium-small14;

  & > svg {
    width: 2em;
    height: 2em;
    transition: 1s;

    & > path {
      fill: var(--grey-dark);
    }
  }
}

.cadaster-information--open .cadaster-information__collapse-button svg {
  transform: rotate(-180deg);
}

.cadaster-information__key {
  margin: 10px 0 0;

  &:first-child {
    margin: 0;
  }

  @include fonts.font-medium-small14;
}

.cadaster-information__short-info > .cadaster-information__key {
  margin: 2.23vh 0 0;
}

.cadaster-information__value {
  font-weight: 400;
}

.cadaster-information__full-info {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

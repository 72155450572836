@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.profile-pane {
  position: absolute;
  top: 120%;
  right: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: 11vw;
  padding: 2.5vh 1.5vw;
  background: var(--main-white);
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  z-index: 999999;
}

.profile-pane--overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  opacity: 0.5;
  z-index: 999998;
}

.profile-pane__username {
  font-weight: 500;
  color: var(--grey-dark);
  margin: 0;
  text-align: start;
  word-wrap: break-word;

  @include fonts.font-size(16px);
}

.profile-pane__profile {
  width: fit-content;
  color: var(--grey-dark);
  text-decoration: none;
  font-weight: 400;
  padding: 0;

  @include fonts.font-size(16px);

  &:hover {
    @media (hover: hover) {
      color: var(--blue-main);
    }
  }

  &:active {
    color: var(--blue-dark);
  }
}

.profile-pane__logout {
  display: flex;
  align-items: center;
  gap: 5px;
  width: fit-content;
  color: var(--error-main);
  text-decoration: none;
  padding: 0;

  @include fonts.font-size(16px);

  &:hover {
    @media (hover: hover) {
      color: var(--error-light);

      svg path {
        stroke: var(--error-light);
      }
    }
  }

  &:active {
    color: var(--error-dark);

    svg path {
      stroke: var(--error-dark);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    transform: rotate(180deg);

    path {
      stroke: var(--error-main);
      stroke-width: 2px;
      transition: 0.5s;
    }
  }
}

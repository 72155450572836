.spinner {
  & svg {
    width: 100%;
    height: 100%;
  }
}

.spinner--fixed {
  width: 5em;
}

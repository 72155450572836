@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.header {
  display: flex;
  position: relative;
  height: 9.2%;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  background: var(--main-white);
  padding: 0 40px;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 1px 5px rgb(55 54 107 / 13%);
  z-index: 999998;
}

.fixed-header {
  position: fixed;
  width: 100%;
}

.header__icon {
  margin: auto;
  width: 1.5em;
  height: 1.5em;
  padding: 0.5vh 0.5vw 0;

  &--profile {
    &:hover path {
      @media (hover: hover) {
        stroke: var(--blue-light);
      }
    }

    &:active path {
      stroke: var(--blue-main);
    }

    path {
      transition: 0.5s;
    }
  }
}

.header__links {
  @include fonts.font-size(10px);

  display: flex;
  align-content: space-between;
  justify-content: space-between;
  gap: 3em;
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    margin: auto;

    &:first-of-type {
      margin-right: 4.2em;
    }
  }
}

.header__link {
  @include fonts.font-size(16px);

  display: flex;
  align-items: center;
  width: auto;
  line-height: 140%;
  align-self: center;
  color: var(--grey-dark);
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: 0.5s linear;

  &:hover {
    @media (hover: hover) {
      color: var(--green-cold);
    }
  }

  &.active {
    font-weight: 400;
    color: var(--green-main);
    text-shadow: 1px 0 0 var(--green-main);
  }

  &--profile {
    padding: 0;

    svg circle {
      transition: 0.5s;
    }

    :hover {
      @media (hover: hover) {
        & svg circle:first-child {
          fill: var(--grey-light);
        }

        & svg circle:nth-child(3) {
          stroke: var(--secondary-dark);
        }
      }
    }
  }

  &--profile--active {
    svg circle {
      &:nth-child(1) {
        fill: var(--grey-main);
      }

      &:nth-child(3) {
        stroke: var(--secondary-dark);
      }
    }

    &:hover svg circle {
      @media (hover: hover) {
        &:nth-child(1) {
          fill: var(--grey-main);
        }

        &:nth-child(3) {
          stroke: var(--secondary-dark);
        }
      }
    }
  }
}

.header__link-icon {
  fill: var(--grey-dark);
  transition: 0.5s linear;
  margin-right: 8px;

  :hover > & {
    @media (hover: hover) {
      fill: var(--green-cold);
    }
  }
}

.header__logo {
  width: 3.5em;
}

.header__profile-wrapper {
  position: relative;
  padding: 0;

  @include fonts.font-regular-small;
}

.header__buttons {
  display: flex;
  justify-content: space-between;
  gap: 1.5em;
  align-items: center;

  &:nth-child(0) > svg {
    height: 2em;
  }

  &:nth-child(1) {
    background: var(--error-dark);
  }

  &:nth-child(1) > svg {
    height: 2.1em;
  }

  .header__link--sign-in {
    @include fonts.font-medium-small;

    align-items: center;
    justify-content: center;
    width: unset;
    padding: 0 8px;
  }
}

.header__create {
  height: 3em;
  width: 12.5em;
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

@font-face {
  font-family: Rubik;
  src:
    local(rubik),
    url("../node_modules/@platform-for-public-places/components-library/styles/fonts/Rubik-Medium.ttf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: Rubik;
  src:
    local(rubik),
    url("../node_modules/@platform-for-public-places/components-library/styles/fonts/Rubik-Regular.ttf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: Raleway;
  src:
    local(raleway),
    url("../node_modules/@platform-for-public-places/components-library/styles/fonts/Raleway-Variable.ttf") format("opentype-variations");
  font-weight: 400 500 600 700 800;
}

body {
  margin: 0;
  font-family:
    Rubik,
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: none;
  overflow-y: hidden;
}

#root {
  height: common.get-mobile-vh(100);
  overflow-x: hidden;
  overflow-y: auto;

  @include scroll.scroll;
}

body::-webkit-scrollbar {
  display: none;
}

body h2 {
  font-weight: 500;
  font-size: 32px;
}

body h1 {
  font-weight: 500;
}

.sr-only {
  display: none;
}

button {
  border: none;
  margin: 0;
  padding: 0;
  width: auto;
  overflow: visible;
  background: transparent;
  color: inherit;
  font-family: Rubik, sans-serif;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  appearance: none;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

main {
  height: 90.8%;
}

body > reach-portal > div[data-rsbs-is-blocking="false"] {
  position: relative;
  z-index: 100;
}

body > reach-portal > div[data-rsbs-is-blocking="true"] {
  position: relative;
  z-index: 100001;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

input,
textarea {
  font-family: Rubik, sans-serif;
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.forgot-password__back {
  position: absolute;
  top: 2.2em;
  left: 1em;
  margin-left: 2.1%;
  border: 0;
  padding: 0;
  color: var(--grey-dark);

  @include fonts.font-medium-small;

  & > svg {
    width: 32px;
    height: 32px;
  }

  & > svg > path {
    width: 32px;
    height: 32px;
    fill: var(--grey-dark);
    stroke: none;
    transform-origin: center;
    transform: rotate(90deg);
  }

  & > span {
    vertical-align: 4px;
    margin-left: 0;
  }

  &:hover {
    @media (hover: hover) {
      & > svg > path {
        fill: var(--blue-light);
        stroke: none;
      }
    }
  }
}

.forgot-password__title {
  text-align: center;
  margin: 0 0 0.5em;
}

.forgot-password__description {
  text-align: center;
  margin: 0 auto 2.5em;
  width: 70%;
}

.forgot-password__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.forgot-password__input {
  margin: 0 0 2em;
  padding: 3.6% 2.8% 3.6% 5.5%;
  max-width: 6vw;
}

.forgot-password__button {
  width: 50%;
}

@media screen and (max-width: common.$mobile-w) {
  .forgot-password {
    border: none;
    padding: 0;
    margin: 0 8px;
  }

  .forgot-password__description {
    @include fonts_mobile.mobile-font-regular-16px-line24;
  }

  .forgot-password__input {
    max-width: 100%;
  }

  .forgot-password__button {
    width: 100%;
  }
}

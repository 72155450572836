@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.annual-report-docs__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.annual-report-docs {
  display: grid;
  gap: 1em 2em;
  grid-auto-flow: column;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(6, 1fr);
}

.annual-report-docs__document {
  @include fonts.font-medium-medium18;

  text-decoration: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: var(--blue-main);
  gap: 0.625em;

  &:hover {
    @media (hover: hover) {
      color: var(--blue-light);

      & > .annual-report-docs__docs-icon * {
        stroke: var(--blue-light);
      }
    }
  }

  &:active {
    color: var(--blue-dark);

    & > .annual-report-docs__docs-icon * {
      stroke: var(--blue-dark);
    }
  }
}

.annual-report-docs__docs-icon {
  min-width: 2em;
  min-height: 2em;
}

@media screen and (max-width: common.$hd-w) and (min-width: common.$mobile-w) {
  .annual-report-docs {
    gap: 1em 0.5em;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .annual-report-docs__docs-icon {
    width: 24px;
    height: 24px;
  }
}

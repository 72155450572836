@use "@platform-for-public-places/components-library/styles/_common.scss";

.picture-modal__container {
  display: flex;
  flex-direction: column;
  z-index: 1;
  align-items: flex-end;
}

.picture-modal__picture {
  max-height: 90vh;
  max-width: 95vh;
}

.picture-modal__close {
  z-index: 2;
  position: absolute;
  cursor: pointer;
}

.button-round__icon {
  stroke: var(--blue-main);
}

.picture-modal {
  justify-content: space-between;

  .button-round {
    border-radius: 100%;
    background-color: var(--main-white);
    padding: 12px;
    line-height: 0;
    z-index: 1;
    margin: 12px;

    &:hover {
      @media (hover: hover) {
        background-color: var(--secondary-main);
      }
    }

    &:active {
      background-color: var(--blue-main);

      & .button-round__icon {
        stroke: var(--main-white);
      }
    }
  }

  .button-round--hiden {
    visibility: hidden;
  }
}

.button-round__icon_left {
  transform: rotate(180deg);
}

.icon-cross {
  fill: var(--main-white);
}

.picture-modal__mobile-carousel {
  max-height: common.get-mobile-vh(90);
}

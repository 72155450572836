@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.typography {
  margin: 0;
}

.typography__body2 {
  @include fonts.font-size(16px);
}

.typography__body3 {
  @include fonts.font-size(14px);
}

.mobile-typography__body3 {
  @include fonts_mobile.mobile-font-regular-14px-line20;
}

.typography__h5 {
  @include fonts.font-size(20px);

  font-weight: 500;
}

.typography__heading-h6 {
  @include fonts.font-size(16px);

  font-weight: 500;
}

.typography__heading-h5 {
  @include fonts.font-size(18px);

  font-weight: 500;
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.area-selection {
  max-width: 63%;

  @media (max-width: (common.$hd-w)) {
    max-width: 80%;
  }
}

.area-selection__footer {
  max-width: 63%;
}

.area-selection__stage-header {
  margin-bottom: 1.9%;
}

.area-selection__select-button {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 1.1% 1.8%;
  margin: 1% 0 5%;
}

.area-selection__area-info-header {
  color: var(--grey);
  margin: 0 0 26px;

  @include fonts.font-medium-size20-line24;
}

.area-selection__info-row {
  display: grid;
  grid-template-columns: 2fr 1fr 2fr;
  gap: 12px;
}

.area-selection__area-stub {
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  border: 1px solid var(--secondary-dark);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  overflow: hidden;
}

.area-selection__area-map {
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  border: 1px solid var(--secondary-dark);
  overflow: hidden;
  margin-bottom: auto;
}

.area-selection__map {
  width: 100%;
  height: 100%;
  z-index: 1;
}

.area-selection__area-stub-text {
  color: var(--secondary-dark);
  margin: 6% 0 0 9%;

  @include fonts.font-medium-medium18;
}

.area-selection__area-stub-img {
  display: block;
  height: 60%;
  aspect-ratio: 2/1;
  margin-top: 12px;
  align-self: center;
}

.area-selection__cadasters {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow: hidden auto;
  aspect-ratio: 1/2;

  @include scroll.scroll;
}

.area-selection__cadaster {
  padding: 1.2em;
  border-radius: 10px;
  border: 1px solid var(--secondary-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  transition: 0.5s;
  box-sizing: border-box;
  cursor: pointer;
  flex-shrink: 0;

  @include fonts.font-medium-small14;

  & > svg {
    height: 1.7em;
    width: 1.7em;
  }

  & > p {
    margin: 0;
  }

  &--selected {
    background: var(--secondary-dark);

    & > svg {
      transform: rotate(90deg);
      transition: 0.5s;

      & > path {
        fill: var(--grey-dark);
      }
    }
  }

  &-text--regular {
    font-weight: 400;
  }

  &-info {
    border-radius: 10px;
    border: 1px solid var(--secondary-dark);
    display: flex;
    flex-direction: column;
    padding: 20px;
    aspect-ratio: 1 /1;
    box-sizing: border-box;
    overflow-x: auto;

    @include scroll.scroll;
  }

  &-chevron-icon {
    transform: rotate(270deg);
    fill: var(--secondary-dark);
  }
}

.area-selection__cadaster_pkk-error {
  padding: 0.8em 1.2em;

  & svg {
    height: 2.3em;
    width: 2.3em;

    & > path {
      stroke: var(--warning-main);
    }
  }
}

.area-selection__no-cadaster-text {
  color: var(--secondary-dark);
  margin: 0;

  @include fonts.font-medium-small14;
}

.area-selection__no-cadaster::after {
  visibility: visible;
  border-color: var(--secondary-dark);
}

.area-selection__no-cadaster-info-text {
  color: var(--secondary-dark);
  margin: 0 0 0 10px;

  @include fonts.font-medium-medium18;

  &::after {
    content: "";
    display: block;
    background: url("./img/info-example.svg") center / contain no-repeat;
    width: 100%;
    aspect-ratio: 13/7;
    margin-top: 12%;
  }
}

.area-selection__subtitle {
  color: var(--grey);
  margin: 2rem 0 1.125rem;

  @include fonts.font-regular-size18-line24;
}

.area-selection__marker-icon {
  stroke: var(--blue-main);
  stroke-width: 2px;
  margin-right: 10px;
}

.area-selection__attention-text {
  color: var(--grey-dark);
  margin: 2.7% 0 2%;

  @include fonts.font-regular-small;

  & > a {
    color: var(--green-main);
    font-weight: 500;
  }
}

@media (max-width: common.$mobile-w) {
  .area-selection {
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 4em;
  }

  .area-selection__footer {
    max-width: unset;
  }

  .edit-layout__gate {
    .area-selection {
      padding: 0 8px;
    }

    .area-selection__stage-header__header-text {
      display: none;
    }
  }

  .area-selection__area-info-header {
    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .area-selection__area-stub-text {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .area-selection__select-button {
    padding: 2.1% 1.8%;
    justify-content: center;

    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .area-selection__info-row {
    grid-template-columns: 5fr 1.5fr;
    gap: 1%;
  }

  .area-selection__cadaster {
    border-right-color: transparent;
    border-radius: 10px 0 0 10px;
    justify-content: unset;
    gap: 6px;
    padding-left: 13px;
    background: var(--secondary-main);

    & > svg {
      height: 2em;
      width: 2em;
      transform: rotate(90deg);
      transition: 0.3s;

      & > path {
        fill: var(--grey-dark);
      }
    }

    & .area-selection__cadaster-icon--pkk-error {
      transform: rotate(0deg);
      height: 2.9em;
      width: 2.9em;

      & > path {
        stroke: var(--grey-main);
        fill: none;
      }
    }

    &--selected {
      background: var(--secondary-dark);
    }

    &:active {
      -webkit-tap-highlight-color: transparent;
    }

    &--selected > svg {
      transform: rotate(-90deg);
    }

    &-icon {
      order: -1;
    }

    &-field-name {
      display: none;
    }

    &-text--regular {
      @include fonts_mobile.mobile-font-regular-size12-line14;
    }

    &-info {
      padding: 12px;
      aspect-ratio: 1/1;

      @include scroll.scroll-none;
    }
  }

  .area-selection__cadasters {
    aspect-ratio: unset;
    max-height: 48vh;
    position: absolute;
    right: 0;

    @include scroll.scroll-none;
  }

  .cadaster-information__key {
    @include fonts_mobile.mobile-font-bold-12px;
  }
}

.creation-area__subtitle {
  color: var(--grey);
  margin: 1.5rem 0 1.125rem;

  @include fonts.font-regular-size18-line24;
}

.creation-area__input-wrapper {
  max-width: 30%;
}

.creation-area__input {
  padding: 0.75rem 1.25rem;
}

.creation-area__error {
  display: inline-block;
  margin-top: 0.5rem;
  color: var(--error-main);
  opacity: 1;
  transition: 0.5s;

  @include fonts.font-regular-small;

  &--hidden {
    margin: 2px 0 8px;
    color: var(--error-main);
    opacity: 0;
    transition: 0.5s;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .creation-area__subtitle {
    @include fonts_mobile.mobile-font-regular-14px;

    margin: 1.25rem 0 0.5rem;
  }

  .creation-area__input-wrapper {
    max-width: 100%;
    margin-bottom: 3em;
  }

  .creation-area__error {
    @include fonts_mobile.mobile-font-regular-14px;

    margin: 4px 0 0;
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.reporting-desktop {
  display: flex;
  padding: 4em 2.5em 0 5.75em;
  color: var(--grey-dark);
  height: fit-content;
}

.reporting-desktop__reporting-sidebar {
  margin-top: 5em;
  width: 15%;
  height: fit-content;
}

.reporting-sidebar__title {
  @include fonts.font-medium-medium18;

  margin: 0 0 1em;
}

.reporting-sidebar__digest {
  @include scroll.scroll;

  height: 65vh;
  overflow-y: auto;
}

.reporting-desktop__content {
  width: 85%;
}

.reporting-desktop__title {
  @include fonts.font-medium-size32-line36;

  margin: 0 0 1.5em;
}

.reporting-desktop__reports {
  @include scroll.scroll;

  height: 73vh;
  overflow-y: auto;
}

.reporting-desktop__loading-trigger {
  width: 100%;
  min-height: 30px;
  background: transparent;
}

.reporting-desktop__annual-report {
  padding-right: 9.375em;
  margin-bottom: 4em;
}

.annual-report__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.annual-report__header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1em;
  border-bottom: 1px solid var(--grey-main);
}

.annual-report__year,
.annual-report__total-count {
  @include fonts.font-medium-size20-line24;

  margin: 0;
}

.annual-report__main {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.annual-report__chart {
  margin-right: 2em;
  width: 65%;
}

.reporting-desktop__annual_report_docs {
  margin-top: 2em;
  width: 35%;
}

@media screen and (max-width: common.$hd-w) and (min-width: common.$mobile-w) {
  .annual-report__chart {
    margin-right: 0.5em;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.become-specialist-container {
  padding-right: 12vw;
  padding-bottom: 4vh;
}

.become-specialist-container__subtitle {
  margin: 2.1em 0 0;
  color: var(--grey-dark);

  @include fonts.font-medium-size20-line24;
}

.become-specialist-container__description {
  max-width: 948px;
  margin: 1.3em 0;
  color: var(--grey-dark);

  @include fonts.font-regular-size18-line24;
}

.become-specialist-container__prompt {
  color: var(--blue-main);

  @include fonts.font-medium-small14;
}

.become-specialist-container__request-card {
  margin-bottom: 2.4vh;
}

.become-specialist-form__photo-uploader {
  margin-bottom: 2.4vh;
  display: inline-block;
}

.become-specialist-form__element {
  margin-bottom: 2.6vh;

  &_required {
    color: var(--blue-main);
  }
}

.become-specialist-form__label {
  margin-bottom: 1.1vh;
  display: inline-block;
  position: relative;
  color: var(--grey-dark);

  @include fonts.font-regular-small14;
}

.become-specialist-form__label > .become-specialist-form__label--span {
  color: var(--blue-dark);
}

.become-specialist-form__label--error > .become-specialist-form__label--span-error {
  color: var(--error-main);
}

.become-specialist-form__label_portfolio-link {
  display: block;
  text-align: center;
  margin: 10px 0;
}

.become-specialist-form__input {
  margin: 0;
  padding: 0.7em 1.3em;
  color: var(--grey-dark);
}

.become-specialist-form__name {
  min-width: 380px;
  display: flex;
  flex-direction: column;
}

.become-specialist-form__name-input,
.become-specialist-form__name-input--error {
  width: 18vw;
}

.become-specialist-form__name-input--error,
.become-specialist-form__about-input--error {
  border-color: var(--error-main);
}

.become-specialist-form__portfolio-input {
  @include fonts.font-regular-small;

  .file-input__trigger {
    padding: 0.7em 1.3em;

    @include fonts.font-medium-small;
  }

  .file-input__text-field {
    padding: 0.7em 1.3em 0.7em 1.8em;
  }
}

.become-specialist-form__portfolio-preview-block {
  position: relative;
  box-sizing: border-box;
  padding: 0.7em 1.3em;
  display: flex;
  align-items: center;
  border: 1px solid var(--blue-main);
  border-radius: 5px;
  min-width: 18vw;
  max-width: 23vw;

  &:hover {
    @media (hover: hover) {
      cursor: pointer;

      .portfolio-preview-block__name {
        color: var(--blue-dark);
      }

      .portfolio-preview-block__docs-icon > path {
        stroke: var(--blue-dark);
      }

      & > .portfolio-preview-block__cross {
        & > svg > path {
          fill: var(--blue-main);
        }
      }
    }
  }

  &:active {
    & > .portfolio-preview-block__cross {
      & > svg > path {
        fill: var(--blue-dark);
      }
    }
  }

  & > .portfolio-preview-block__docs-icon {
    margin-right: 1vw;
    width: 24px;
    height: 24px;
  }

  & > .portfolio-preview-block__cross {
    display: inline-block;
    position: absolute;
    top: 17px;
    right: 15px;
    cursor: pointer;

    & > svg {
      width: 16px;
      height: 16px;
    }

    & > svg > path {
      fill: var(--secondary-dark);
    }
  }
}

.portfolio-preview-block__name {
  color: var(--blue-main);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 75%;

  @include fonts.font-regular-small14;
}

.become-specialist-form__social-links {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  justify-content: space-between;

  & > li {
    width: 30%;
    display: flex;
    align-items: center;
    gap: 10px;

    & > svg {
      fill: var(--blue-light);
    }
  }
}

.become-specialist-form__social-link-input {
  width: calc(100% - 46px);
  padding: 0.7em 1.3em;
}

.become-specialist-form__social-link-input_error {
  border-color: var(--error-main);
}

.become-specialist-form__phone-input {
  width: 15.5vw;
}

.become-specialist-form__phone-input-container {
  display: flex;
  align-items: center;
  gap: 10px;

  & svg {
    stroke: var(--blue-light);
    stroke-width: 2px;
  }
}

.become-specialist-form__specialist-type-list {
  margin: 0;
  padding-left: 0;
  list-style-type: none;
  display: flex;
  gap: 6vw;
}

.become-specialist-form__specialist-type-input {
  display: flex;
  align-items: center;
}

.become-specialist-form__specialist-label {
  display: flex;
  align-items: center;

  @include fonts.font-regular-small14;
}

.specialist-type-input__label_error > span {
  color: var(--error-main);
}

.specialist-type-input__checkbox {
  margin-right: 12px;
}

.specialist-type-input__checkbox_error {
  border-color: var(--error-main);
}

.become-specialist__error-text {
  color: var(--error-main);
  animation: fadeIn 0.5s;
  display: block;
  padding: 0.4em 0 0 0.4em;

  @include fonts.font-regular-very-small;
}

.specialist-type-input__icon {
  margin: 8px;
  width: 4em;
  height: 4em;
}

.become-specialist-form__specialist-type-title,
.become-specialist-form__specialist-type-description {
  color: var(--blue-dark);
}

.become-specialist-form__specialist-type-title {
  @include fonts.font-medium-small14;
}

.become-specialist-form__specialist-type-description {
  margin: 0;

  @include fonts.font-regular-small14;
}

.become-specialist-form__submit-wrapper {
  display: flex;
  gap: 10%;
  height: 5vh;
  justify-content: space-between;
  padding-right: 36%;

  @media (max-width: common.$hd-w) {
    padding-right: 24%;
  }
}

.become-specialist-form__submit-button {
  width: 23vw;
  min-width: 18vw;
  padding: 0.7em;

  @include fonts.font-medium-small;
}

.become-specialist-form__submit-description {
  color: var(--grey-dark);

  @include fonts.font-regular-very-small;
}

@media screen and (max-width: common.$mobile-w) {
  .become-specialist-container {
    padding: 0 2vw;

    & input {
      @include fonts_mobile.mobile-font-regular-size12-line14;
    }
  }

  .become-specialist-container__subtitle {
    margin: 0;

    @include fonts_mobile.mobile-font-bold-16px-line32;
  }

  .become-specialist-container__description {
    margin: 1vh 0 0;

    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .become-specialist-container__request-card {
    margin: 2vh 0 0;
  }

  .become-specialist-container__prompt {
    margin: 1.5vh 0 0;
  }

  .become-specialist-form {
    margin: 1.5vh 0 0;
  }

  .become-specialist-form__photo-uploader {
    margin: 0 0 2vh;
  }

  .become-specialist-form__label {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .become-specialist-form__input {
    width: calc(100% - 2.2%);
    height: 5vh;
    padding: 1.5vh 4vw;
  }

  .become-specialist-form__name {
    min-width: auto;
  }

  .become-specialist-form__portfolio-input {
    height: 5vh;
  }

  .become-specialist-form__portfolio-link-input--error {
    border-color: var(--error-main);
  }

  .become-specialist-form__portfolio-preview-block {
    width: calc(100% - 2.2%);
    max-width: 100%;

    & span {
      @include fonts_mobile.mobile-font-regular-size12-line14;
    }
  }

  .become-specialist-form__social-links,
  .become-specialist-form__specialist-type-list,
  .become-specialist-form__submit-wrapper {
    flex-direction: column;
  }

  .become-specialist-form__social-links {
    gap: 1.5vh;
  }

  .become-specialist-form__social-links > li {
    width: calc(100% - 2.2%);
  }

  .become-specialist-form__phone-input {
    width: calc(100% - 14vw);
  }

  .become-specialist-form__specialist-type-list {
    gap: 1vh;
  }

  .become-specialist-form__specialist-type-title {
    @include fonts_mobile.mobile-font-bold-14px-line32;
  }

  .become-specialist-form__specialist-type-description {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }

  .become-specialist-form__submit-wrapper {
    padding: 0;
    gap: 1vh;
  }

  .become-specialist-form__submit-button {
    width: 100%;
    height: 6vh;

    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .become-specialist-form__submit-description {
    margin-top: 0;
    text-align: center;

    @include fonts_mobile.mobile-font-regular-size12-line14;
  }
}

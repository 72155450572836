@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.dropdown-tag__group {
  display: flex;
  align-items: center;
  color: var(--blue-main);

  @include fonts.font-regular-small;
}

.dropdown-tag__group-badge {
  background-color: var(--secondary-main);
  color: var(--blue-main);
  border-radius: 2em;
  min-width: 1em;
  padding: 0.3em 0.5em;
  margin-left: 7px;
  text-align: center;
}

.dropdown-tag__container {
  @include scroll.scroll;
}

.dropdown-tag__selector {
  &.selector__dropdown {
    width: fit-content;
    min-width: 45vh;
    margin: 0;

    & .selector__control {
      border: 1px solid var(--grey-light);
    }
  }

  & .selector__option--is-disabled {
    opacity: 0.5;
  }

  & .selector__multi-value {
    border-radius: 25px;
    border: var(--blue-main) solid 2px;
    background: var(--main-white);

    & .selector__multi-value__label {
      color: var(--blue-main);

      @include fonts.font-regular-size12-line20;
    }

    & .selector__multi-value__remove {
      &:hover {
        background: transparent;
      }

      & svg {
        fill: var(--blue-main);
      }
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.search-input {
  @include fonts.font-size(16px);

  line-height: calc(3em - 2px);
  padding: 0 1.25em;
  border: 1px solid var(--grey-light);
  border-radius: 0.3em;
  color: var(--grey-dark);
  transition: 0.5s;

  &:focus {
    border-color: var(--blue-main);
    outline: none;
  }

  &::placeholder {
    color: var(--grey-main);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .search-input {
    height: 44px;
    padding: 0 14px;
    font-size: 14px;
    line-height: 46px;
    border-radius: 10px;
    border: 1px solid var(--secondary-dark);
  }
}

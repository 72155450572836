@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-preview-catalog__like {
  display: none;
}

.project-preview-catalog {
  position: relative;
  flex-shrink: 0;
  width: 20%;

  &:hover .project-preview-catalog__like {
    @media (hover: hover) {
      display: block;
      position: absolute;
      background-position: center;
      background-repeat: no-repeat;
      stroke: var(--main-white);
      right: 15px;
      top: 15px;
      margin: 10px 0;
      z-index: 100;
    }
  }
}

.project-preview-catalog__link {
  text-decoration: none;
  box-sizing: border-box;

  &:active {
    -webkit-tap-highlight-color: transparent;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .project-preview-catalog{
    width: 100%;
  }
}

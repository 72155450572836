@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_colors.scss";

.filter-item {
  @include fonts.font-size(12px);

  line-height: 20px;
  font-weight: 400;
  color: var(--grey-dark);
  padding: 0;
  margin: 0;
  border: none;
  width: max-content;

  &:hover {
    color: var(--grey-dark);

    & > svg > path {
      stroke: transparent;
    }
  }

  &:active {
    color: var(--grey-dark);
  }

  & > svg {
    width: 16px;
    height: 16px;

    & > path {
      stroke: transparent;
    }

    &.filter-item__icon_active > path {
      stroke: var(--blue-dark);
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .filter-item {
    text-align: left;
    color: var(--grey-dark);

    @include fonts_mobile.mobile-font-regular-14px-line20;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.photo-uploader-label {
  height: 88px;
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;

  &:hover {
    @media (hover: hover) {
      cursor: pointer;

      circle {
        fill: var(--secondary-light);
      }

      path {
        stroke: var(--blue-light);
      }

      .photo-uploader__image-icon {
        fill: var(--main-white);
      }
    }
  }

  &:active {
    circle {
      fill: var(--secondary-main);
    }

    path {
      stroke: var(--blue-dark);
    }
  }
}

.photo-uploader__image-cross {
  position: absolute;
  cursor: pointer;
  top: -10%;
  right: 60%;

  @media (max-width: common.$mobile-w) {
    right: 69%;
  }
}

.photo-uploader__image-cross--hiden {
  display: none;
}

.photo-uploader__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.photo-uploader__image-wrapper {
  position: relative;
}

.photo-uploader__image {
  width: 5em;
  height: 5em;
  border-radius: 100px;
  object-fit: cover;
}

.photo-uploader__image-icon {
  position: absolute;
  transition: 0.5s;
  left: calc(50% - 16px);
  top: calc(50% - 16px);
}

.photo-uploader__title,
.photo-uploader__text {
  margin: 0;
  color: var(--blue-dark);
  text-align: left;
}

.photo-uploader__title,
.photo-uploader__title_error {
  margin-bottom: 5px;

  @include fonts.font-regular-size16-line32;
}

.photo-uploader__text,
.photo-uploader__text_error {
  @include fonts.font-regular-size12-line20;
}

.photo-uploader__title_error,
.photo-uploader__text_error {
  color: var(--error-main);
}

@media screen and (max-width: common.$mobile-w) {
  .photo-uploader-label {
    gap: 1.5vw;
    height: 64px;
  }

  .photo-uploader__image {
    width: 4em;
    height: 4em;
  }

  .photo-uploader__title {
    margin: 0;

    @include fonts_mobile.mobile-font-bold-14px-line32;
  }

  .photo-uploader__text {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }
}

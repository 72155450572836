.text-link {
  color: var(--green-main);
  font-family: Rubik, sans-serif;
  font-weight: 500;
  text-decoration-line: underline;
  transition: 0.5s;
  -webkit-text-fill-color: var(--green-main);

  &:visited {
    color: var(--blue-main);
    -webkit-text-fill-color: var(--main-blue);
  }

  &:hover {
    @media (hover: hover) {
      color: var(--green-light);
      -webkit-text-fill-color: var(--green-light);
    }
  }

  &:active {
    color: var(--green-dark);
    -webkit-text-fill-color: var(--green-dark);
  }
}

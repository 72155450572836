@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.front-page-footer {
  padding: 4.7% 16vh 6.5%;
  background-color: var(--main-white);
  display: grid;
  grid-template: "menu contact subscribe ." ". . . city" / 20vw 25vw 20vw 1fr;
  row-gap: 6vh;
}

.front-page-footer__links {
  display: flex;
  flex-direction: column;
  gap: 2em;
  margin: 0;
  padding: 0;
  grid-area: menu;

  & > li {
    list-style-type: none;
  }
}

.front-page-footer__link {
  display: flex;
  align-items: center;
  color: var(--grey-dark);
  text-decoration: none;

  @include fonts.font-size(18px);

  font-weight: 500;
  transition: 0.5s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: var(--blue-main);
    transition: 0.5s;

    & > .front-page-footer__link-icon {
      fill: var(--blue-main);
    }
  }

  &:active {
    color: var(--blue-dark);
    transition: 0.5s;

    & > .front-page-footer__link-icon {
      fill: var(--blue-dark);
    }
  }
}

.front-page-footer__link-icon {
  fill: var(--grey-dark);
  transition: 0.5s linear;
  width: 1.2em;
  height: 1.2em;
  margin-right: 6px;
}

.front-page-footer__contacts {
  grid-area: contact;
  display: flex;
  flex-direction: column;
  gap: 1.5em;

  & > li {
    list-style-type: none;
  }

  & > li:first-child {
    & :hover {
      & > a,
      > p {
        color: var(--grey-dark);
      }
    }

    & :active {
      & > a,
      > p {
        color: var(--grey-dark);
      }
    }
  }

  & > li:nth-child(3) .footer-contacts__container > a {
    width: 50%;
  }
}

.footer-contacts__label {
  color: var(--green-main);

  @include fonts.font-size(14px);

  font-weight: 500;
  line-height: 18.2px;
  margin: 0;
  margin-bottom: 0.85em;
  display: block;
}

.footer-contacts__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  width: auto;
  transition: 0.5s;

  & > svg {
    width: 2em;
    height: 2em;
    min-width: fit-content;
    margin-bottom: auto;

    path {
      stroke: var(--blue-main);
    }
  }

  & > a,
  > p {
    @include fonts.font-size(16px);

    line-height: 130%;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    font-weight: 400;
    text-decoration: none;
    color: var(--grey-dark);
    transition: 0.5s;
  }

  &:hover {
    & > svg path {
      stroke: var(--blue-light);
      transition: 0.5s;
    }

    & > a,
    > p {
      color: var(--blue-main);
      transition: 0.5s;
    }
  }

  &:active {
    & > svg path {
      stroke: var(--blue-dark);
    }

    & > a,
    > p {
      color: var(--blue-dark);
    }
  }
}

.front-page-footer__subscribe {
  grid-area: subscribe;
  display: flex;
  flex-direction: column;

  & > li {
    list-style-type: none;
    margin-bottom: 1em;
  }
}

.footer-subscribe__label {
  color: var(--green-main);

  @include fonts.font-size(14px);

  font-weight: 500;
  line-height: 18.2px;
  margin: 0;
  margin-bottom: 0.85em;
  display: block;
}

.footer-subscriptions__container {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  transition: 0.5s;

  & > svg {
    width: 2em;
    height: 2em;

    path {
      fill: var(--blue-main);
    }
  }

  & > a {
    color: var(--blue-main);
    -webkit-tap-highlight-color: transparent;

    @include fonts.font-size(16px);

    font-weight: 500;
    line-height: 18.2px;
    transition: 0.5s;
  }

  &:hover {
    & > svg path {
      fill: var(--blue-light);
      transition: 0.5s;
    }

    & > a {
      color: var(--blue-light);
      transition: 0.5s;
    }
  }

  &:active {
    & > svg path {
      fill: var(--blue-dark);
    }

    & > a {
      color: var(--blue-dark);
    }
  }
}

.front-page-footer__development {
  display: flex;
  align-items: center;
  gap: 1em;
  align-self: flex-start;
  justify-self: end;

  @include fonts.font-size(16px);

  font-weight: 500;
}

.front-page-footer__logo {
  min-width: 3.8vw;
  height: auto;
}

.footer-development__text {
  color: var(--grey-dark);
  width: min-content;
  min-width: fit-content;

  & > p {
    display: inline;
  }
}

.footer-development__link {
  color: #816fee;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    color: #9888f5;
  }

  &:active {
    color: #7460ef;
  }

  &:visited {
    color: var(--blue-main);
  }
}

.front-page-footer__city {
  color: var(--grey-dark);
  justify-self: end;
  grid-area: city;

  @include fonts.font-size(18px);
}

@media screen and (max-width: common.$mobile-w) {
  .front-page-footer {
    padding: 35px 15px 0 9px;
    grid-template: "contact subscribe" "logo city" / 1.1fr 0.9fr;
    column-gap: 1em;
    row-gap: 2.5em;
  }

  .front-page-footer__links {
    display: none;
  }

  .front-page-footer__contacts {
    gap: 1em;
    width: 200%;
    overflow: auto;
  }

  .front-page-footer__contacts > li:is(:nth-child(3), :nth-child(4)) .footer-contacts__container {
    align-items: flex-start;
    width: 90vw;

    & > a {
      width: auto;
    }
  }

  .footer-contacts__label,
  .footer-subscribe__label {
    font-size: 12px;
    font-weight: 500;
    line-height: 15.6px;
  }

  .footer-contacts__container > a {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
  }

  .footer-subscriptions__container > a {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
  }

  .front-page-footer__subscribe > li {
    margin-bottom: 0.8em;
  }

  .front-page-footer__contacts .footer-contacts__container > svg {
    flex-shrink: 0;
    min-width: unset;
    width: 1.5em;
    height: 1.5em;
  }

  .front-page-footer__subscribe .footer-subscriptions__container > svg {
    width: 1.5em;
    height: 1.5em;
  }

  .front-page-footer__city {
    color: var(--grey-dark);
    text-decoration: none;
    align-self: flex-end;

    @include fonts_mobile.mobile-font-regular-10px;
  }

  .front-page-footer__logo {
    min-width: 7vw;
    height: auto;
  }

  .footer-development__text {
    font-size: 10px;
    line-height: 135%;
    font-weight: 400;
    margin: 0;
    width: auto;
  }

  .footer-development__link {
    font-weight: 500;
  }
}

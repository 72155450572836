.accordion > input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.accordion__content {
  max-height: 0;
  display: none;
  padding-top: 1em;
  overflow: hidden;
  transition: all 0.35s;
}

.accordion input:checked ~ .accordion__content {
  max-height: 100%;
  display: flex;
}

/* Visual styles */
.accordion__container {
  color: var(--theme);
  border-radius: 0.5rem;
  overflow: hidden;
}

.accordion__label,
.accordion__close {
  display: flex;
  cursor: pointer;
}

.accordion__label {
  align-items: center;
  gap: 4px;
}

.accordion__icon {
  fill: var(--grey-dark);
  transition: all 0.35s;
}

.accordion input:checked + .accordion__label > .accordion__icon {
  transform: rotate(180deg);
}

.accordion__content p {
  margin: 0;
}

.accordion__close {
  justify-content: flex-end;
  font-size: 0.75rem;
}

.date-picker {
  position: absolute;
  width: 200px;
  height: 210px;
  color: var(--grey-dark);
  background-color: white;
  border: 2px solid var(--blue-main);
  border-radius: 10px;
  overflow: hidden;
  transform-origin: top left;
  z-index: 2;
}

.date-picker .rdp {
  margin: 0;
}

.date-picker .rdp-table {
  margin: 0 10px 10px;
  transform-origin: top left;
  transform: scale(0.65);
}

.date-picker .rdp-head_row {
  color: var(--blue-main);
  font-weight: 400;
  font-size: 20px;
}

.date-picker .rdp-button:focus,
.date-picker .rdp-button:active,
.date-picker .rdp-day_selected:not([aria-disabled="true"]) {
  border-color: var(--blue-main);
}

.date-picker .rdp-day_selected:not([aria-disabled="true"]),
.date-picker .rdp-day_selected:focus:not([aria-disabled="true"]) {
  color: var(--grey-dark);
  background-color: var(--secondary-dark);
  border: 0;
}

.date-picker .rdp-day_selected:hover:not([aria-disabled="true"]) {
  @media (hover: hover) {
    color: var(--grey-dark);
    background-color: var(--secondary-dark);
    border: 0;
  }
}

.date-picker .rdp-day_selected:focus:not([aria-disabled="true"]) {
  border-color: var(--blue-main);
}

.date-picker .rdp-button:not([aria-disabled="true"]) {
  border-radius: 5px;
  font-size: 20px;
}

.date-picker .rdp-day_outside {
  color: var(--grey-light);
}

.date-picker__overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 2;
}

.date-picker-caption {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 172px;
  height: 47px;
  padding: 0 14px;
  background-color: var(--blue-main);
}

.date-picker-caption__month {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: var(--main-white);
}

.date-picker-caption__button--prev,
.date-picker-caption__button--next,
.date-picker-caption__button--prev svg,
.date-picker-caption__button--next svg {
  width: 16px;
  height: 16px;
}

.date-picker-caption__button--prev svg {
  transform: rotate(90deg);
}

.date-picker-caption__button--next svg {
  transform: rotate(270deg);
}

.date-picker-caption__button--prev svg path,
.date-picker-caption__button--next svg path {
  fill: var(--main-white);
  stroke: var(--main-white);
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.idea-widget {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 63.1%;
  margin: 0;
  height: 726px;
}

.idea-widget__subtitle {
  color: var(--grey);
  margin: 0 0 2%;

  @include fonts.font-regular-size18-line24;
}

.idea-widget__footer {
  max-width: 63.1%;
  padding: 0;
}

.idea-widget__idea-description {
  height: fit-content;
  margin: 0 0 3%;
  color: var(--grey-dark);

  @include fonts.font-regular-size18-line24;

  & > a {
    color: var(--green-main);
    font-weight: 500;
  }
}

.idea-widget__input-wrapper {
  &--area {
    display: flex;
    flex-direction: column;
    height: 73%;

    & > textarea {
      @include scroll.scroll;
    }
  }
}

.idea-widget__input {
  display: block;
  flex-grow: 1;
  overflow: hidden;
}

.idea-widget__warning {
  display: inline-block;
  margin: 2px 0 6px;
  color: var(--warning-main);
  opacity: 1;
  transition: 0.5s;

  @include fonts.font-regular-small;

  &--hidden {
    margin: 2px 0 6px;
    color: var(--warning-main);
    opacity: 0;
    transition: 0.5s;
  }
}

.idea-widget__error {
  display: inline-block;
  margin: 0.5% 0 8px;
  color: var(--error-main);
  opacity: 1;
  transition: 0.5s;

  @include fonts.font-regular-small;

  &--hidden {
    margin: 2px 0 8px;
    color: var(--error-main);
    opacity: 0;
    transition: 0.5s;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .idea-widget {
    max-width: 100%;
    height: 100%;
    gap: 12px;
    margin-bottom: 50px;
  }

  .idea-widget__input {
    overflow: visible;
  }

  .idea-widget__footer {
    gap: 2.2%;
    padding: 2% 0;
    width: 96%;
    z-index: 100;
    position: absolute;
    bottom: 0;
  }

  .idea-widget__title {
    margin-bottom: 8px;
  }

  .idea-widget__subtitle {
    @include fonts_mobile.mobile-font-regular-14px;
  }

  .edit-layout__gate {
    .idea-widget {
      padding: 0 8px;
    }

    .idea-widget__title__header-text {
      display: none;
    }
  }

  .idea-widget__input-wrapper--line {
    margin: 0;
    height: auto;
    transition: 0.5s;

    .text-input {
      padding: 4% 2.2%;

      @include fonts_mobile.mobile-font-regular-14px;
    }

    & > input::placeholder {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .idea-widget__input-wrapper--area {
    min-height: 250px;
    height: 45%;

    .text-area {
      padding: 5% 2.2%;
      margin: 0;

      @include fonts_mobile.mobile-font-regular-14px;
    }

    & > textarea::placeholder {
      @include fonts_mobile.mobile-font-regular-14px;
    }
  }

  .idea-widget__idea-description {
    max-width: 95%;
    padding: 12px 0 10px;

    @include fonts_mobile.mobile-font-regular-14px;
  }

  .idea-widget__error {
    @include fonts_mobile.mobile-font-regular-14px;

    margin: 4px 0 0;
  }
}

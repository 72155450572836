@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.time-picker {
  width: fit-content;
  min-width: 5em;
  color: var(--grey-dark);

  @include fonts.font-medium-size16-line22;

  &__indicators {
    display: none !important;
  }

  &__control {
    border: 1px solid var(--grey-light) !important;
    box-shadow: none !important;
    border-radius: 5px;
    height: 45px;

    &:hover,
    &--is-focused {
      border-color: var(--blue-main) !important;
    }
  }

  &__placeholder {
    color: var(--grey-light) !important;
  }

  &__value-container {
    padding: 0.25em 1em !important;
  }

  &__menu {
    border-radius: 10px !important;
    box-shadow: 0 1px 5px rgb(55 54 107 / 13%) !important;
    width: 5em !important;
  }

  &__menu-list {
    @include scroll.scroll;

    &::-webkit-scrollbar {
      background-color: transparent;
    }
  }

  &__group {
    padding: 0;
  }
}

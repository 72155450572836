@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.reporting-chart-legend {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  margin: 0;
  padding: 0;
  gap: 1em;
  margin-right: 2em;
}

.reporting-chart-legend__element {
  display: flex;
  gap: 0.625em;
  align-items: center;
}

.reporting-chart-legend__marker {
  width: 1.5em;
  height: 1.5em;
}

.reporting-chart-legend__projects {
  background-color: var(--green-main);
}

.reporting-chart-legend__platform {
  background-color: var(--tag-purple);
}

.reporting-chart-legend__element-name {
  @include fonts.font-regular-small;

  margin: 0;
}

@media screen and (max-width: common.$hd-w) and (min-width: common.$mobile-w) {
  .reporting-chart-legend__marker {
    width: 1em;
    height: 1em;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .reporting-chart-legend {
    flex-direction: row;
    margin-left: 1.2vw;
  }

  .reporting-chart-legend__marker {
    width: 1em;
    height: 1em;
  }

  .reporting-chart-legend__element-name {
    @include fonts_mobile.mobile-font-regular-14px-line20;
  }
}

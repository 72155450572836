@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.platform-member {
  display: flex;
  padding: 6% 6.5%;
  background: #fff;
  border-radius: 20px;
  max-width: calc(81.5vw - 13%);
  max-height: calc(90vh - 12%);
  overflow-y: auto;
  gap: 6%;
}

.platform-member__left {
  width: 27%;
  flex-shrink: 0;
}

.platform-member__avatar {
  width: 100%;
}

.platform-member__contacts {
  display: flex;
  gap: 5.5%;
  justify-content: center;
  margin-top: 12%;

  & button {
    position: relative;
    padding: 0;
    border: 0;

    &::after {
      position: absolute;
      top: 125%;
      left: -50%;
      content: "Скопировано";
      background: var(--secondary-main);
      color: var(--grey-main);
      padding: 9px 13px;
      border-radius: 8px;
      opacity: 0;
      max-width: 0;
      transition: opacity 0.3s, font-size 0.5s, max-width 0.5s;
      transition-delay: 1s;
      font-size: 1px;
      font-weight: 400;
      line-height: 120%;
    }

    &:active {
      &::after {
        opacity: 1;
        max-width: 100px;
        font-size: 12px;
        transition: opacity 0s, font-size 0s, max-width 0s;
      }
    }
  }

  & svg {
    width: 2.2vw;
    height: 100%;
  }
}

.platform-member__name {
  @include fonts.font-size(32px);

  font-weight: 600;
  line-height: 119%;
  color: var(--grey-dark);
  margin: 0.25em 0 0;
}

.platform-member__role {
  @include fonts.font-size(20px);

  font-weight: 500;
  line-height: 120%;
  color: var(--green-light);
  margin: 0.6em 0 0;
}

.platform-member__citation {
  @include fonts.font-size(24px);

  font-style: italic;
  line-height: 140%;
  color: var(--grey-dark);
  margin: 1em 0 0;
}

.platform-member__achievements {
  @include fonts.font-size(18px);

  line-height: 189%;
  color: var(--grey-dark);
  margin: 1em 0 0;
  padding-left: 1em;
}

.platform-member__achievement::marker {
  color: var(--green-light);
}

.platform-member__achievement-link {
  font-weight: 500;
  color: var(--green-main);
  transition: 0.5s;

  &:visited {
    color: var(--blue-main);
  }

  &:hover {
    @media (hover: hover) {
      color: var(--green-light);
    }
  }

  &:active {
    color: var(--green-dark);
  }
}

@media screen and (max-width: common.$mobile-w) {
  .platform-member {
    width: max-content;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding-inline: 2px;
    max-width: 100%;
    align-items: center;
    gap: 10px;
    padding-bottom: 0;

    & img {
      width: 170px;
    }
  }

  .platform-member__name {
    @include fonts_mobile.mobile-font-medium-size16-line22;
  }

  .platform-member__role {
    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .platform-member__citation {
    @include fonts_mobile.mobile-font-regular-size16-line22;

    text-align: center;
  }

  .platform-member__achievements {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-left: 0;
    text-align: center;
    list-style-type: none;
  }

  .platform-member__contacts svg {
    width: 40px;
  }

  .platform-member__contacts {
    margin-top: 20px;
  }

  .platform-member__button-more {
    @include fonts_mobile.mobile-font-bold-16px-line32;
  }
}

.search-project-filters {
  flex-direction: column;
  gap: 2em;
  flex: 1;
  padding: 0 3%;
  padding-top: 2.75em;
}

.search-project-filters-mobile {
  padding: 5% 0;
}

.search-project-filters__button {
  padding: 0;
  color: var(--grey-main);

  &:hover {
    color: var(--grey-light);
  }

  &:active {
    color: var(--grey-dark);
  }

  & path {
    stroke: var(--grey-main);
  }
}

.search-project-filters-mobile-frame {
  border: 1px solid var(--secondary-main);
  border-radius: 8px;
  padding: 1em;
  margin-top: 1em;
}

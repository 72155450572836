.search-filter-button {
  padding: 0;
  aspect-ratio: 1 / 1;
  border-radius: 10px;
  background: var(--main-white);
  border: 1px solid var(--secondary-main);
  position: relative;

  &:active {
    -webkit-tap-highlight-color: transparent;
  }

  &_active {
    border: 1px solid var(--secondary-dark);
    background: var(--secondary-main);
  }
}

.search-filter-button__icon {
  stroke: var(--grey-dark);
  padding-top: 20%;
  width: 60%;
  height: 60%;
}

.search-filter-button__indicator {
  position: absolute;
  right: 24%;
  bottom: 18%;
}

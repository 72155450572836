.projects-catalog {
  height: 100%;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}

.project-catalog__search-pane {
  flex-grow: 0.26;
  padding-top: 0;
}

.project-catalog__filters {
  padding-top: 4%;
}

.search-pane__project-catalog-list {
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  overflow-x: hidden;
}

.search-pane__catalog-container {
  height: inherit;
  padding-top: 2.5%;
  margin: 0 2.8%;
  box-sizing: border-box;
  gap: 2.5em;
}

.search-pane__frame-catalog {
  margin: 0;
}

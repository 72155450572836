@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.members__empty-tag {
  margin: 20px 0 0;
  color: var(--grey-main);

  @include fonts.font-regular-small;
}

.member__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.members__button-list {
  @include scroll.scroll;

  transition: 0.5s;
  overflow: auto;
}

.members__button {
  padding-left: 0;
  margin-top: 0.8em;
  border: none;
  border-radius: 0;

  & > svg {
    transition: 0.5s;
  }
}

.member__name {
  @include fonts.font-regular-small;

  margin: 0;
  color: var(--grey-dark);
}

.members__spinner {
  width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: center;

  svg {
    width: 3em;
    height: 3em;
  }
}

.member__subheader {
  @include fonts.font-size(16px);

  margin: 2em 0 1.2em;
  color: var(--grey-dark);
  font-weight: 500;
  line-height: 140%; // 22px
}

.member__name_clickable,
.member__name_clickable-reverted {
  @include fonts.font-regular-small;

  color: var(--blue-main);
  text-decoration: underline;
}

.member__name_clickable,
.member__name_clickable-reverted:hover {
  color: var(--blue-light);
}

.member__name_clickable,
.member__name_clickable-reverted:active {
  color: var(--blue-dark);
}

@media screen and (max-width: common.$mobile-w) {
  .members__mobile-container {
    & > p {
      margin: 0;
    }
  }

  .members__mobile-container-role {
    color: var(--grey-main);
    font-weight: 400;
  }

  .members__mobile-container-name {
    @include fonts_mobile.mobile-font-bold-14px-line20;

    color: var(--blue-main);
    text-decoration: underline;
  }

  .members__button-list {
    display: flex;
    flex-wrap: wrap;
    gap: 4px 20px;
  }

  .members__button {
    padding-left: 8px;
  }

  .members__empty-tag {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    padding-inline: 0.5em;
    margin: 10px 0;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.text-editor-preview {
  white-space: initial;
  word-break: break-word;

  @include fonts.font-size(16px);

  & h1 {
    margin: 0;

    @include fonts.font-size(24px);
  }

  & h2 {
    margin: 0;

    @include fonts.font-size(22px);
  }

  & h3 {
    margin: 0;

    @include fonts.font-size(18px);
  }

  & p {
    margin: 0;
  }

  & ul {
    margin: 0;
    padding-left: 2em;
  }

  & ol {
    margin: 0;
    padding-left: 2em;
  }

  & ul li,
  ol li {
    margin: 0;
    padding: 0;
    line-height: 120%;
  }

  & a {
    margin: 0;
    color: var(--green-main);

    &:hover {
      color: var(--green-light);
    }

    &:active {
      color: var(--green-dark);
    }

    &:visited {
      color: var(--blue-main);
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .text-editor-preview {
    font-size: 14px;

    & h1 {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
    }

    & h2 {
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
    }

    & h3 {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.comment {
  color: var(--grey-dark);

  &--pending {
    color: var(--grey-main);

    &__time {
      color: var(--grey-light);
    }
  }
}

.comment__header {
  display: flex;
  gap: 12px;
  align-items: center;

  &--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.comment__author {
  @include fonts.font-regular-size16-line32;
  @include fonts_mobile.mobile-font-bold-16px-line32;

  margin: 0;
}

.comment__time {
  @include fonts.font-regular-small14;
  @include fonts_mobile.mobile-font-regular-14px-line20;

  color: var(--grey-light);
}

.comment__actions {
  display: flex;
  gap: 13px;
}

.comment__action-button {
  height: 20px;
  cursor: pointer;
}

.comment__action-icon {
  width: 20px;
  height: 20px;
  stroke: var(--blue-main);
  stroke-width: 2px;
}

.comment__text {
  margin: 14px 0 0;
  white-space: pre-wrap;

  @include fonts.font-regular-small;
  @include fonts_mobile.mobile-font-regular-size16-line22;
}

%warning-block {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-top: 18px;
  padding: 16px 20px;
  background-color: var(--secondary-light);
  border-radius: 10px;
}

.comment__warning {
  &--pending {
    @extend %warning-block;

    svg path {
      stroke: var(--blue-main);
    }
  }

  &--denied {
    @extend %warning-block;

    svg path {
      fill: var(--error-main);
    }
  }
}

%warning-text-font {
  @include fonts.font-regular-small;
  @include fonts_mobile.mobile-font-regular-size16-line22;
}

.comment__warning-text {
  &--pending {
    @extend %warning-text-font;

    color: var(--blue-dark);
  }

  &--denied {
    @extend %warning-text-font;

    color: var(--error-main);
    margin: 0;

    span {
      margin-left: 5px;
      font-style: italic;
    }
  }
}

@media screen and (max-width: common.$mobile-w) {
  .comment__header {
    display: block;
  }

  .comment__actions {
    position: relative;
  }

  .comment__actions-trigger {
    height: 34px;
    cursor: pointer;

    & svg {
      width: 24px;
      height: 24px;
      margin: 0 0 10px 10px;
      stroke: var(--blue-main);
    }
  }

  .comment__actions-popup {
    display: none;

    &--visible {
      display: flex;
      position: absolute;
      top: 34px;
      right: 2px;
      gap: 16px;
      flex-direction: column;
      padding: 18px 23px 20px 17px;
      background-color: var(--main-white);
      border: 2px solid var(--secondary-dark);
      border-radius: 10px;
    }
  }

  .comment__action-label {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .comment__action-button {
    display: flex;
    gap: 6px;
    height: 24px;
    align-items: center;

    & svg {
      height: 24px;
      width: 24px;
    }

    &:first-of-type {
      svg {
        stroke: var(--grey-dark);
      }

      .comment__action-label {
        color: var(--grey-dark);
      }
    }

    &:last-of-type {
      svg {
        stroke: var(--error-main);
      }

      .comment__action-label {
        color: var(--error-main);
      }
    }
  }

  .comment__text {
    margin: 6px 0 0;
  }

  .comment__warning--pending,
  .comment__warning--denied {
    gap: 14px;
    margin-top: 10px;
    padding: 14px 16px;

    svg {
      flex-shrink: 0;
    }
  }
}

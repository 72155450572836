@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.like-button-compact {
  @include fonts.font-size(16px);

  &--clicked-like .like-button-compact__icon {
    animation: 1s heart-compact-animation;
    fill: var(--main-white);
  }
}

.like-button-compact__icon {
  width: 1.5em;
  height: 1.5em;
  stroke: var(--main-white);
  fill: transparent;
  stroke-width: 2px;
  transition: 1s;

  &--liked {
    fill: var(--main-white);
  }
}

@keyframes heart-compact-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

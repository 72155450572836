@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.choise-project-type-layout__gate {
  @include scroll.scroll-none;

  flex: 3;
  padding: 2.7% 0 0;
  overflow: auto;
  height: 100%;
}

.choise-project-type-layout_choose-project {
  flex-direction: column;
}

.choise-project-type-layout__back-wrapper {
  margin: 4% 0 0 7%;

  &_choose-project {
    margin: 3.7% 0 0 7.5%;
  }
}

.choise-project-type-layout__back {
  @include fonts.font-medium-size20-line24;

  border: 0;
  padding: 0;
  color: var(--grey-dark);

  & > svg {
    height: 1.2em;
    width: 1.2em;

    & > path {
      transform-origin: center;
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }

  &span {
    vertical-align: 4px;
    margin-left: 10px;
  }
}

@media screen and (max-width: common.$mobile-w) {
  .choise-project-type-layout__gate {
    flex: 8;
    margin: 0 2%;
    padding: 0;
  }

  .choise-project-type-layout {
    flex-direction: column;
  }

  .choise-project-type-layout__title {
    @include fonts_mobile.mobile-font-regular-18px;

    display: flex;
    justify-content: center;
    padding: 12px 0;
    margin: 0;
  }
}

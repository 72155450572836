@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.members-incoming-row {
  @include fonts.font-size(16px);

  line-height: 140%;
  color: var(--grey-dark);
}

.members-incoming-row__decline-form {
  margin-top: 25px;
  grid-column: 1 / end;
}

#link.members-incoming-row__item {
  padding: 0;
  border: 0;
  text-align: left;
  text-decoration: underline;
}

#link.members-incoming-row__item,
.members-incoming-row span {
  white-space: pre-wrap;
  word-wrap: break-word;
  width: fit-content;
  height: fit-content;
}

#list.members-incoming-row__item {
  display: flex;
  flex-direction: column;
}

#actions.members-incoming-row__item {
  display: flex;
  justify-content: space-between;

  & .actions__approve,
  & .actions__reject {
    flex-shrink: 0;
    height: 24px;
    width: 24px;
    padding: 0;
    border: 0;
  }

  & .actions__approve {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  & .actions__approve svg path {
    stroke: var(--green-light);
  }

  & .actions__reject svg path {
    fill: var(--error-main);
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.request-form__container {
  background: var(--main-white);
  border-radius: 10px;
  padding: 1.04%;
  z-index: 1;
  color: var(--grey-dark);
}

.request-form__container-huge {
  height: 86%;
  padding: 1.5%;
}

.request-form__title {
  @include fonts.font-medium-big;

  margin: 0;
}

.request-form__title-role {
  @include fonts.font-regular-small14;
}

.request-form__title-comment {
  @include fonts.font-regular-small14;
}

.request-form__close-wrapper {
  display: flex;
  justify-content: flex-end;
}

.request-form__close {
  cursor: pointer;
  margin: 0;

  & > svg path {
    fill: var(--grey-light);
  }
}

.request-form__block {
  padding: 0 3em 3.75em;
}

.request-form__comment {
  width: 886px;
  height: 189px;
  margin-bottom: 2em;
}

.request-form__button {
  padding: 1.25% 8.7%;
}

.request-form__huge {
  overflow: auto;
  max-height: 87%;
  padding: 3% 2% 1% 0%;

  @include scroll.scroll;

  & .become-specialist-container__prompt {
    margin-bottom: 3%;
  }
}

.request-form__selector-roles {
  display: flex;
  flex-direction: column;

  & .selector__menu-list {
    @include scroll.scroll-none;
  }

  & .search-dropdown__input {
    max-width: 90vh;
    width: auto;
  }

  .request-form__notification-link {
    color: var(--blue-dark);
    font-weight: 500;
  }
}

.request-form__notification-link {
  &:visited,
  &:link {
    color: var(--blue-dark);
  }
}

@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.auth-modal {
  background: var(--main-white);
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 3.9% 4.4% 5.7%;
  max-width: 22vw;
}

.auth-modal_with-back-button {
  padding: 5.8% 4% 5.7%;
}

.auth-modal__header {
  @include fonts.font-medium-big;

  text-align: center;
  margin-bottom: 9%;
  color: var(--grey-dark);
}

.auth-modal__back-button {
  position: absolute;
  top: 2.2em;
  left: 1em;
  margin-left: 2.1%;
  border: 0;
  padding: 0;
  color: var(--grey-dark);

  @include fonts.font-medium-small;

  & > svg {
    width: 32px;
    height: 32px;
  }

  & > svg > path {
    width: 32px;
    height: 32px;
    fill: var(--grey-dark);
    stroke: none;
    transform-origin: center;
    transform: rotate(90deg);
  }

  & > span {
    vertical-align: 4px;
    margin-left: 0;
  }

  &:hover {
    @media (hover: hover) {
      & > svg > path {
        fill: var(--blue-light);
        stroke: none;
      }
    }
  }
}

.auth-input {
  min-width: 19vw;
  border-width: 2px;
}

.auth-button {
  padding: calc(0.7vw - 3px) 0;

  @include fonts.font-medium-small;
}

.auth-title {
  color: var(--grey-dark);

  @include fonts.font-medium-size20-line24;
}

.auth-description {
  color: var(--grey-dark);

  @include fonts.font-regular-small;
}

@media screen and (max-width: common.$mobile-w) {
  .auth-title {
    @include fonts_mobile.mobile-font-bold-20px;
  }

  .auth-description {
    @include fonts_mobile.mobile-font-regular-16px-line24;
  }

  .auth-button {
    padding: calc(3.5vw - 3px) 0;

    @include fonts_mobile.mobile-font-medium-size16-line19;
  }
}

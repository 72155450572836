@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";

.search-pane {
  @include fonts.font-size(16px);
  @include scroll.scroll-none;

  position: relative;
  display: flex;
  height: calc(90.8vh - 1.75em);
  width: 0;
  background-color: var(--main-white);
  transition: 0.5s;
  overflow: auto;

  &--visible {
    flex: 0.33;
  }

  &--visible-with-filters {
    flex: 0.8;
    max-width: 41.6%;
  }

  &--visible-with-filters-catalog {
    flex: 0.3;
  }
}

.search-pane__frame {
  flex: 1;
  height: inherit;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 1.75em 1.25em 0;
}

.search-pane__frame-map {
  max-width: 22.8vw;
  min-width: 22.8vw;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  gap: 1.3em;
}

.search-pane__list {
  padding-right: 3%;

  @include fonts.font-size(10px);
  @include scroll.scroll;

  overflow: hidden auto;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &--empty {
    padding: 0;
    margin-top: 2.4em;
  }
}

.search-pane__list-catalog {
  @include scroll.scroll;

  overflow: hidden auto;
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.search-pane__spinner {
  @include fonts.font-size(10px);

  position: absolute;
  width: 8em;
  height: 8em;
  top: calc(50% - 4em);
  left: calc(50% - 4em);

  svg {
    width: 8em;
    height: 8em;
  }
}

.search-pane__filters {
  display: flex;
  overflow: hidden auto;
  max-width: 16vw;

  @include scroll.scroll-none;

  padding-bottom: 1em;

  &--close {
    display: none;
  }
}

.search-pane__filters-catalog {
  flex: 1;
}

.search-pane__filters-map {
  flex: 0.72 1;
  border-left: 2px solid var(--secondary-light);
}

.search-pane__mobile-container {
  overflow: auto;
  display: flex;
  flex-direction: column;
  margin: 0.5em;
  flex: 1;
}

.search-pane__input-mobile-filter {
  flex: 0;
}

.search-pane__mobile-order-number {
  padding-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 0;
}

@media screen and (max-width: common.$mobile-w) {
  .search-pane__list-catalog {
    flex-flow: column nowrap;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";

.join-button-compact {
  @include fonts.font-size(16px);

  gap: 1vw;

  &--clicked-join .join-button-compact__icon {
    animation: 1s join-compact-animation;
    fill: var(--main-white);
  }
}

.join-button-compact__icon {
  transition: 1s;
  fill: transparent;

  & > path {
    stroke: var(--main-white);
  }

  &--joined > path {
    stroke: none;
  }
}

@keyframes join-compact-animation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

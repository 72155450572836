div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-overlay-pane > svg > g > path:first-child {
  stroke: var(--grey-main);
  fill: var(--grey-main);
  fill-opacity: 0.2;
}

div.leaflet-pane.leaflet-marker-pane > div {
  background: transparent;
  border: none;
}

div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-marker-pane > div::before {
  position: absolute;
  box-sizing: border-box;
  visibility: visible;
  display: block;
  width: 16px;
  height: 16px;
  border: 4px solid var(--grey-main);
  background-color: var(--main-white);
  border-radius: 20px;
  content: "";
  top: 0;
  left: 0;
}

.base-layout--drawing > div > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-overlay-pane > svg > g > path,
.base-layout--drawing > div > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-marker-pane > div::before,
.only-map-layout--drawing > div > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-overlay-pane > svg > g > path,
.only-map-layout--drawing > div > div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-marker-pane > div::before {
  cursor: url("./img/cursor.svg"), default;
}

div.leaflet-pane.leaflet-map-pane > div.leaflet-pane.leaflet-overlay-pane > div > div {
  background-color: var(--green-main) !important;
  border-radius: 20px;
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.project-preview-horizontal {
  @include fonts.font-size(16px);

  position: relative;
  display: flex;
  height: 8.4em;
  box-sizing: border-box;
  background: var(--main-white);
  border: 1px solid var(--secondary-dark);
  border-radius: 10px;
  overflow: hidden;
  flex-shrink: 0;
  align-items: stretch;
  cursor: pointer;
}

.project-preview-horizontal__status-tile {
  left: 10px;
  bottom: 10px;
  gap: 0;
}

.project-preview-horizontal__cover {
  width: 26%;
  background-color: var(--secondary-dark);
  object-fit: cover;
  object-position: center;
  transition: 0.5s;

  &:hover {
    @media (hover: hover) {
      width: 50%;
    }
  }

  &::before {
    opacity: 0;
  }
}

.project-preview-horizontal__content {
  flex: 1;
  padding: 1.13em 1.25em 0 1em;
  overflow: hidden;
}

.project-preview-horizontal__name {
  @include fonts.font-size(14px);

  width: 18em;
  line-height: 120%;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: start;
  color: var(--grey-dark);
  margin: 0;
}

.project-preview-horizontal__status {
  @include fonts.font-size(12px);

  display: flex;
  justify-content: space-between;
  margin-top: 1em;
  line-height: 120%;
  color: var(--grey-dark);
}

.project-preview-horizontal__button > span {
  @include fonts.font-size(14px);

  font-weight: 500;
  line-height: 120%;
  color: var(--blue-main);
}

.project-preview-horizontal__status > span:first-of-type {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.project-preview-horizontal__progress {
  margin-top: 0.63em;
}

.project-preview-horizontal__button {
  display: flex;
  gap: 0.38em;
  align-items: center;
  margin-top: 0.44em;
  cursor: pointer;
  opacity: 0;
  transition: 0.5s;
  transform: translateX(-10.63em);
}

.project-preview-horizontal:hover .project-preview-horizontal__button {
  @media (hover: hover) {
    opacity: 1;
    transform: translateX(0);
  }
}

.project-preview-horizontal__button > svg {
  width: 1em;
  height: 1em;
}

.project-preview-horizontal__button > svg > path {
  stroke: var(--blue-main);
  stroke-width: 3px;
}

@media screen and (max-width: common.$mobile-w) {
  .project-preview-horizontal {
    font-size: 12px;
    height: 10em;
  }

  .project-preview-horizontal__name {
    @include fonts_mobile.mobile-font-bold-12px-line17;
  }

  .project-preview-horizontal__status {
    @include fonts_mobile.mobile-font-regular-size12-line14;
  }
}

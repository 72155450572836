@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.deny {
  display: flex;
  flex-direction: column;
  padding: 9.7% 26% 0;
  height: 75%;
}

.deny__title {
  @include fonts.font-medium-big;

  margin: 0 0 4.8%;
}

.deny__text {
  @include fonts.font-regular-size18-line24;

  margin: 0 0 2%;
}

.deny__text-area {
  height: 23.2%;
}

.deny__button-container {
  display: flex;
  margin: 2.6% 0 1.2%;
  justify-content: flex-end;
}

.deny__button {
  padding: 1.3% 4.9%;
}

.deny__image {
  background-position: center;
  background-size: contain;
  height: 38%;
  display: flex;
  aspect-ratio: 373/322;
}

@media screen and (max-width: common.$mobile-w) {
  .deny {
    padding: 0 8px;
    height: 100%;
  }

  .deny__header {
    order: -1;
  }

  .deny__title {
    @include fonts_mobile.mobile-font-medium-size20-line28;
  }

  .deny__text {
    @include fonts_mobile.mobile-font-regular-size16-line22;
  }

  .deny__text-area {
    @include fonts_mobile.mobile-font-regular-14px-line20;

    height: 30%;
  }

  .deny__button-container {
    margin-top: 6.7%;
  }

  .deny__button {
    flex: 1;
    padding: 3.8% 4.9%;

    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .deny__image {
    height: 20%;
    order: -1;
    margin-bottom: 6%;
  }
}

@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";

.information-card {
  background: var(--main-white);
  border-radius: 10px;
  max-height:
    calc(
      84.2vh - 3.5em
    ); // fullscreen - header (9.2%) - vertical margins/paddings

  padding: 3.3vh 1.9vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 0 1px 5px rgb(55 54 107 / 13%);
  font-family: Rubik, sans-serif;
}

.information-card__header {
  @include fonts.font-medium-size20-line24;

  margin: 0;
}

.information-card__header--small {
  @include fonts.font-regular-medium;

  color: var(--grey-dark);
  margin: 18px 0 16px;
}

.information-card__content {
  @include scroll.scroll;

  overflow: auto;
  width: 20.5vw;
  padding-right: 9px;

  @media (max-width: common.$hd-w) {
    width: 27vw;
  }
}

.information-card__message {
  @include fonts.font-medium-small;

  align-self: flex-start;
  color: var(--blue-main);
  margin: 20px 0 28px;
}

.information-card__message--error {
  @include fonts.font-medium-small;

  color: var(--error-main);
}

.information-card__message--pkk-error {
  color: var(--grey-dark);
}

.information-card__button-block {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 6%;
}

.information-card__reset,
.information-card__create {
  box-sizing: content-box;
  font-weight: 400;
  width: 35%;
  height: 2em;
  transition: 0.5s;
}

.information-card__intersected-info {
  margin: 0;
  color: var(--yellow-dark);
}

.information-card__intersected-error-message {
  @include fonts.font-regular-small;

  display: flex;
  align-items: center;
  gap: 3.2%;
  margin-top: 2em;
  justify-content: center;

  & .information-card__message--error-icon {
    flex-shrink: 0;

    & path {
      stroke: var(--yellow-dark);
    }
  }
}

.information-card__reset {
  border: 1px solid var(--blue-main);
}

.information-card__create {
  border: 1px;
  background-color: var(--blue-main);
  color: var(--main-white);
}

.cadaster-info {
  display: flex;
  flex-direction: column;
}

.information-card__spinner {
  margin-top: 18px;
  display: flex;
  justify-content: center;
}

.project-info__message {
  margin: 8px 0 0;
}

.project-info__intersections {
  display: flex;
  flex-direction: column;
  gap: 2vh;
}

.cadaster-info__pkk-error {
  @include fonts.font-regular-small;

  display: flex;
  gap: 3.2%;

  & .information-card__message--error-icon {
    flex-shrink: 0;

    & path {
      stroke: var(--warning-main);
    }
  }
}

.information-card__message--pkk-error-icon {
  & path {
    stroke: var(--warning-main);
  }
}

@media (max-width: common.$mobile-w) {
  .information-card__button-block {
    width: 100%;
    justify-content: space-evenly;
    margin-top: 0;
    box-sizing: border-box;
    padding: 0 8px;
    gap: 8px;
  }

  .information-card__mobile {
    position: fixed;
    left: 0;
    bottom: 0;
    padding: 1em 0;
    width: 100%;
    z-index: 10000;
  }

  .information-card__reset {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    flex: 1;
  }

  .information-card__create {
    @include fonts_mobile.mobile-font-medium-size16-line22;

    flex: 1;
  }

  .information-card__reset,
  .information-card__create {
    height: 3em;
  }

  .information-card__message--error {
    @include fonts_mobile.mobile-font-regular-size12-line14;

    flex: 6;
  }

  .information-card__message--error-icon {
    flex: 1;
  }

  .information-card__message--fetching {
    @include fonts_mobile.mobile-font-regular-size12-line14;

    flex: 6;
    color: var(--blue-dark);
  }

  .information-card__message--fade-away {
    animation: 3s fade-away;
    opacity: 0;
  }

  .information-card__message--load {
    @include fonts_mobile.mobile-font-regular-size12-line14;

    flex: 6;
    color: var(--green-light);
  }

  .information-card__info-block {
    display: flex;
    background: var(--main-white);
    border-radius: 10px;
    gap: 1em;
    margin: 8px;
    align-items: center;
    padding: 1.66% 1em;
  }

  .information-card__spinner {
    flex: 1;
    margin: 0;

    & svg {
      width: 50%;
    }
  }

  .information-card__spinner-mobile svg {
    margin: 1em;
    width: 1.5em;
    height: 1.5em;
    fill: var(--blue-dark);
  }
}

@keyframes fade-away {
  0% {
    opacity: 1;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

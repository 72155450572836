@use "@platform-for-public-places/components-library/styles/_fonts.scss";
@use "@platform-for-public-places/components-library/styles/_scroll.scss";
@use "@platform-for-public-places/components-library/styles/_common.scss";
@use "@platform-for-public-places/components-library/styles/_fonts_mobile.scss";

.creation-layout {
  display: flex;
}

.creation-layout__aside-steps {
  flex: 1;
}

.creation-layout__gate {
  @include scroll.scroll-none;

  flex: 3;
  padding: 3.7% 0 0;
  overflow: auto;
}

.creation-layout_choose-project {
  flex-direction: column;
}

.creation-layout__back-wrapper {
  margin: 14% auto 0 27%;
  width: 46%;

  &_choose-project {
    margin: 3.7% 0 0 7.5%;
  }
}

.creation-layout__back {
  @include fonts.font-medium-size20-line24;

  border: 0;
  padding: 0;
  color: var(--grey-dark);

  & > svg {
    height: 1.2em;
    width: 1.2em;

    & > path {
      transform-origin: center;
      transform: rotate(180deg);
      transition: 0.5s;
    }
  }

  &span {
    vertical-align: 4px;
    margin-left: 10px;
  }
}

.creation-layout__steps {
  width: 65%;
  margin: 26% auto 0 27%;
  display: flex;
  flex-direction: column;
  height: 74%;
  gap: 5.6%;
}

.creation-layout__step {
  display: flex;
  gap: 8.5%;
}

.creation-layout__step-number {
  @include fonts.font-medium-size20-line24;

  width: 32px;
  height: 32px;
  border-radius: 5px;
  background: var(--main-white);
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  color: var(--green-main);
  border: 2px solid var(--green-main);
}

.creation-layout__step--active > .creation-layout__step-number {
  background: var(--green-main);
  color: var(--main-white);
}

.creation-layout__step-name {
  @include fonts.font-medium-size20-line24;

  color: var(--grey-main);
  transition: 0.5s;
}

.creation-layout__step--active > .creation-layout__step-name {
  color: var(--green-main);
}

@media screen and (max-width: common.$mobile-w) {
  .creation-layout__gate {
    flex: 8;
    margin: 0 2%;
    padding: 0;
  }

  .creation-layout {
    flex-direction: column;
    height: calc(var(--vh, 1vh) * 100);
  }

  .creation-layout__steps {
    flex-direction: initial;
    height: auto;
    display: flex;
    justify-content: center;
    width: initial;
    gap: 5.05%;
    margin: 0 0 5.5%;
  }

  .creation-layout__step-number {
    @include fonts_mobile.mobile-font-medium-size14-line17;
  }

  .creation-layout__title {
    @include fonts_mobile.mobile-font-regular-18px;

    display: flex;
    justify-content: center;
    padding: 12px 0;
    margin: 0;
  }
}
